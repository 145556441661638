import { APIFetchError } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { parseAsJSON } from "lib/parse";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import {
  MetadataScan,
  ProcessedErrorData,
  ProcessedErrors,
  ProcessedMetadata,
  RawProcessedMetadata,
} from "../lib/types";

function isProcessedErrorData(obj: any): obj is ProcessedErrorData {
  return (
    typeof obj === "object" &&
    typeof obj.description === "string" &&
    typeof obj.details === "string"
  );
}

/**
 * Errors can contain a regular string OR a serialized object...
 */
function parseErrors(
  source?: ProcessedErrors<string>
): ProcessedErrors<ProcessedErrorData> {
  const errors = {} as ProcessedErrors<ProcessedErrorData>;
  if (source) {
    for (const key in source) {
      const code = parseInt(key);
      if (source[code]) {
        try {
          const err = JSON.parse(source[code]);
          if (isProcessedErrorData(err)) {
            errors[code] = err;
          }
        } catch (e) {
          errors[code] = {
            description: source[code],
            details: "N/A",
          };
        }
      }
    }
  }
  return errors;
}

/**
 * Parse scan-data very nicely and try to recover from old/incorrect properties
 */
function forgivingScanParser(scan: any): MetadataScan {
  let clean: MetadataScan = {
    id: scan.id,
    patientId: "",
    sourceUris: [],
    type: scan.type,
    version: scan.version,
  };

  if (scan.hasOwnProperty("sourceUris")) {
    clean.sourceUris = scan.sourceUris.map(
      (u: string) => RecordingURI.parse(u) || u
    );
  } else if (scan.hasOwnProperty("source_uris")) {
    clean.sourceUris = scan.source_uris.map(
      (u: string) => RecordingURI.parse(u) || u
    );
  } else {
    throw new Error("Metadata error: Source URIs undefined");
  }

  if (scan.hasOwnProperty("patientId")) {
    clean.patientId = scan.patientId;
  } else if (scan.hasOwnProperty("patient_id")) {
    clean.sourceUris = scan.patient_id;
  } else {
    throw new Error("Metadata error: Patient id undefined");
  }
  return scan;
}

async function parse(response: Response): Promise<ProcessedMetadata> {
  const raw = await parseAsJSON<RawProcessedMetadata>(response);

  // Check for required properties
  if (!raw.hasOwnProperty("timestamp_s")) {
    throw new Error("Metadata error: Timestamps missing");
  }
  if (!raw.hasOwnProperty("ranges") || !raw.ranges) {
    throw new Error("Metadata error: ranges missing");
  }

  return {
    blood_vessels_mask: !!raw.blood_vessels_mask,
    created_at: raw.created_at ? DateTime.fromISO(raw.created_at) : undefined,
    errors: parseErrors(raw.errors),
    events: raw.events || [],
    scan: forgivingScanParser(raw.scan),
    pad_mean_blood_volume_fraction_T1: !!raw.pad_mean_blood_volume_fraction_T1,
    pad_mean_oxygenation_T1: !!raw.pad_mean_oxygenation_T1,
    premetadata: !!raw.premetadata,
    ranges: raw.ranges,
    succeeded: raw.succeeded || [],
    timestamp_s: raw.timestamp_s,
    tissue_algo_version: raw.tissue_algo_version ?? "1",
    version: raw.version ?? 1,
  };
}

export function useProcessedMetadata(scan: ImmutableScan | undefined) {
  const api = useAPIClient().blob;
  const { logError } = useReporting();

  const [data, setData] = useState<ProcessedMetadata>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!scan) {
      return;
    }
    if (data) {
      return;
    }

    api
      .fetchImmutableScanBlob({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        resultName: "metadata",
        path: "metadata.json",
      })
      .result.then(parse)
      .then(setData)
      .catch((e) => {
        const hasError = (e: any): e is APIFetchError => !!e.response;
        if (hasError(e)) {
          setError(`Error ${e.response.status} when fetching metadata`);
          logError(e);
        } else if (e.hasOwnProperty("message")) {
          setError(e.message);
          logError(e);
        }
      });
  }, [api, data, logError, scan]);

  return { processedMetadata: data, metadataError: error };
}
