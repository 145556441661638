import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useHeartRate(scan: ImmutableScan | undefined) {
  const api = useAPIClient().scan;

  const [data, setData] = useState<APITypesV1.HeartRateResultData>();

  useEffect(() => {
    if (!scan) {
      return;
    }

    const request = api.fetchImmutableScanResult({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName: "ssn_heart_rate",
    });

    request.result
      .then((r) => setData(r.data as APITypesV1.HeartRateResultData))
      .catch(silenceAbort);

    return () => {
      setData(undefined);
      request.abandon();
    };
  }, [api, scan]);

  return data;
}
