import { SMPLBodyMetrics } from "@cur8/api-client";
import { ImmutableScan, Patient } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { toPose } from "lib/meshuga/pose";
import { isRecordingURI } from "lib/uri/guard";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

function toMeters(value?: number): { meters: number } {
  if (value == null) {
    return {
      meters: NaN,
    };
  }

  return {
    meters: value / 1000,
  };
}

function toCircumferences(smpl: SMPLBodyMetrics) {
  const circ = smpl.circumferences;

  return {
    calf: toMeters(circ?.calf),
    hip: toMeters(circ?.hip),
    neck: toMeters(circ?.neck),
    thigh: toMeters(circ?.thigh),
    thorax: toMeters(circ?.thorax),
    waist: toMeters(circ?.waist),
  };
}

function toDistances(smpl: SMPLBodyMetrics) {
  return {
    height: toMeters(smpl.height),
  };
}

function toUnit(smpl: SMPLBodyMetrics) {
  return {
    distances: toDistances(smpl),
    circumferences: toCircumferences(smpl),
  };
}

type SMPLMeasurementMetric = {
  timestamp: DateTime;
  unit: ReturnType<typeof toUnit>;
};

function fetchBodyMetrics(
  api: APIClient,
  patient: Patient,
  scans: ImmutableScan[]
) {
  async function fetchSMPL(scan: ImmutableScan) {
    const recordingURI = scan.sourceUris.filter(isRecordingURI).at(0);
    if (!recordingURI) {
      throw new Error("No recordingURI on scan");
    }

    const { deviceId, recordingId } = recordingURI;
    const smpl = await api.meshuga.fetchSMPLBodyMetrics({
      deviceId,
      recordingId,
      kind: toPose(patient.sex),
      pose: "front",
    }).result;

    return {
      timestamp: scan.versionCreatedAt,
      unit: toUnit(smpl),
    } as SMPLMeasurementMetric;
  }

  const tasks = scans.map(fetchSMPL);

  return Promise.all(tasks);
}

export function usePatientSMPLMetrics(
  scans?: ImmutableScan[],
  patient?: Patient
) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<SMPLMeasurementMetric[]>>();

  useEffect(() => {
    if (!scans || !patient) {
      return;
    }

    fetchBodyMetrics(api, patient, scans).then((data) => {
      setResult({
        data,
      });
    });

    return () => {
      setResult(undefined);
    };
  }, [api, patient, scans]);

  return result;
}
