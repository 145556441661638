import { BloodOxygen, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import FixedAvatarValue from "render/ui/presentation/FixedAvatarValue";
import ValueItem from "render/ui/symbol/ValueItem";

interface BloodOxygenMetricProps {
  bloodOxygen: Metric<"cardio.oxygen_saturation">[];
}

export default function BloodOxygenMetric({
  bloodOxygen,
}: BloodOxygenMetricProps) {
  const latest = bloodOxygen.at(0);

  const ranges = useMemo(() => BloodOxygen.rangesFor(), []);

  const risk = useMemo(() => {
    if (latest) {
      return ranges.findRisk({ "%": latest.unit.percent });
    }
  }, [latest, ranges]);

  return (
    <>
      <FixedAvatarValue
        label={
          <>
            Blood O<sub>2</sub>
          </>
        }
        value={
          <ValueItem
            value={latest ? latest.unit.percent.toFixed(0) : "--"}
            unit="%"
          />
        }
        risk={risk ?? Risk.Unknown}
        align="right"
      />
    </>
  );
}
