import { Length, Weight } from "@cur8/measurements";
import { useMemo } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import ValueItem from "render/ui/symbol/ValueItem";
import InfoPanel from "../InfoPanel";
import MainUSPItem from "../MainUSPItem";
import ValueBox from "../ValueBox";
import styles from "./styles.module.sass";

function calcBSA(height: Length, weight: Weight) {
  return (
    0.20247 * Math.pow(height.meters, 0.725) * Math.pow(weight.kilograms, 0.425)
  );
}

export default function SkinPanel() {
  const { metrics } = usePatientData();

  const height = metrics.body.height;
  const weight = metrics.body.weight;

  const surfaceArea = useMemo(() => {
    const h = height?.at(0);
    const w = weight?.at(0);
    if (!h || !w) {
      return;
    }

    return calcBSA(h.unit, w.unit);
  }, [height, weight]);

  const { ui } = useDashboardContext();

  return (
    <div className={styles.SkinPanel}>
      <InfoPanel
        onClick={() => {
          ui.set({ layer: Layer.Skin });
        }}
        align="right"
        caption={
          <>
            <h3>Skin</h3>
            <MainUSPItem
              value={surfaceArea ? surfaceArea.toFixed(2) : "--"}
              unit="m² SURFACE AREA"
            />
          </>
        }
      >
        <div className={styles.values}>
          <ValueBox
            caption="Surface images"
            value={<ValueItem value="+2000" />}
          />

          <ValueBox caption="Thermal images" value={<ValueItem value={12} />} />

          <ValueBox
            caption="Tissue"
            value={<ValueItem value={2.5} unit="mm" />}
          />
        </div>
      </InfoPanel>
    </div>
  );
}
