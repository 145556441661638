import { ImmutableScan } from "@cur8/rich-entity";
import { Property, PropertyLabel } from "render/pages/TissuePages/lib/types";
import RoIRatio from "./RoIRatio";
import styles from "./styles.module.sass";

interface PropertyRatioProps {
  indexRemap: number[];
  onSelectProperty: (property: Property) => void;
  properties: PropertyLabel[];
  scan: ImmutableScan;
  selected: Property;
  timestamps: number[];
}

export default function PropertyRatio({
  indexRemap,
  onSelectProperty,
  properties,
  scan,
  selected,
  timestamps,
}: PropertyRatioProps) {
  return (
    <div className={styles.PropertyRatio}>
      <h3>Ratios</h3>
      <div data-section="properties">
        {properties &&
          properties.map(([property, label]) => (
            <button
              onClick={() => onSelectProperty(property)}
              data-selected={selected === property}
              key={property}
            >
              <label>{label}</label>
              <RoIRatio
                indexRemap={indexRemap}
                property={property}
                scan={scan}
                timestamps={timestamps}
              />
            </button>
          ))}
      </div>
    </div>
  );
}
