import { createContext } from "react";
import {
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";

export enum SessionState {
  Pending,
  Busy,
  Settled,
}

type MSALAuth = {
  instance: PublicClientApplication;
  auth?: AuthenticationResult;
  session: {
    state: SessionState;
    logIn(): void;
    logOut(): void;
  };
};

export const Context = createContext<MSALAuth | null>(null);
