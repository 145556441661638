import { UnitConverter, UnitSystem } from "./UnitConverter";

export const Converters: Record<UnitSystem, UnitConverter<"mmHg" | "psi">> = {
  metric: new UnitConverter({
    unit: "mmHg",
  }),
  imperial: new UnitConverter({
    unit: "psi",
  }),
};
