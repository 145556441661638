import { Diastolic, Systolic } from "@cur8/health-risks-calc";
import { AggregatedBloodPressureData, Patient } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { getHighestRisk, getRiskColor } from "../../lib/utils";
import DataSummary from "./components/DataSummary";
import styles from "./styles.module.sass";

interface BloodPressureTableHeaderProps {
  patient: Patient;
  measurements: AggregatedBloodPressureData[];
}

export default function BloodPressureTableHeader({
  measurements,
  patient,
}: BloodPressureTableHeaderProps) {
  const age = patientAge(patient, DateTime.now());

  const ranges = useMemo(() => {
    return {
      systolic: Systolic.rangesFor({ age }),
      diastolic: Diastolic.rangesFor({ age }),
    };
  }, [age]);

  const getMax = useCallback(
    (values: number[]) => (values.length ? Math.max(...values) : NaN),
    []
  );
  const getMin = useCallback(
    (values: number[]) => (values.length ? Math.min(...values) : NaN),
    []
  );
  const getAvg = useCallback(
    (values: number[]) =>
      values.length
        ? +(values.reduce((acc, val) => acc + val, 0) / values.length).toFixed(
            0
          )
        : NaN,
    []
  );
  const toVal = useCallback((val: number) => (isNaN(val) ? "--" : val), []);
  const reduce = useCallback(
    (c: (v: AggregatedBloodPressureData) => number | undefined) => {
      return measurements.reduce<number[]>((acc, val) => {
        const prop = c(val);
        if (prop != null) {
          acc.push(prop);
        }
        return acc;
      }, []);
    },
    [measurements]
  );

  const max = {
    systolic: getMax([
      ...reduce(({ maxDay }) => maxDay?.systolic),
      ...reduce(({ maxNight }) => maxNight?.systolic),
    ]),
    diastolic: getMax([
      ...reduce(({ maxDay }) => maxDay?.diastolic),
      ...reduce(({ maxNight }) => maxNight?.diastolic),
    ]),
  };

  const min = {
    systolic: getMin([
      ...reduce(({ minDay }) => minDay?.systolic),
      ...reduce(({ minNight }) => minNight?.systolic),
    ]),
    diastolic: getMin([
      ...reduce(({ minDay }) => minDay?.diastolic),
      ...reduce(({ minNight }) => minNight?.diastolic),
    ]),
  };

  const avg = {
    systolic: getAvg([
      ...reduce(({ avgDay }) => avgDay?.systolic),
      ...reduce(({ avgNight }) => avgNight?.systolic),
    ]),
    diastolic: getAvg([
      ...reduce(({ avgDay }) => avgDay?.diastolic),
      ...reduce(({ avgNight }) => avgNight?.diastolic),
    ]),
  };

  const risk = {
    avg: getHighestRisk([
      ranges.systolic.findRisk({ mmHg: avg.systolic }),
      ranges.diastolic.findRisk({ mmHg: avg.diastolic }),
    ]),
    max: getHighestRisk([
      ranges.systolic.findRisk({ mmHg: max.systolic }),
      ranges.diastolic.findRisk({ mmHg: max.diastolic }),
    ]),
    min: getHighestRisk([
      ranges.systolic.findRisk({ mmHg: min.systolic }),
      ranges.diastolic.findRisk({ mmHg: min.diastolic }),
    ]),
  };

  return (
    <div className={styles.BloodPressureTableHeader}>
      <div className={styles.summary}>
        <DataSummary
          risk={getRiskColor(risk.avg)}
          label="Average"
          systolic={toVal(avg.systolic)}
          diastolic={toVal(avg.diastolic)}
        />
      </div>
      <div className={styles.summary}>
        <DataSummary
          risk={getRiskColor(risk.max)}
          label="Max"
          systolic={toVal(max.systolic)}
          diastolic={toVal(max.diastolic)}
        />
      </div>
      <div className={styles.summary}>
        <DataSummary
          risk={getRiskColor(risk.min)}
          label="Min"
          systolic={toVal(min.systolic)}
          diastolic={toVal(min.diastolic)}
        />
      </div>
    </div>
  );
}
