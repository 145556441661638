import { BrachialPressure, Risk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { RISK_COLORS } from "render/risk";
import FloatingValueTarget from "render/ui/presentation/FloatingValueTarget";
import ValueItem from "render/ui/symbol/ValueItem";
import BloodPressure from "../components/BloodPressure";

interface LeftArmBloodPressureProps {
  patient: Patient;
  brachialPressure: Metric<"cardio.brachial_pressure.left">[];
}

export default function LeftArmBloodPressure({
  brachialPressure,
  patient,
}: LeftArmBloodPressureProps) {
  const age = useAge(patient);

  const brachial = brachialPressure.at(0);

  const risk = useMemo(() => {
    if (brachial) {
      const { systolic, diastolic } = brachial.unit;
      return BrachialPressure.riskFor({ systolic, diastolic, age });
    }
  }, [brachial, age]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label="Arm Pressure"
      value={
        <ValueItem
          value={brachial ? <BloodPressure brachial={brachial.unit} /> : "--"}
        />
      }
      color={color}
      align="right"
    />
  );
}
