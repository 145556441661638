import { createContext } from "react";
import { Configuration as MSALConfiguration } from "@azure/msal-browser";

type AppInsights = {
  connectionString: string;
};

type MSALScopes = {
  scopes: {
    login: string[];
    token: string[];
  };
};

type MSAL = MSALConfiguration & MSALScopes;

export type Config = {
  apiBaseUrl: string;
  msal: MSAL;
  appInsights: AppInsights;
};

export const Context = createContext<Config | null>(null);
