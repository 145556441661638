import { APITypesV1 } from "@cur8/api-client";
import { PatientBlobURI } from "@cur8/uri";
import { LocaleCode } from "lib/locales";
import { useAPIClient } from "render/context/APIContext";
import { useMSAL } from "render/context/MSALContext";

export type TransSumPair = {
  language: LocaleCode;
  transcriptionSummary: string;
  results: any;
  instruction: string;
  prompt: string;
  temp: number;
  generatedSummary: string;
  savedSummary: string;
  readTime: number;
  isLiked: boolean | undefined;
};

const greetings = ["hi", "hello", "hej"];

export function dropGreeting(text: string) {
  const parts = text.split("\n\n");
  if (parts.length > 0) {
    const tLow = parts[0].toLowerCase();
    for (const greeting of greetings) {
      if (tLow.startsWith(greeting)) {
        return parts.slice(1).join("\n\n");
      }
    }
  }
  return text;
}

/**
 * Save transcription / summary pair for future fine-tuning of summary model
 *
 */

export function useSaveTranscriptionSummaryPair() {
  const api = useAPIClient();
  const { auth } = useMSAL();

  async function saveTranSumPair(patientId: string, pair: TransSumPair) {
    const recordingTimestamp = new Date().getTime().toString();
    const fileName = `speech/${recordingTimestamp}_transum.json`;

    const readTime = Math.round((new Date().getTime() - pair.readTime) / 1000);
    const user = auth?.account?.username || "";

    const payload: TransSumPair & { user: string } = {
      ...pair,
      generatedSummary: dropGreeting(pair.generatedSummary),
      savedSummary: dropGreeting(pair.savedSummary),
      readTime,
      user,
    };

    try {
      await api.blob.putPatientBlob({
        patientId: patientId,
        file: new Blob([JSON.stringify(payload)], {
          type: "application/json;charset=utf-8",
        }),
        path: fileName,
      }).result;
    } catch (error) {
      console.error("Could not upload pair: ", error);
      return;
    }
    const targetUri = new PatientBlobURI(patientId, fileName);

    // create an annotation so we can find the file
    api.annotation.createAnnotation(
      { patientId: patientId },
      {
        acceptState: APITypesV1.AcceptState.Proposed,
        targetUri: targetUri.toString(),
        applicationSpecificTarget: "speech:transumpair",
      }
    );
  }

  return { saveTranSumPair };
}
