import { Slot, Visit, fromAPI } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { APIClient } from "lib/api/client";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import PatientName from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { paths } from "render/routes/paths";
import PassedTime from "render/ui/format/PassedTime";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { Details } from "./components/Details";
import { DetailsV1 } from "./components/DetailsV1";
import LLMBrief from "./components/LLMBrief";
import OnboardingQuestionnaire from "./components/OnboardingQuestionnaire";
import SlotDate from "./fragments/SlotDate";
import SlotTime from "./fragments/SlotTime";
import { useCancelAppointmentPopup } from "./hooks/useCancelAppointmentPopup";
import { useCheckInVisitPopup } from "./hooks/useCheckInVisitPopup";
import styles from "./styles.module.sass";

interface Props {
  patientId: string;
  bookingSlotId: string;
}

function fetchVisit(
  apiClient: APIClient,
  pointer: { patientId: string; visitId: string }
) {
  const { patientId, visitId } = pointer;
  const req = apiClient.visit.fetchVisit({ patientId, visitId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toVisit),
  };
}

function fetchSlot(apiClient: APIClient, slotId: string) {
  const req = apiClient.bookingV2.getSlot({ slotId });

  return {
    abandon: req.abandon,
    result: req.result.then(fromAPI.toSlot),
  };
}

export default function PatientAppointmentPage({
  patientId,
  bookingSlotId,
}: Props) {
  const api = useAPIClient();
  const patient = usePatient(patientId);
  const [slot, setSlot] = useState<Slot>();
  const [visit, setVisit] = useState<Visit>();
  const { data: questionnaireResponse } = useQuestionnaire(patientId);
  const { emitDialog } = useCancelAppointmentPopup();
  const nav = {
    patient: useNav(paths.patient.detail),
    riskAssessment: useNav(paths.patient.riskAssessmentEntry),
  };
  const checkInVisitPopup = useCheckInVisitPopup();
  const appointment = slot;

  useEffect(() => {
    // visitId == slotId
    // the slot ids are wrong atm
    // slot id is returned with a prefix from the backend
    // untill they fix it we need to parse it.
    // https://dev.azure.com/cur8/TheRigg/_workitems/edit/11928
    const visitId = bookingSlotId.includes("_")
      ? bookingSlotId.split("_")[1]
      : bookingSlotId;

    if (!visitId) {
      return;
    }

    const visitReq = fetchVisit(api, {
      patientId,
      visitId,
    });

    visitReq.result.then(setVisit).catch(silenceAbort);

    return () => {
      visitReq.abandon();
    };
  }, [api, patientId, bookingSlotId]);

  useEffect(() => {
    const slotReq = fetchSlot(api, bookingSlotId);
    slotReq.result.then(setSlot).catch(silenceAbort);

    return () => {
      slotReq.abandon();
    };
  }, [api, bookingSlotId]);

  const handleCancelAppointment = useCallback(() => {
    if (appointment) {
      emitDialog(appointment);
    }
  }, [emitDialog, appointment]);

  const handleCheckInAppointment = useCallback(() => {
    if (visit) {
      checkInVisitPopup.emitDialog(visit, setVisit);
    }
  }, [visit, checkInVisitPopup]);

  return (
    <FramedPage>
      <div className={styles.PatientAppointmentDetails}>
        <div className={styles.pageHeader}>
          <div className={styles.checkIn}>
            <PageHeader caption="Appointment Details" />
            {visit && (
              <>
                {/* <HoverTextButton onClick={handleCheckInAppointment}>
                  <CheckinButtonText visit={visit} />
                </HoverTextButton> */}
                <CheckinTime visit={visit} />
              </>
            )}
          </div>
          <HoverTextButton
            onClick={handleCancelAppointment}
            disabled={!appointment}
          >
            Cancel appointment
          </HoverTextButton>
        </div>
        <div className={styles.content}>
          <section className={styles.leftSection}>
            <div className={styles.header}>
              <h1 className={styles.patientName}>
                <PathLink
                  to={nav.patient.to({ patientId })}
                  children={
                    patient ? <PatientName patient={patient} /> : patientId
                  }
                />
              </h1>
              <div className={styles.date}>
                {appointment ? <SlotDate slot={appointment} /> : null}
              </div>
              <div>{appointment ? appointment.room?.site?.siteName : null}</div>
              <div className={styles.time}>
                {appointment ? <SlotTime slot={appointment} /> : null}
              </div>
            </div>

            {questionnaireResponse ? (
              <>
                <OnboardingQuestionnaire response={questionnaireResponse} />
                {patient ? <LLMBrief patient={patient} /> : null}
              </>
            ) : null}
          </section>

          <section className={styles.rightSection}>
            {questionnaireResponse?.questionnaireType === "onboarding/1" ? (
              <DetailsV1 patient={patient} onboarding={questionnaireResponse} />
            ) : (
              <Details patient={patient} onboarding={questionnaireResponse} />
            )}
          </section>
        </div>
      </div>
    </FramedPage>
  );
}

function CheckinButtonText({ visit }: { visit: Visit }) {
  if (!visit.checkinStartTimestamp) return <>Check in</>;
  if (!visit.checkinEndTimestamp) return <>Finish check in</>;
  return <>Assign to room</>;
}

function CheckinTime({ visit }: { visit: Visit }) {
  const timestamp = visit.checkinEndTimestamp || visit.checkinStartTimestamp;

  if (!timestamp) return null;

  return (
    <span className={styles.caption}>
      {visit.checkinEndTimestamp ? "Checked in" : "Check in started"}{" "}
      <span title={timestamp.toFormat("yyyy-LL-dd HH:mm:ss")}>
        <PassedTime date={timestamp} />
      </span>
    </span>
  );
}
