import React, { useCallback, useState } from "react";
import { Context, Dialog } from "./context";

let counter = 0;

interface PopupDialogContextProps {
  children: React.ReactNode;
}

export default function PopupDialogContext({
  children,
}: PopupDialogContextProps) {
  const [dialogs, setDialogs] = useState<Dialog[]>([]);

  const publish = useCallback((id: string, content: React.ReactNode) => {
    setDialogs((dialogs) => {
      return [...dialogs, { id, content }];
    });
  }, []);

  const unpublish = useCallback((id: string) => {
    setDialogs((dialogs) => {
      return dialogs.filter((dialog) => dialog.id !== id);
    });
  }, []);

  const create = useCallback(() => {
    const id = (counter++).toString();

    const emit = (content: React.ReactNode) => {
      publish(id, content);
    };

    const close = () => {
      unpublish(id);
    };

    return { emit, close };
  }, [publish, unpublish]);

  const clear = useCallback(() => {
    setDialogs([]);
  }, []);

  const value = {
    dialogs,
    create,
    clear,
  };

  return <Context.Provider value={value}> {children} </Context.Provider>;
}
