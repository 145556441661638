import { useCallback, useEffect } from "react";
import { debounce } from "@pomle/throb";

export function useRenderAspect(
  renderer: THREE.WebGLRenderer,
  camera: THREE.PerspectiveCamera
) {
  const canvas = renderer.domElement;

  const updateAspect = useCallback(() => {
    const { width, height } = canvas.getBoundingClientRect();
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
  }, [camera, canvas]);

  useEffect(() => {
    updateAspect();
  }, [updateAspect]);

  useEffect(() => {
    const updateDebounced = debounce(updateAspect, 100);

    const observer = new ResizeObserver((entries) => {
      updateDebounced();
    });

    observer.observe(canvas);

    return () => {
      observer.disconnect();
    };
  }, [updateAspect, canvas]);
}
