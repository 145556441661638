import { APITypesV1 } from "@cur8/api-client";
import { PatientBlobURI } from "@cur8/uri";
import { APIClient } from "lib/api/client";
import { MemberSummary } from "lib/doctor-scribe/types";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { EMPTY_MEMBER_SUMMARY } from "../constants";

interface props {
  patientId: string;
}
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

function fetchAnnotations(api: APIClient, patientId: string, target: string) {
  return api.annotation.queryAnnotations({
    patientId: patientId,
    applicationSpecificTarget: target,
  });
}

export function useMemberSummary({ patientId }: props) {
  const api = useAPIClient();
  const stopPolling = useRef(false);
  const requestRef = useRef<ReturnType<typeof fetchAnnotations>>();

  const [memberSummaryAnnotations, setMemberSummaryAnnotations] =
    useState<APITypesV1.Annotation[]>();

  const reloadMemberSummaryAnnotations = useCallback(() => {
    requestRef.current = fetchAnnotations(api, patientId, "llm:memberSummary");
    requestRef.current.result
      .then((result) => setMemberSummaryAnnotations(result.items))
      .catch(silenceAbort);
    return requestRef.current;
  }, [api, patientId]);

  const fetchMemberSummary = useCallback(
    async (uriStr: string) => {
      const uri = PatientBlobURI.parse(uriStr);
      if (uri?.patientId && uri.path) {
        const request = api.blob.fetchPatientBlob({
          patientId: patientId,
          path: uri.path,
        });
        return (await (await request.result).json()) as MemberSummary;
      } else {
        return EMPTY_MEMBER_SUMMARY;
      }
    },
    [api, patientId]
  );

  const pollForMemberSummaryAnnotation = useCallback(
    async (timestamp: string) => {
      let req = reloadMemberSummaryAnnotations();
      const result = await req.result.catch(silenceAbort);
      let annotations = (await req.result).items;
      while (
        !stopPolling.current &&
        (!annotations.length || !annotations[0].targetUri.includes(timestamp))
      ) {
        await sleep(1000);
        req = reloadMemberSummaryAnnotations();
        annotations = (await req.result).items;
      }
    },
    [reloadMemberSummaryAnnotations]
  );

  useEffect(() => {
    stopPolling.current = false;
    reloadMemberSummaryAnnotations();
    return () => {
      stopPolling.current = true;
      requestRef.current?.abandon();
    };
  }, [reloadMemberSummaryAnnotations]);

  return {
    memberSummaryAnnotations,
    reloadMemberSummaryAnnotations,
    pollForMemberSummaryAnnotation,
    fetchMemberSummary,
  };
}
