import { APITypesV1 } from "@cur8/api-client";
import { useEffect, useRef, useState } from "react";
import BusyPlaceholder from "render/ui/throbber/BusyPlaceholder";
import { useAnnotationImage } from "../hooks/useAnnotationImage";
import { atlasUrlFromAnnotation } from "../utils/atlasUrlFromAnnotation";
import classes from "./styles.module.sass";

interface CanvasImageProps {
  anno: APITypesV1.Annotation;
}

export function CanvasImage({ anno }: CanvasImageProps) {
  const { image, originalSize } = useAnnotationImage(anno, true);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [style, setStyle] = useState({
    isFullscreen: false,
    image: classes.CanvasImage,
    container: classes.ImageContainer,
    button: classes.Button,
  });

  function imageFullscreenToggle() {
    if (style.isFullscreen) {
      setStyle({
        isFullscreen: false,
        container: classes.ImageContainer,
        image: classes.CanvasImage,
        button: classes.Button,
      });
    } else {
      setStyle({
        isFullscreen: true,
        container: classes.ImageContainerFullscreen,
        image: classes.CanvasImageFullscreen,
        button: classes.ButtonFullscreen,
      });
    }
  }

  function parseTimestamp(anno: APITypesV1.Annotation) {
    // TODO: we really need to store a source image timestamp as part of annotations
    //return anno.createdAt.split("T")[0];
    return anno.targetUri.split(":")[3].split(".")[0];
  }

  useEffect(() => {
    function drawAnnotation(
      canvasRect: DOMRect,
      ctx: CanvasRenderingContext2D
    ): void {
      if (!originalSize || !image) return;
      const scaleW = originalSize.w / image.width;
      const scaleH = originalSize.h / image.height;

      const r = (anno.data as APITypesV1.AnnotationData2D).rect;
      const ss = ctx.strokeStyle;
      ctx.strokeStyle = "#bbffbb";
      ctx.lineWidth = 3;
      ctx.strokeRect(
        r.left / scaleW,
        r.top / scaleH,
        r.width / scaleW,
        r.height / scaleH
      );
      ctx.stroke();
      ctx.strokeStyle = ss;
    }

    if (canvasRef.current && image) {
      canvasRef.current.width = image.width;
      canvasRef.current.height = image.height;

      const context = canvasRef.current.getContext("2d");
      if (!context) return;

      context.drawImage(
        image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      drawAnnotation(canvasRef.current.getBoundingClientRect(), context);
    }
  }, [image, anno, originalSize]);

  if (!image) {
    return (
      <div>
        <BusyPlaceholder />
      </div>
    );
  }

  const timeStamp = !style.isFullscreen ? (
    <div className={classes.Timestamp}>
      <a href={atlasUrlFromAnnotation(anno)}>{parseTimestamp(anno)}</a>
    </div>
  ) : undefined;

  return (
    <div className={style.container} data-annotationId={anno.id}>
      <div className={classes.DermaFlex}>
        <canvas
          className={style.image}
          ref={canvasRef}
          onClick={imageFullscreenToggle}
        ></canvas>
        {timeStamp}
      </div>
    </div>
  );
}
