import styles from "./styles.module.sass";

export interface ToggleItemProps {
  isActive?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

export default function ToggleItem({
  children,
  onClick,
  isActive = false,
}: ToggleItemProps) {
  return (
    <div className={styles.ToggleItem} data-active={isActive} onClick={onClick}>
      {children}
    </div>
  );
}
