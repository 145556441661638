import { RangeLabel, RiskRange } from "@cur8/health-risks-calc";
import { ChartRange } from "../types";

const LABEL_MAP: Record<RangeLabel, string> = {
  elevated: "Elevated",
  diabetes: "Diabetes",
  high: "High",
  low: "Low",
  mild: "Mild",
  moderate: "Moderate",
  normal: "Normal",
  optimal: "Optimal",
  "pre-diabetes": "Pre-Diabetes",
  "very high": "Very High",
  "very low": "Very Low",
};

export function toChartRanges(ranges: readonly RiskRange[]) {
  return ranges.map((range) => {
    const isToInfinity = !isFinite(range.to);
    const isFromInfinity = !isFinite(range.from);

    return {
      from: isFromInfinity ? range.to * 0.9 : range.from,
      to: isToInfinity ? range.from * 1.1 : range.to,
      risk: range.risk,
      label: range.label ? LABEL_MAP[range.label] : "-",
    };
  });
}

export function capToMaxRanges(ranges: ChartRange[], currentNumber?: number) {
  if (!currentNumber) {
    return ranges.slice(0, 3);
  }

  const sortedRanges = ranges.toSorted((a, b) => a.to - b.to);

  const activeRange = sortedRanges.find((range, index) => {
    const isFirstRange = index === 0;
    const isLastRange = index === sortedRanges.length - 1;

    if (isFirstRange) {
      return currentNumber < range.to;
    }

    if (isLastRange) {
      return currentNumber >= range.from;
    }

    return currentNumber >= range.from && currentNumber < range.to;
  });

  const index = activeRange ? sortedRanges.indexOf(activeRange) : 0;
  const len = sortedRanges.length;
  let start = index - 1;
  let end = start + 3;

  if (end > len) {
    end = len;
    start = Math.max(0, end - 3);
  }
  if (start < 0) {
    start = 0;
    end = Math.min(start + 3, len);
  }

  return sortedRanges.slice(start, end);
}
