import { Risk } from "@cur8/health-risks-calc";
import styles from "./styles.module.sass";

type Variant = "normal" | "monitor";

const VariantMap: Record<Risk, Variant> = {
  [Risk.Unknown]: "monitor",
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.Risk]: "monitor",
  [Risk.HighRisk]: "monitor",
  [Risk.ImmediateRisk]: "monitor",
};

const LabelMap: Record<Risk, string> = {
  [Risk.Unknown]: "Unknown",
  [Risk.Optimal]: "Optimal",
  [Risk.Normal]: "Normal",
  [Risk.Risk]: "Monitor",
  [Risk.HighRisk]: "Monitor",
  [Risk.ImmediateRisk]: "Monitor",
};

interface MetricStatusBadgeProps {
  risk: Risk;
}

export default function MetricStatusBadge({ risk }: MetricStatusBadgeProps) {
  return (
    <div className={styles.MetricStatusBadge} data-variant={VariantMap[risk]}>
      {LabelMap[risk]}
    </div>
  );
}
