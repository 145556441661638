import { SkinBookTracking } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { isRecordingURI } from "lib/uri/guard";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import DayDate from "render/ui/format/DayDate";
import ArrowIcon from "render/ui/symbol/ArrowIcon";
import PaginatedTable from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import SkinBookStatus from "../SkinBookStatus";
import styles from "./styles.module.sass";

type DermaTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
>;

export default function DermaTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
}: DermaTableProps) {
  const nav = {
    atlas: useNav(paths.patient.recording.atlas, query.atlas),
  };

  const getAtlasLink = (item: SkinBookTracking) => {
    const recordingURI = item.sourceUris.at(0);
    if (recordingURI && isRecordingURI(recordingURI)) {
      const { recordingId, deviceId } = recordingURI;
      const patientId = item.patientId;

      return nav.atlas.to({ patientId, deviceId, recordingId });
    }
  };

  return (
    <div className={styles.DermaTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Scan</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>
              {item.scanCreatedAt ? (
                <DayDate date={item.scanCreatedAt} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <SkinBookStatus item={item} />
            </td>
            <td className={styles.actionCol}>
              <a href={getAtlasLink(item)} target="_blank" rel="noreferrer">
                Atlas
                <ArrowIcon direction="right" />
              </a>
            </td>
          </tr>
        )}
      />
    </div>
  );
}
