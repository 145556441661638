import { useMemo } from "react";
import { useAvatar } from "render/hooks/patient/useAvatar";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Context } from "./context";

interface AvatarContextProps {
  children: React.ReactNode;
}

export default function AvatarContext({ children }: AvatarContextProps) {
  const { patient, scans } = usePatientData();

  const skinScan = useMemo(() => {
    if (!scans) {
      return;
    }

    const skinScan = scans.skin.at(0);
    if (!skinScan) {
      return new Error("No SkinScan available");
    }

    return skinScan;
  }, [scans]);

  const avatar = useAvatar(patient, skinScan);

  return <Context.Provider value={avatar}>{children}</Context.Provider>;
}
