import { ImmutableScan } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useChromophoreTimeseriesMulti } from "render/pages/TissuePages/hooks/useChromophoreTimeseriesMulti";
import { LCJSPoint, Property } from "render/pages/TissuePages/lib/types";

interface PropertyRatioProps {
  indexRemap: number[];
  property: Property;
  scan: ImmutableScan;
  timestamps: number[];
}

// Function to calculate the mean y-value excluding points with y = 0
function seriesMean(series: LCJSPoint[]): number {
  if (series.length <= 0) {
    throw new Error("No points in series");
  }
  const nonZeroPoints = series.filter((point) => point.y !== 0);
  if (nonZeroPoints.length === 0) {
    throw new Error("No Y-values");
  }

  const sumY = nonZeroPoints.reduce((acc, point) => acc + point.y, 0);
  return sumY / nonZeroPoints.length;
}

export default function PropertyRatio({
  indexRemap,
  property,
  scan,
  timestamps,
}: PropertyRatioProps) {
  const { filtered } = useTissueAnnotationContext();
  const series = useChromophoreTimeseriesMulti(
    scan,
    filtered.roi,
    indexRemap,
    property,
    timestamps
  );
  const [ratio, setRatio] = useState<string>("");

  useEffect(() => {
    if (!series) {
      return;
    }
    const forearm = series.find((ser) => ser.label === "forearm");
    const hypothenar = series.find((ser) => ser.label === "hypothenar");
    if (forearm && hypothenar) {
      try {
        const meanForearm = seriesMean(forearm.series);
        const meanHypothenar = seriesMean(hypothenar.series);
        setRatio((meanHypothenar / meanForearm).toFixed(2));
      } catch (err) {
        setRatio("NaN");
        console.error(err);
      }
    }
  }, [series]);

  return <div>{ratio}</div>;
}
