import { Sticky } from "@pomle/react-viewstack";
import { Lesion, isDermoscopyAnnotation } from "lib/lesion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import PanView from "render/ui/presentation/PanView";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import ImageViewer from "./components/ImageViewer";
import LesionMacroPanel from "./components/LesionMacroPanel";
import styles from "./styles.module.sass";

export default function LesionMacroLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();
  const {
    skin: { lesions },
  } = usePatientData();

  const { ui } = useDashboardContext();

  const physId = ui.physId;

  const [selected, setSelected] = useState<Lesion>();

  useEffect(() => {
    if (!lesions) {
      return;
    }

    setSelected(lesions[0]);
  }, [lesions]);

  useEffect(() => {
    if (!lesions || !physId) {
      return;
    }

    for (const lesion of lesions) {
      if (lesion.physicalId === physId) {
        setSelected(lesion);
        break;
      }
    }
  }, [lesions, physId]);

  const dermaId = ui.dermaId;

  const dermaAnno = useMemo(() => {
    if (!lesions) {
      return;
    }

    for (const lesion of lesions) {
      for (const link of lesion.links) {
        const anno = link.annotation;
        if (isDermoscopyAnnotation(anno) && anno.id === dermaId) {
          return anno;
        }
      }
    }
  }, [lesions, dermaId]);

  const hasDermaAnno = !!dermaAnno;

  /**
   * When we expand a derma image from thumbnail to fullscreen, we want to animate the fullscreen view from the thumbnail position on screen
   * For the effect to work, we need to execute the following things in order:
   * 1. calculate thumbnail position on the screen
   * 2. apply the thumbnail position as initial offset of the fullscreen view while the fullscreen view is still inactive/hidden
   * 3. activate the fullscreen view, which will trigger the animation from the thumbnail position to center of the screen
   *
   * Introducing an "is-image-viewer-ready" flag allows us to delay the activation of the fullscreen view after thumbnail click by one render cycle
   * Which gives us time to apply the initial offset to the fullscreen view before it becomes visible and animated
   */
  const imageViewerContainerRef = useRef<HTMLDivElement>(null);
  const [isImageViewerReady, setIsImageViewerReady] = useState(false);
  useEffect(() => {
    if (hasDermaAnno) {
      const dermaFlagThumbImage = document.querySelector(
        `[data-role="derma-flag-button"][data-derma-id="${dermaId}"] img`
      ) as HTMLButtonElement;

      if (!dermaFlagThumbImage) return;

      const { top, width, height, left } =
        dermaFlagThumbImage.getBoundingClientRect();

      const dermaFlagThumbImageCenter = {
        x: left + width * 0.5,
        y: top + height * 0.5,
      };

      const dermaFlagThumbImageOffset = {
        x:
          (dermaFlagThumbImageCenter.x - window.innerWidth * 0.5) /
          window.innerWidth,
        y:
          (dermaFlagThumbImageCenter.y - window.innerHeight * 0.5) /
          window.innerHeight,
      };

      const imageViewerContainer = imageViewerContainerRef.current;
      if (!imageViewerContainer) return;

      imageViewerContainer.style.setProperty(
        "--derma-flag-thumb-offset-from-viewport-center-x",
        `${Math.round(dermaFlagThumbImageOffset.x * 100)}%`
      );
      imageViewerContainer.style.setProperty(
        "--derma-flag-thumb-offset-from-viewport-center-y",
        `${Math.round(dermaFlagThumbImageOffset.y * 100)}%`
      );

      setIsImageViewerReady(true);
    }
  }, [hasDermaAnno, dermaId]);

  return (
    <Layout active={active}>
      <div className={styles.LesionMacroLayout} data-active={active}>
        <div className={styles.nav}>
          <div className={styles.thumbs}></div>

          <div
            ref={imageViewerContainerRef}
            className={styles.imageViewerContainer}
            data-active={hasDermaAnno && isImageViewerReady}
            data-is-transition-enabled={isImageViewerReady}
          >
            <PanView zoomScale={2} active={hasDermaAnno && isImageViewerReady}>
              {(_) => (
                <div className={styles.content}>
                  <ImageViewer annotation={dermaAnno} />
                </div>
              )}
            </PanView>
          </div>
        </div>

        <div className={styles.panel}>
          <SidePanel>
            <Sticky delay={1000}>
              {active && patient && (
                <LesionMacroPanel
                  active={active}
                  patient={patient}
                  selected={selected}
                />
              )}
            </Sticky>
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
