import { Patient } from "@cur8/rich-entity";
import { resolveSummaries } from "lib/health-risk";
import { useMemo } from "react";
import { useMostRecentMetrics } from "render/hooks/api/metrics/useMostRecentMetrics";
import { Score2 } from "render/hooks/api/metrics/useScore2";
import { useScore2Status } from "render/hooks/api/metrics/useScore2Status";
import { useAge } from "render/hooks/patient/useAge";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import MetricStatus from "render/ui/presentation/MetricStatus";
import MetricStatusBadge from "render/ui/presentation/MetricStatusBadge";
import BloodworkSymbol from "./assets/bloodwork.svg?react";

interface BloodworkItemProps {
  patient: Patient;
  bloodwork: PatientMetrics["bloodwork"];
  score2: Score2 | undefined;
}

export default function BloodworkItem({
  patient,
  bloodwork,
  score2,
}: BloodworkItemProps) {
  const age = useAge(patient);
  const sex = patient.sex;

  const latest = useMostRecentMetrics(bloodwork);

  const score2Risk = useScore2Status(patient, score2);

  const risk = useMemo(() => {
    if (!score2Risk || !latest) {
      return;
    }

    const summaries = resolveSummaries(latest, {
      sex,
      age,
      score2Risk,
    });

    const risks = summaries.map((s) => s.summary.risk);

    return Math.max(...risks);
  }, [age, sex, score2Risk, latest]);

  return (
    <MetricStatus
      caption="Bloodwork"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <BloodworkSymbol />
    </MetricStatus>
  );
}
