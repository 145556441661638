import { Converters as ForceConverter } from "lib/conversion/force";
import { Converters as HeightConverter } from "lib/conversion/height";
import { Converters as PressureConverter } from "lib/conversion/pressure";
import { useMemo } from "react";
import { useMetricsUnits } from "render/context/MetricsUnitContext/MetricsUnitContext";

export function useUnitConverter() {
  const system = useMetricsUnits();

  return useMemo(() => {
    return {
      force: ForceConverter[system.force],
      height: HeightConverter[system.length],
      pressure: PressureConverter[system.pressure],
    };
  }, [system]);
}
