import { Sex } from "@cur8/rich-entity";
import { BloodPressureType } from "../../lib/projection";
import BenchmarkResultDescription from "../BenchmarkResultDescription";
import styles from "./styles.module.sass";

interface HeaderProps {
  title: string;
  subtitle: string;
  sex: Sex;
  age: number;
  currentValue: number;
  bloodPressureType: BloodPressureType;
}

export default function Header({
  title,
  sex,
  age,
  currentValue,
  bloodPressureType,
  subtitle,
}: HeaderProps) {
  return (
    <div className={styles.Header}>
      <div className={styles.titles}>
        <h3>{title}</h3>
        <span className={styles.aux}>[{subtitle}]</span>
      </div>
      <div className={styles.description}>
        <BenchmarkResultDescription
          sex={sex}
          age={age}
          currentValue={currentValue}
          bloodPressureType={bloodPressureType}
        />
      </div>
    </div>
  );
}
