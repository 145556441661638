import { APITypesV1 } from "@cur8/api-client";
import { formatDate } from "lib/doctor-scribe/utils";
import { ChangeEvent, useRef } from "react";
import ReloadIcon from "./assets/reload.svg?react";
import styles from "./styles.module.sass";

interface AnnotationSelectProps {
  label: string;
  annotations: APITypesV1.Annotation[] | undefined;
  selectedValue: string;
  onSelectedId: (id: string) => void;
  onReload: () => void;
}

export default function AnnotationSelect({
  label,
  annotations,
  selectedValue,
  onSelectedId,
  onReload,
}: AnnotationSelectProps) {
  const reloadRef = useRef<HTMLDivElement>(null);

  function handleReload() {
    if (reloadRef.current) {
      reloadRef.current.classList.add(styles.spin);
      reloadRef.current.onanimationend = () => {
        reloadRef.current?.classList.remove(styles.spin);
      };
    }
    onReload();
  }

  return (
    <div>
      <label>{label}</label>
      <div className={styles.content}>
        {annotations && (
          <select
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              onSelectedId(e.currentTarget.value);
            }}
            value={selectedValue}
          >
            <option value="" label="None" />
            {annotations.map((a) => (
              <option label={formatDate(a.createdAt)} value={a.id} key={a.id} />
            ))}
          </select>
        )}
        <div className={styles.reloadIcon} ref={reloadRef}>
          <ReloadIcon onClick={handleReload} />
        </div>
      </div>
    </div>
  );
}
