import ArrowIcon from "./assets/arrow.svg?react";
import styles from "./styles.module.sass";

interface MainUSPItemProps {
  value: React.ReactNode;
  unit?: React.ReactNode;
}

export default function MainUSPItem({ value, unit }: MainUSPItemProps) {
  return (
    <span className={styles.MainUSPItem}>
      <ArrowIcon />
      <span className={styles.amount}>{value}</span>
      {unit && <span className={styles.unit}>{unit}</span>}
    </span>
  );
}
