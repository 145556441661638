import { createContext } from "react";

export type Dialog = {
  id: string;
  content: React.ReactNode;
};

export type PopupDialogContextValue = {
  dialogs: Dialog[];
  create(): {
    emit(content: React.ReactNode): void;
    close(): void;
  };
  clear(): void;
};

export const Context = createContext<PopupDialogContextValue | null>(null);
