import { ImmutableScan, Patient } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { toPose } from "lib/meshuga/pose";
import { createFakeSMPLPositions, findSMPLAreas } from "lib/smpl";
import { isRecordingURI } from "lib/uri/guard";
import { useMemo } from "react";
import heartPCDLocation from "render/assets/models/heart.pcd";
import { usePointCloud } from "render/hooks/api/meshuga/usePointCloud";
import { useSMPLPointCloud } from "render/hooks/api/meshuga/useSMPLPointCloud";
import { usePCD } from "render/hooks/api/usePCD";

function useSMPL(
  pose: "front" | "back",
  uri?: RecordingURI | Error,
  kind?: "neutral" | "male" | "female"
) {
  const pointsResult = useSMPLPointCloud(pose, uri, kind);

  const areas = useMemo(() => {
    if (!pointsResult) {
      return;
    }

    if (pointsResult.error) {
      return createFakeSMPLPositions();
    }

    return findSMPLAreas(pointsResult.data);
  }, [pointsResult]);

  return {
    points: pointsResult?.data,
    areas,
  };
}

export type SkinScanSpatial = ReturnType<typeof useAvatar>;

export function useAvatar(patient?: Patient, skinScan?: ImmutableScan | Error) {
  const uri = useMemo(() => {
    if (!skinScan) {
      return;
    }

    if (skinScan instanceof Error) {
      return skinScan;
    }

    const recordingURI = skinScan.sourceUris.filter(isRecordingURI).at(0);
    if (!recordingURI) {
      return new Error("No RecordingURI on SkinScan");
    }

    return recordingURI;
  }, [skinScan]);

  const kind = useMemo(() => {
    return patient ? toPose(patient.sex) : undefined;
  }, [patient]);

  const heartPCDResult = usePCD(heartPCDLocation);

  return {
    front: {
      world: usePointCloud("front", uri)?.data,
      smpl: useSMPL("front", uri, kind),
    },
    back: {
      world: usePointCloud("back", uri)?.data,
      smpl: useSMPL("back", uri, kind),
    },
    heart: heartPCDResult?.data,
  };
}
