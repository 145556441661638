import styles from "./styles.module.sass";

interface ValueBoxProps {
  caption: React.ReactNode;
  value: React.ReactNode;
}

export default function ValueBox({ caption, value }: ValueBoxProps) {
  return (
    <div className={styles.ValueBox}>
      <div className={styles.caption}>{caption}</div>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
