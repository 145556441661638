import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { ValidScanTypes, parseScanType } from "lib/scanType";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export type PatientImmutableScans = ReturnType<typeof groupScans>;

function typeFilter(
  scanType: ImmutableScan["scanType"],
  type: ValidScanTypes
): boolean {
  const pt = parseScanType(scanType);
  return pt?.type === type;
}

function groupScans(scans: ImmutableScan[]) {
  const groups = new Map<number, ImmutableScan[]>();

  const latest = scans.at(0);
  if (latest) {
    const day = 60 * 60 * 24;

    scans.forEach((scan) => {
      const s = latest.timestamp.toUnixInteger();
      const i = scan.timestamp.toUnixInteger();
      const index = Math.floor((s - i) / day);
      if (!groups.has(index)) {
        groups.set(index, []);
      }
      groups.get(index)?.push(scan);
    });
  }

  return {
    cardio: scans.filter((scan) =>
      typeFilter(scan.scanType, ValidScanTypes.Cardio)
    ),
    skin: scans.filter((scan) =>
      typeFilter(scan.scanType, ValidScanTypes.Skin)
    ),
    stethoscope: scans.filter((scan) =>
      typeFilter(scan.scanType, ValidScanTypes.Stethoscope)
    ),
    tissue: scans.filter((scan) =>
      typeFilter(scan.scanType, ValidScanTypes.Tissue)
    ),
    history: Array.from(groups.values()),
  };
}

function fetchScans(
  api: APIClient,
  patientId: string
): Promise<ImmutableScan[]> {
  function fetchType(scanType: string) {
    return api.scan
      .queryImmutableScans({
        patientId,
        scanType,
        order: APITypesV1.SortOrder.Desc,
        pageSize: 20,
      })
      .result.then((result) => result.items.map(fromAPI.toImmutableScan));
  }

  return Promise.all([
    fetchType("cardio/"),
    fetchType("skin/"),
    fetchType("stetho/"),
    fetchType("tci/"),
    fetchType("tci.pad/"),
  ]).then((groups) => groups.flatMap((group) => group));
}

export function usePatientImmutableScans(patientId: string) {
  const api = useAPIClient();

  const [scans, setScans] = useState<PatientImmutableScans>();

  useEffect(() => {
    fetchScans(api, patientId).then(groupScans).then(setScans);

    return () => {
      setScans(undefined);
    };
  }, [api, patientId]);

  return scans;
}
