import { APITypesV1, PaginatedResponse } from "@cur8/api-client";
import { Annotation, fromAPI } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import PatientName from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import BackButtonLink from "render/ui/trigger/BackButtonLink";
import MarkingsTable from "./components/MarkingsTable";
import styles from "./styles.module.sass";

interface TrackedMarkingsPageProps {
  patientId: string;
}

export default function TrackedMarkingsPage({
  patientId,
}: TrackedMarkingsPageProps) {
  const [annotations, setAnnotations] =
    useState<PaginatedResponse<Annotation>>();
  const [
    physicalArtefactsByPhysicalArtifactId,
    setPhysicalArtefactsByPhysicalArtifactId,
  ] = useState<Record<string, APITypesV1.PhysicalArtefact>>({});

  const api = useAPIClient();
  const patient = usePatient(patientId);

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  const fetchPhysicalArtifactsForAnnotation = useCallback(
    (annotation: Annotation) => {
      const physicalArtefactId = annotation.physicalArtefactId;
      if (!physicalArtefactId) return;

      api.physicalArtefact
        .getPhysicalArtefactLatestVersion({
          patientId,
          physicalArtefactId,
        })
        .result.then((physicalArtefactsByPhysicalArtifactId) => {
          setPhysicalArtefactsByPhysicalArtifactId((v) => {
            return {
              ...v,
              [physicalArtefactId]: physicalArtefactsByPhysicalArtifactId,
            };
          });
        });
    },
    [api.physicalArtefact, patientId]
  );

  const fetchAnnotations = useCallback(
    ({ continuationToken }: { continuationToken?: string }) => {
      const req = api.annotation.queryAnnotations({
        patientId,
        continuationToken,
        applicationSpecificTarget: "panorama",
        acceptState: APITypesV1.AcceptState.Accepted,
      });

      req.result.then(({ items, nextPage }) => {
        const annotations = items.map(fromAPI.toAnnotation);

        setAnnotations((v) => {
          return {
            nextPage,
            items: [...(v?.items ?? []), ...annotations],
          };
        });

        for (let i = 0; i < annotations.length; i++) {
          const annotation = annotations[i];
          fetchPhysicalArtifactsForAnnotation(annotation);
        }
      });

      return req;
    },
    [api.annotation, fetchPhysicalArtifactsForAnnotation, patientId]
  );

  useEffect(() => {
    if (annotations?.items) return;
    const req = fetchAnnotations({ continuationToken: undefined });

    return req.abandon;
  }, [annotations, api, patientId, fetchAnnotations]);

  return (
    <FramedPage>
      <div className={styles.TrackedMarkingsPage}>
        <div>
          <BackButtonLink to={nav.patient.to({ patientId })}>
            {patient && <PatientName patient={patient} />}
          </BackButtonLink>
          <PageHeader caption="Markings" />
        </div>
        <MarkingsTable
          hasNextPage={!!annotations?.nextPage}
          items={annotations?.items ?? []}
          onLoadMore={() => {
            fetchAnnotations({ continuationToken: annotations?.nextPage });
          }}
          physicalArtefactsByPhysicalArtifactId={
            physicalArtefactsByPhysicalArtifactId
          }
          patientId={patientId}
        />
      </div>
    </FramedPage>
  );
}
