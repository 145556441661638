import { APITypesV2 } from "@cur8/api-client";
import {
  ConvertedQuestionnaire,
  convertQuestionnaire,
} from "lib/questionnaire/convert";
import { useMemo } from "react";
import styles from "./styles.module.sass";

interface OnboardingQuestionnaireProps {
  response: APITypesV2.QuestionnaireResponse;
}

export default function OnboardingQuestionnaire({
  response,
}: OnboardingQuestionnaireProps) {
  const answers = useMemo(() => {
    return convertQuestionnaire(response);
  }, [response]);

  if (!response) {
    return (
      <div className={styles.question}>
        <div className={styles.title}>Questionnaire was not completed.</div>
      </div>
    );
  }

  const healthConditions = answers?.healthConditions;

  const additionalWorries = answers?.additionalWorries;

  const healthConditionsFamily =
    answers?.healthConditionsFamily || getV2FamilyHealthAnswers(answers);

  if (!healthConditions && !additionalWorries && !healthConditionsFamily) {
    return (
      <div className={styles.question}>
        <div className={styles.title}>
          No information of health conditions, family health conditions or
          health concerns provided in the onboarding.
        </div>
      </div>
    );
  }

  return (
    <>
      {healthConditions && (
        <div className={styles.question}>
          <div className={styles.title}>Health conditions in the last year</div>
          <div className={styles.answer}>{healthConditions}</div>
        </div>
      )}
      {additionalWorries && (
        <div className={styles.question}>
          <div className={styles.title}>Questions or concerns</div>
          <div className={styles.answer}>{additionalWorries}</div>
        </div>
      )}
      {healthConditionsFamily && (
        <div className={styles.question}>
          <div className={styles.title}>Family health conditions</div>
          <div className={styles.answer}>{healthConditionsFamily}</div>
        </div>
      )}
    </>
  );
}

function isYes(answer: string | undefined) {
  return answer === "Yes";
}

function getV2FamilyHealthAnswers(answers: ConvertedQuestionnaire) {
  const { cardiovascular, skinCancer, diabetes, relevantConditions } = answers;

  return [
    isYes(cardiovascular) && "Cardiovascular disease",
    isYes(skinCancer) && "Skin cancer",
    isYes(diabetes) && "Diabetes",
    relevantConditions,
  ]
    .filter(Boolean)
    .join(", ");
}
