import { Visit } from "@cur8/rich-entity";
import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import CheckInVisitPopup from "../components/CheckInVisitPopup";

export function useCheckInVisitPopup() {
  const { create } = usePopupDialog();

  const emitDialog = useCallback(
    (visit: Visit, onDone: (visit: Visit) => void) => {
      const { emit, close } = create();

      emit(
        <CheckInVisitPopup
          visit={visit}
          onDone={(updatedVisit) => {
            close();
            onDone(updatedVisit);
          }}
          onCancel={close}
        />
      );
    },
    [create]
  );

  return {
    emitDialog,
  };
}
