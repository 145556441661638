import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useImmutableScanBlob<T>({
  scan,
  resultName,
  path,
  parse,
}: {
  scan?: ImmutableScan;
  resultName: string;
  path: string;
  parse: (response: Response) => Promise<T>;
}) {
  const api = useAPIClient().blob;

  const [data, setData] = useState<T>();

  useEffect(() => {
    if (!scan) {
      return;
    }

    const request = api.fetchImmutableScanBlob({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName,
      path,
    });

    request.result.then(parse).then(setData).catch(silenceAbort);

    return () => {
      setData(undefined);
      request.abandon();
    };
  }, [api, scan, resultName, path, parse]);

  return data;
}
