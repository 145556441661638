import { APITypesV1 } from "@cur8/api-client";
import { useState } from "react";
import BusyPlaceholder from "render/ui/throbber/BusyPlaceholder";
import { useAnnotationImage } from "../hooks/useAnnotationImage";
import classes from "./styles.module.sass";

interface AnnotationImageProps {
  anno: APITypesV1.Annotation;
  isOverview: boolean;
  deletable: boolean;
  onDelete: () => void;
}

export function AnnotationImage({
  anno,
  isOverview,
  deletable,
  onDelete,
}: AnnotationImageProps) {
  const { image } = useAnnotationImage(anno, isOverview);

  console.log(anno);

  const [style, setStyle] = useState({
    isFullscreen: false,
    image: classes.AnnotationImage,
    container: classes.ImageContainer,
    button: classes.Button,
  });

  function imageFullscreenToggle() {
    if (style.isFullscreen) {
      setStyle({
        isFullscreen: false,
        container: classes.ImageContainer,
        image: classes.AnnotationImage,
        button: classes.Button,
      });
    } else {
      setStyle({
        isFullscreen: true,
        container: classes.ImageContainerFullscreen,
        image: classes.AnnotationImageFullscreen,
        button: classes.ButtonFullscreen,
      });
    }
  }

  function parseTimestamp(anno: APITypesV1.Annotation) {
    // TODO: we need to store source timestamp as part of annotations ...
    if (anno.applicationSpecificTarget?.includes("derma")) {
      return anno.createdAt.split(".")[0];
    } else {
      return anno.targetUri.split(":")[3].split(".")[0];
    }
  }

  if (!image) {
    return (
      <div>
        <BusyPlaceholder />
      </div>
    );
  }

  function timestampLabel() {
    if (style.isFullscreen) return undefined;
    return <div className={classes.Timestamp}>{parseTimestamp(anno)}</div>;
  }

  const size = (
    anno.classification as APITypesV1.MoleClassification
  )?.size?.toFixed(2);
  const sizeLabel =
    !style.isFullscreen && size ? (
      <div className={classes.Timestamp}>{size + " mm"}</div>
    ) : undefined;

  const annoType = (anno.classification as APITypesV1.MoleClassification)
    ?.$type;
  const typeLabel =
    !style.isFullscreen && annoType ? (
      <div className={classes.Timestamp}>{annoType}</div>
    ) : undefined;

  return (
    <div className={style.container}>
      <div className={classes.DermaFlex}>
        <img
          className={style.image}
          src={image.src}
          alt=""
          onClick={imageFullscreenToggle}
        />
        <div className={classes.Footer}>
          {timestampLabel()} {typeLabel} {sizeLabel}
        </div>
      </div>
    </div>
  );
}
