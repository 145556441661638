import { BrachialPressure } from "@cur8/measurements";
import styles from "./styles.module.sass";

interface BloodPressureProps {
  brachial: BrachialPressure;
}

export default function BloodPressure({ brachial }: BloodPressureProps) {
  return (
    <span className={styles.BloodPressure}>
      <span className={styles.systolic}>{brachial.systolic.mmHg}</span>
      <span className={styles.diastolic}>
        &nbsp;/&nbsp;{brachial.diastolic.mmHg}
      </span>
    </span>
  );
}
