import { useMemo } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import StudySymbol from "render/ui/symbol/StudySymbol";
import { Layer } from "../../../../types";
import styles from "./styles.module.sass";

const HIDE = new Set([Layer.LesionLibrary]);

export default function DisclaimerLayout() {
  const { isCurrentVisitInSweden } = usePatientData();

  const { ui } = useDashboardContext();

  const { layer, dermaId, camera } = ui;

  const variant = useMemo(() => {
    if (layer === Layer.Skin && camera) {
      return "dark";
    }

    if (layer === Layer.LesionMacro && dermaId) {
      return "dark";
    }

    return "light";
  }, [layer, dermaId, camera]);

  const content = useMemo(() => {
    if (isCurrentVisitInSweden) {
      return (
        <p>
          Metrics marked with <StudySymbol forceShow={true} /> are part of our
          clinical studies. Consult with your medical team before taking action.
        </p>
      );
    }

    if (ui.layer === Layer.Heart) {
      return (
        <p>
          Metrics marked with <StudySymbol forceShow={true} /> are shown for
          educational purposes only. Consult with your medical team before
          taking action.
        </p>
      );
    }

    return <p>Consult with your medical team before taking action.</p>;
  }, [isCurrentVisitInSweden, ui.layer]);

  if (isCurrentVisitInSweden == null) {
    return null;
  }

  return (
    <div
      className={styles.DisclaimerLayout}
      data-visible={!HIDE.has(layer)}
      data-variant={variant}
    >
      {content}
    </div>
  );
}
