import { resolveHighestSide } from "lib/types/body";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import CirculationSection from "./components/CirculationSection";
import styles from "./styles.module.sass";

interface CirculationLayoutProps extends LayoutProps {}

export default function CirculationLayout({ active }: CirculationLayoutProps) {
  const { patient } = usePatientData();

  const {
    metrics: { cardio },
  } = usePatientData();

  const leftBrachialPressure =
    cardio.brachialPressure.left?.at(0)?.unit.systolic.mmHg;
  const rightBrachialPressure =
    cardio.brachialPressure.right?.at(0)?.unit.systolic.mmHg;

  const higherSide = useMemo(() => {
    return resolveHighestSide(
      leftBrachialPressure,
      rightBrachialPressure,
      "left"
    );
  }, [leftBrachialPressure, rightBrachialPressure]);

  return (
    <Layout active={active}>
      <div className={styles.CirculationLayout}>
        <div className={styles.leftPanel} data-active={active}>
          <SidePanel>
            {patient && (
              <CirculationSection
                patient={patient}
                side="right"
                showBenchmark={higherSide === "right"}
              />
            )}
          </SidePanel>
        </div>

        <div className={styles.rightPanel} data-active={active}>
          <SidePanel>
            {patient && (
              <CirculationSection
                patient={patient}
                side="left"
                showBenchmark={higherSide === "left"}
              />
            )}
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
