import { useContext } from "react";
import { Context } from "./context";

export function useKeyboardContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useKeyboardContext without KeyboardContext");
  }
  return context;
}
