import {
  AnklePressure,
  Basophils,
  BloodOxygen,
  CRP,
  Cholesterol,
  CholesterolHDL,
  Diastolic,
  Eosinophils,
  EyePressure,
  Glucose,
  HDL,
  Haemoglobin,
  HbA1c,
  HeartRate,
  LDL,
  Lymphocytes,
  Monocytes,
  Neutrophils,
  NonHDL,
  Risk,
  RiskRange,
  Sex,
  Systolic,
  ToePressure,
  Triglycerides,
  Waistline,
  WhiteBloodCells,
  findRange,
} from "@cur8/health-risks-calc";
import { MetricName, Unit } from "@cur8/measurements";
import { Score2RiskStatus } from "render/hooks/api/metrics/useScore2Status";
import { Entry } from "render/ui/presentation/MeasurementTimeline";
import { clamp } from "./math";
import { Metric } from "./metric";

export function softCap(low: number, high: number, entries: Entry[] = []) {
  const values = entries.map((e) => e.value).concat(low, high);
  const min = Math.min(...values);
  const max = Math.max(...values);

  return function capRange(range: RiskRange) {
    return {
      ...range,
      from: clamp(range.from, min, max),
      to: clamp(range.to, min, max),
    };
  };
}

export enum Deviation {
  Above = 1,
  None = 0,
  Below = -1,
}

export function toSummary(ranges: readonly RiskRange[], value: number) {
  let risk = Risk.Unknown;
  let deviation = Deviation.None;

  const range = findRange(ranges, value);

  if (!range) {
    return {
      risk,
      deviation,
    };
  }

  risk = range.risk;

  const normal = ranges.find((range) => range.risk === Risk.Normal);

  if (!normal) {
    return {
      risk,
      deviation,
    };
  }

  const r = range.from + range.to;
  const n = normal.from + normal.to;

  if (r > n) {
    deviation = Deviation.Above;
  } else if (r < n) {
    deviation = Deviation.Below;
  }

  return {
    risk,
    deviation,
  };
}

export type RiskFactors = {
  age: number;
  sex: Sex;
  score2Risk?: Score2RiskStatus;
};

type RiskResolver<M extends MetricName> = (
  unit: Unit<M>,
  factors: RiskFactors
) => {
  risk: Risk;
  deviation: Deviation;
};

type RiskResolvers = {
  [M in MetricName]: RiskResolver<M>;
};

const Resolver: Partial<RiskResolvers> = {
  "bloodwork.cholesterol": (unit, { age }) => {
    return toSummary(Cholesterol.rangesFor({ age }).entries, unit["mmol/L"]);
  },
  "bloodwork.cholesterol_hdl": (unit) => {
    return toSummary(CholesterolHDL.rangesFor().entries, unit.fraction);
  },
  "bloodwork.crp": (unit) => {
    return toSummary(CRP.rangesFor().entries, unit["mg/L"]);
  },
  "bloodwork.hba1c": (unit, { age }) => {
    return toSummary(HbA1c.rangesFor({ age }).entries, unit["mmol/mol"]);
  },
  "bloodwork.hdl": (unit, { sex }) => {
    return toSummary(HDL.rangesFor({ sex }).entries, unit["mmol/L"]);
  },
  "bloodwork.ldl": (unit, { age, score2Risk }) => {
    return toSummary(
      LDL.rangesFor({
        age,
        isScore2HighRisk: score2Risk?.status === Risk.HighRisk,
      }).entries,
      unit["mmol/L"]
    );
  },
  "bloodwork.non_hdl": (unit, { age, sex, score2Risk }) => {
    return toSummary(
      NonHDL.rangesFor({
        sex,
        age,
        isScore2HighRisk: score2Risk?.status === Risk.HighRisk,
      }).entries,
      unit["mmol/L"]
    );
  },
  "bloodwork.triglycerides": (unit) => {
    return toSummary(Triglycerides.rangesFor().entries, unit["mmol/L"]);
  },
  "bloodwork.haemoglobin": (unit, { age, sex }) => {
    return toSummary(Haemoglobin.rangesFor({ sex }).entries, unit["g/L"]);
  },
  "bloodwork.glucose": (unit) => {
    return toSummary(Glucose.rangesFor().entries, unit["mmol/L"]);
  },
  "bloodwork.white_blood_cells.total": (unit) => {
    return toSummary(WhiteBloodCells.rangesFor().entries, unit["x10⁹/L"]);
  },
  "bloodwork.white_blood_cells.neutrophils": (unit) => {
    return toSummary(Neutrophils.rangesFor().entries, unit["x10⁹/L"]);
  },
  "bloodwork.white_blood_cells.basophils": (unit) => {
    return toSummary(Basophils.rangesFor().entries, unit["x10⁹/L"]);
  },
  "bloodwork.white_blood_cells.monocytes": (unit) => {
    return toSummary(Monocytes.rangesFor().entries, unit["x10⁹/L"]);
  },
  "bloodwork.white_blood_cells.eosinophils": (unit) => {
    return toSummary(Eosinophils.rangesFor().entries, unit["x10⁹/L"]);
  },
  "bloodwork.white_blood_cells.lymphocytes": (unit) => {
    return toSummary(Lymphocytes.rangesFor().entries, unit["x10⁹/L"]);
  },
  "body.waist": (unit, { sex }) => {
    return toSummary(Waistline.rangesFor({ sex }).entries, unit.meters * 100);
  },
  "cardio.heart_rate": (unit) => {
    return toSummary(HeartRate.rangesFor().entries, unit.bpm);
  },
  "cardio.brachial_pressure.left": (unit, { age }) => {
    const sys = toSummary(
      Systolic.rangesFor({ age }).entries,
      unit.systolic.mmHg
    );
    const dia = toSummary(
      Diastolic.rangesFor({ age }).entries,
      unit.diastolic.mmHg
    );
    return sys.risk > dia.risk ? sys : dia;
  },
  "cardio.brachial_pressure.right": (unit, { age }) => {
    const sys = toSummary(
      Systolic.rangesFor({ age }).entries,
      unit.systolic.mmHg
    );
    const dia = toSummary(
      Diastolic.rangesFor({ age }).entries,
      unit.diastolic.mmHg
    );
    return sys.risk > dia.risk ? sys : dia;
  },
  "cardio.ankle_pressure.left": (unit) => {
    return toSummary(AnklePressure.rangesFor().entries, unit.mmHg);
  },
  "cardio.ankle_pressure.right": (unit) => {
    return toSummary(AnklePressure.rangesFor().entries, unit.mmHg);
  },
  "cardio.toe_pressure.left": (unit) => {
    return toSummary(ToePressure.rangesFor().entries, unit.mmHg);
  },
  "cardio.toe_pressure.right": (unit) => {
    return toSummary(ToePressure.rangesFor().entries, unit.mmHg);
  },
  "cardio.oxygen_saturation": (unit) => {
    return toSummary(BloodOxygen.rangesFor().entries, unit.percent);
  },
  "risk_assessment.eye_pressure.left": (unit) => {
    return toSummary(EyePressure.rangesFor().entries, unit.mmHg);
  },
  "risk_assessment.eye_pressure.right": (unit) => {
    return toSummary(EyePressure.rangesFor().entries, unit.mmHg);
  },
};

export function resolveSummaries(metrics: Metric[], factors: RiskFactors) {
  return metrics.map((metric) => {
    return resolveSummary(metric, factors);
  });
}

export function resolveSummary(metric: Metric, factors: RiskFactors) {
  const resolve = Resolver[metric.name] as RiskResolver<typeof metric.name>;
  if (!resolve) {
    throw new Error(`No resolver for ${metric.name}`);
  }

  return {
    metricName: metric.name,
    summary: resolve(metric.unit, factors),
  };
}
