import { Side } from "@cur8/api-client";
import { RecordingURI } from "@cur8/uri";
import fallbackPCD from "lib/meshuga/neural_vectra.pcd";
import { createPoints, parsePointCloud } from "lib/meshuga/pointcloud";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

const SCALE: Record<Side, number> = {
  front: 1,
  back: -1,
};

export function useSMPLPointCloud(
  pose: Side,
  recordingURI?: RecordingURI | Error,
  kind?: "neutral" | "male" | "female"
) {
  const { meshuga } = useAPIClient();

  const [result, setResult] = useState<AsyncResult<THREE.Points>>();

  useEffect(() => {
    if (!(recordingURI instanceof Error)) {
      return;
    }

    fetch(fallbackPCD)
      .then((r) => r.arrayBuffer())
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        points.geometry.scale(1, SCALE[pose], 1);
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      setResult(undefined);
    };
  }, [recordingURI, pose]);

  useEffect(() => {
    if (!recordingURI || !kind || recordingURI instanceof Error) {
      return;
    }

    const { deviceId, recordingId } = recordingURI;

    const request = meshuga.fetchSMPLPointCloud({
      deviceId,
      recordingId,
      kind,
      pose,
    });

    request.result
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [meshuga, recordingURI, pose, kind]);

  return result;
}
