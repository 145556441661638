import { useEffect, useState } from "react";

export function useDOMElement<Element extends HTMLElement>(
  ref: React.RefObject<Element>
) {
  const [element, setElement] = useState<Element>();

  useEffect(() => {
    setElement(ref.current ?? undefined);

    return () => {
      setElement(undefined);
    };
  }, []);

  return element;
}
