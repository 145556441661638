import { useContext } from "react";
import { Context } from "./context";

export function useConfig() {
  const config = useContext(Context);
  if (!config) {
    throw new Error("useConfig without ConfigContext");
  }
  return config;
}
