import * as THREE from "three";

const top = [411];

const leftEye = [2800];
const rightEye = [6260];

const leftIndexFinger = [2319];
const leftHand = [
  1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990, 1991, 1992, 1993,
  1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006,
  2007, 2008, 2009, 2010, 2011, 2012, 2013, 2016, 2017, 2018, 2019, 2020, 2021,
  2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034,
  2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2047, 2048,
  2049, 2050, 2051, 2052, 2054, 2059, 2060, 2061, 2062, 2063, 2064, 2065, 2066,
  2067, 2068, 2069, 2070, 2071, 2072, 2073, 2074, 2075, 2076, 2077, 2078, 2079,
  2080, 2081, 2082, 2084, 2085, 2086, 2087, 2088, 2089, 2090, 2091, 2092, 2093,
  2094, 2095, 2096, 2097, 2098, 2099, 2101, 2102, 2103, 2105, 2106, 2107, 2108,
  2109, 2110, 2111, 2112, 2113, 2114, 2115, 2116, 2117, 2118, 2119, 2120, 2121,
  2123, 2124, 2125, 2126, 2127, 2128, 2129, 2130, 2133, 2134, 2135, 2136, 2137,
  2138, 2139, 2143, 2144, 2145, 2146, 2147, 2148, 2149, 2150, 2151, 2152, 2153,
  2154, 2155, 2156, 2157, 2158, 2159, 2160, 2161, 2162, 2164, 2165, 2166, 2167,
  2168, 2169, 2170, 2171, 2172, 2173, 2174, 2175, 2176, 2177, 2178, 2179, 2180,
  2181, 2182, 2183, 2184, 2185, 2186, 2187, 2192, 2193, 2195, 2196, 2197, 2198,
  2199, 2200, 2201, 2202, 2203, 2204, 2205, 2206, 2207, 2208, 2209, 2210, 2211,
  2212, 2213, 2214, 2215, 2216, 2217, 2218, 2219, 2220, 2221, 2222, 2223, 2224,
  2225, 2226, 2227, 2228, 2229, 2230, 2231, 2232, 2233, 2234, 2235, 2236, 2237,
  2238, 2239, 2240, 2241, 2245, 2246, 2247, 2248, 2249, 2250, 2251, 2252, 2253,
  2254, 2255, 2256, 2257, 2258, 2259, 2260, 2261, 2262, 2263, 2264, 2265, 2266,
  2267, 2269, 2270, 2272, 2273, 2274, 2275, 2276, 2277, 2279, 2280, 2281, 2282,
  2283, 2284, 2285, 2287, 2288, 2290, 2291, 2292, 2293, 2295, 2296, 2297, 2298,
  2299, 2300, 2301, 2302, 2303, 2305, 2306, 2307, 2308, 2309, 2310, 2311, 2312,
  2313, 2317, 2318, 2319, 2320, 2321, 2322, 2323, 2324, 2325, 2326, 2327, 2328,
  2329, 2330, 2331, 2332, 2333, 2334, 2337, 2341, 2342, 2343, 2345, 2346, 2347,
  2348, 2349, 2352, 2354, 2356, 2358, 2360, 2361, 2362, 2363, 2364, 2365, 2366,
  2367, 2369, 2370, 2371, 2373, 2374, 2375, 2376, 2379, 2380, 2381, 2382, 2383,
  2384, 2386, 2387, 2388, 2389, 2391, 2393, 2394, 2395, 2396, 2398, 2399, 2400,
  2401, 2402, 2406, 2407, 2409, 2410, 2411, 2413, 2416, 2417, 2418, 2419, 2420,
  2421, 2422, 2423, 2424, 2425, 2426, 2429, 2430, 2431, 2434, 2435, 2437, 2438,
  2440, 2441, 2443, 2444, 2445, 2446, 2448, 2451, 2453, 2464, 2465, 2466, 2468,
  2469, 2470, 2471, 2474, 2475, 2476, 2477, 2478, 2479, 2480, 2481, 2482, 2483,
  2484, 2485, 2486, 2487, 2488, 2490, 2491, 2492, 2494, 2495, 2496, 2497, 2498,
  2499, 2500, 2501, 2502, 2504, 2505, 2506, 2507, 2508, 2510, 2511, 2512, 2514,
  2520, 2521, 2522, 2523, 2524, 2526, 2531, 2532, 2535, 2536, 2538, 2540, 2545,
  2546, 2548, 2549, 2551, 2554, 2558, 2559, 2560, 2561, 2564, 2567, 2574, 2581,
  2584, 2585, 2586, 2590, 2591, 2592, 2593, 2594, 2595, 2596, 2599, 2611, 2612,
  2614, 2617, 2618, 2624, 2625, 2626, 2628, 2630, 2631, 2632, 2633, 2634, 2635,
  2637, 2638, 2639, 2642, 2662, 2664, 2666, 2667, 2668, 2669, 2670, 2671, 2681,
  2682, 2683, 2684, 2685, 2688, 2690, 2691, 2695, 2697, 2698, 2699, 2700, 2701,
  2702, 2703, 2704, 2705, 2706, 2707, 2708, 2709, 2710, 2711, 2712, 2713, 2714,
  2715, 2717, 2718, 2719, 2720, 2721, 2722, 2723, 2724, 2725, 2726, 2727, 2728,
  2729, 2730, 2731, 2732, 2733, 2734, 2735, 2736, 2737, 2738, 2739, 2740, 2741,
  2742, 2743, 2745, 2746, 2747, 2748, 2749, 2750, 2751, 2752, 2753, 2754, 2755,
  2756, 2757, 2758, 2759, 2760, 2761, 2762, 2763, 2765, 2766, 2767, 2768, 2773,
  2774, 2775, 2776, 2777, 2778,
];
const leftShoulder = [
  604, 605, 634, 635, 636, 637, 707, 708, 774, 775, 1238, 1239, 1240, 1241,
  1242, 1243, 1244, 1245, 1289, 1290, 1291, 1292, 1293, 1294, 1316, 1317, 1318,
  1342, 1343, 1355, 1356, 1357, 1358, 1400, 1415, 1438, 1439, 1440, 1441, 1442,
  1443, 1445, 1508, 1509, 1510, 1821, 1822, 1823, 1824, 1825, 1826, 1827, 1828,
  1829, 1830, 1831, 1832, 1833, 1842, 1843, 1844, 1849, 1850, 1851, 1852, 1853,
  1854, 1855, 1856, 1857, 1860, 1861, 1862, 1863, 1864, 1871, 1872, 1873, 1874,
  1875, 1881, 1882, 1883, 1884, 1885, 1886, 1887, 1888, 1889, 1890, 1891, 1893,
  1894, 1895, 1896, 1897, 1898, 1899, 2889, 2890, 2891, 2892, 2893, 2894, 2946,
  2947, 2948, 2985, 2986, 2987, 2988, 2989, 2990, 2991, 2992, 2993, 2994, 2995,
  2996, 3000, 3001, 3002, 3003, 3004, 3005, 3008, 3009, 3010, 3011,
];

const leftUpperArm = [1505];

const leftToe = [3238, 3234, 3233, 3235, 3295, 3293, 3215, 3218, 3216, 3217];

const leftAnkle = [
  3198, 3199, 3200, 3201, 3202, 3203, 3204, 3205, 3207, 3208, 3209, 3210, 3324,
  3325, 3326, 3327, 3328, 3329, 3330, 3331, 3332, 3333, 3334, 3335, 3364, 3365,
  3366, 3370, 3379, 3380, 3381, 3390, 3391, 3392, 3393, 3395, 3399, 3400, 3401,
  3415, 3416, 3417, 3418, 3432, 3433, 3434, 3435, 3436, 3469,
];
const rightAnkle = [
  6598, 6599, 6600, 6601, 6602, 6603, 6604, 6606, 6607, 6608, 6609, 6610, 6724,
  6725, 6726, 6727, 6728, 6729, 6730, 6731, 6732, 6733, 6734, 6735, 6764, 6765,
  6766, 6770, 6779, 6780, 6781, 6790, 6791, 6792, 6793, 6794, 6799, 6800, 6801,
  6815, 6816, 6817, 6818, 6832, 6833, 6834, 6835, 6836, 6869,
];

const rightHand = [
  5442, 5443, 5444, 5445, 5446, 5447, 5448, 5449, 5450, 5451, 5452, 5453, 5454,
  5455, 5456, 5457, 5458, 5459, 5460, 5461, 5462, 5463, 5464, 5465, 5466, 5467,
  5468, 5469, 5470, 5471, 5472, 5473, 5474, 5476, 5478, 5479, 5480, 5481, 5482,
  5483, 5484, 5485, 5486, 5487, 5488, 5489, 5490, 5491, 5492, 5493, 5494, 5495,
  5496, 5497, 5498, 5499, 5500, 5501, 5502, 5503, 5505, 5506, 5507, 5508, 5509,
  5512, 5514, 5515, 5518, 5520, 5521, 5522, 5523, 5524, 5525, 5526, 5527, 5528,
  5529, 5530, 5531, 5532, 5533, 5534, 5536, 5537, 5538, 5539, 5540, 5541, 5542,
  5543, 5546, 5547, 5548, 5549, 5550, 5551, 5552, 5553, 5554, 5555, 5556, 5557,
  5558, 5559, 5560, 5561, 5569, 5571, 5574, 5575, 5576, 5577, 5578, 5579, 5580,
  5581, 5582, 5583, 5584, 5585, 5586, 5587, 5588, 5589, 5590, 5591, 5593, 5594,
  5595, 5596, 5598, 5599, 5600, 5601, 5602, 5603, 5604, 5605, 5606, 5607, 5610,
  5611, 5612, 5613, 5614, 5615, 5616, 5617, 5618, 5619, 5620, 5621, 5622, 5623,
  5624, 5625, 5626, 5627, 5628, 5629, 5630, 5631, 5632, 5633, 5634, 5635, 5636,
  5637, 5638, 5639, 5640, 5641, 5642, 5643, 5644, 5645, 5646, 5647, 5648, 5650,
  5651, 5652, 5653, 5654, 5655, 5656, 5657, 5658, 5659, 5660, 5661, 5662, 5663,
  5664, 5665, 5666, 5667, 5670, 5671, 5672, 5673, 5674, 5675, 5676, 5677, 5678,
  5679, 5680, 5681, 5682, 5683, 5684, 5685, 5686, 5687, 5688, 5689, 5690, 5692,
  5693, 5694, 5695, 5697, 5698, 5699, 5700, 5701, 5706, 5707, 5708, 5709, 5710,
  5711, 5712, 5713, 5714, 5715, 5716, 5717, 5718, 5719, 5720, 5721, 5722, 5723,
  5724, 5725, 5726, 5727, 5728, 5729, 5731, 5733, 5734, 5735, 5736, 5737, 5738,
  5739, 5740, 5741, 5742, 5743, 5744, 5745, 5746, 5748, 5749, 5751, 5752, 5753,
  5755, 5756, 5757, 5758, 5759, 5760, 5761, 5762, 5763, 5764, 5766, 5767, 5768,
  5769, 5770, 5771, 5772, 5773, 5776, 5778, 5779, 5780, 5781, 5782, 5783, 5784,
  5785, 5786, 5787, 5788, 5789, 5790, 5791, 5792, 5793, 5794, 5795, 5798, 5802,
  5803, 5804, 5805, 5806, 5807, 5809, 5812, 5815, 5818, 5819, 5822, 5823, 5824,
  5825, 5826, 5827, 5828, 5829, 5830, 5831, 5832, 5833, 5834, 5835, 5836, 5839,
  5840, 5842, 5843, 5846, 5847, 5848, 5849, 5850, 5851, 5852, 5853, 5854, 5855,
  5856, 5858, 5860, 5861, 5862, 5866, 5867, 5868, 5869, 5870, 5872, 5874, 5877,
  5878, 5879, 5880, 5881, 5882, 5883, 5884, 5885, 5887, 5888, 5890, 5891, 5892,
  5893, 5895, 5896, 5898, 5899, 5901, 5903, 5904, 5905, 5906, 5907, 5909, 5911,
  5913, 5914, 5915, 5926, 5927, 5932, 5938, 5939, 5940, 5941, 5942, 5943, 5944,
  5946, 5947, 5948, 5949, 5950, 5951, 5952, 5953, 5954, 5957, 5958, 5959, 5960,
  5961, 5962, 5965, 5966, 5967, 5968, 5969, 5970, 5971, 5972, 5975, 5980, 5981,
  5984, 5988, 5993, 5995, 5997, 5999, 6004, 6007, 6009, 6010, 6011, 6015, 6019,
  6020, 6022, 6025, 6035, 6040, 6042, 6043, 6044, 6048, 6049, 6051, 6052, 6053,
  6054, 6055, 6057, 6070, 6071, 6072, 6073, 6074, 6075, 6079, 6083, 6085, 6086,
  6087, 6088, 6089, 6090, 6092, 6093, 6097, 6098, 6099, 6100, 6103, 6122, 6123,
  6124, 6125, 6126, 6127, 6128, 6129, 6130, 6131, 6132, 6138, 6142, 6143, 6144,
  6145, 6146, 6148, 6149, 6150, 6151, 6152, 6155, 6157, 6158, 6159, 6160, 6161,
  6162, 6163, 6164, 6165, 6166, 6167, 6168, 6169, 6170, 6171, 6172, 6173, 6174,
  6175, 6176, 6177, 6178, 6179, 6180, 6181, 6182, 6183, 6184, 6185, 6186, 6187,
  6188, 6189, 6190, 6191, 6192, 6193, 6194, 6195, 6196, 6197, 6198, 6199, 6201,
  6202, 6203, 6204, 6205, 6206, 6207, 6208, 6209, 6210, 6211, 6212, 6213, 6214,
  6215, 6216, 6217, 6219, 6220, 6221, 6222, 6223, 6224, 6226, 6227, 6228, 6229,
  6230, 6231, 6232, 6233, 6234, 6236, 6237, 6239,
];

const rightShoulder = [
  4094, 4122, 4123, 4124, 4125, 4198, 4199, 4269, 4270, 4271, 4721, 4722, 4723,
  4724, 4726, 4727, 4796, 4797, 4798, 5006, 5272, 5274, 5282, 5283, 5284, 5285,
  5290, 5291, 5292, 5293, 5310, 5311, 5312, 5313, 5314, 5322, 5323, 5324, 5325,
  5333, 5334, 5336, 5342, 5343, 5344, 5354, 6339, 6347, 6348, 6349, 6350, 6351,
  6352, 6353, 6468, 6469, 6470,
];

const rightCollarbone = [
  1305, 2877, 2878, 3471, 4076, 4077, 4078, 4079, 4185, 4186, 4187, 4188, 4189,
  4218, 4219, 4220, 4698, 4699, 4700, 4701, 4780, 4781, 4782, 4900, 5222, 5275,
  5276, 6273, 6333, 6336, 6337, 6340, 6341, 6343, 6344, 6345, 6346, 6496, 6502,
];

const rightUpperArm = [4917];

const rightToe = [6615, 6618, 6616, 6617, 6633, 6636, 6632, 6695];

const heart = [3063, 752];

const forehead = [0];

const neck = [
  152, 150, 150, 151, 285, 151, 285, 217, 285, 214, 284, 214, 284, 451, 284,
  334, 333, 334, 3049, 334, 3049, 3050, 3049, 3839, 3840, 3839, 3840, 3796,
  3818, 3796, 3818, 3797, 3817, 3797, 3662, 3797, 3662, 3665, 3662, 3664, 3663,
  3664, 3663, 3809, 3810, 3809, 3810, 3721, 3718, 3721, 3718, 3720, 3719, 3720,
  3723, 3720, 3723, 3722, 3724, 3722, 3724, 3725, 3724, 3769, 3768, 3769, 3768,
  3919, 3918, 3919, 3918, 3164, 460, 3164, 423, 3164, 423, 424, 257, 424, 257,
  256, 212, 256, 211, 256, 211, 426, 211, 425, 211, 222, 210, 222, 210, 219,
  208, 219, 208, 218, 207, 218, 207, 452, 297, 452, 297, 441, 297, 152, 153,
  152, 150, 152,
];

const thorax = [
  751, 749, 749, 750, 1235, 750, 1235, 1236, 1235, 1252, 1834, 1252, 1835, 1252,
  1437, 1252, 1437, 1253, 1437, 1436, 1434, 1436, 1435, 1436, 1435, 2906, 1435,
  2907, 2958, 2907, 2958, 2957, 760, 2957, 760, 759, 758, 759, 758, 739, 1287,
  739, 1283, 739, 1283, 740, 1286, 740, 1286, 1418, 1536, 1418, 1759, 1418,
  1759, 1419, 1759, 1760, 1347, 1760, 2866, 1760, 2866, 2952, 2866, 2865, 2866,
  2864, 1417, 2864, 2863, 2864, 2863, 2868, 2861, 2868, 2869, 2868, 2869, 611,
  3038, 611, 3039, 611, 3039, 612, 3039, 3043, 3034, 3043, 3034, 3044, 3037,
  3044, 3037, 3041, 3030, 3041, 3030, 3040, 3033, 3040, 3033, 3042, 1351, 3042,
  1351, 943, 1349, 943, 1349, 687, 1349, 1255, 1256, 1255, 1257, 1255, 1257,
  1254, 3063, 1254, 3063, 2870, 3506, 2870, 3506, 3076, 3506, 6332, 6498, 6332,
  6498, 4737, 4738, 4737, 4738, 4740, 4739, 4740, 4739, 4825, 4826, 4825, 4828,
  4825, 4828, 4428, 4828, 6489, 4828, 6478, 4827, 6478, 6479, 6478, 6479, 6477,
  6480, 6477, 6480, 6482, 6483, 6482, 6483, 6481, 6484, 6481, 6484, 6485, 6486,
  6485, 6486, 4101, 6486, 6330, 6323, 6330, 6322, 6330, 6322, 6329, 6322, 6324,
  4192, 6324, 4192, 4890, 4822, 4890, 4822, 4823, 4160, 4823, 4160, 4684, 4163,
  4684, 4685, 4684, 4685, 5007, 4685, 5008, 5013, 5008, 5228, 5008, 5228, 4767,
  4170, 4767, 4170, 4768, 4169, 4768, 4169, 4769, 4755, 4769, 4755, 4242, 4754,
  4242, 4243, 4242, 4243, 4244, 6363, 4244, 6364, 4244, 6364, 4245, 6364, 6417,
  6419, 6417, 6420, 6417, 6420, 6418, 6420, 4910, 6421, 4910, 4907, 4910, 4907,
  4909, 4908, 4909, 4908, 4736, 4908, 4735, 5296, 4735, 5295, 4735, 4718, 4735,
  4718, 4719, 4718, 4240, 4237, 4240, 4237, 4239, 4238, 4239, 4238, 3014, 3015,
  3014, 752, 3014, 752, 751, 749, 751,
];

const waist = [
  665, 678, 678, 662, 678, 705, 1449, 705, 939, 705, 939, 938, 939, 846, 2919,
  846, 2915, 846, 2915, 830, 2916, 830, 2916, 831, 2917, 831, 2917, 844, 2918,
  844, 2918, 845, 2911, 845, 2911, 886, 2910, 886, 2910, 2928, 3122, 2928, 3122,
  2929, 1780, 2929, 1780, 1779, 1781, 1779, 1781, 1782, 1784, 1782, 1784, 3021,
  1784, 5245, 5246, 5245, 5246, 5244, 5247, 5244, 5247, 6388, 6544, 6388, 6544,
  6389, 6371, 6389, 6371, 4373, 6370, 4373, 6370, 4330, 6376, 4330, 6376, 4331,
  6377, 4331, 6377, 4316, 6374, 4316, 6374, 4317, 6375, 4317, 6375, 4332, 6378,
  4332, 4425, 4332, 4425, 4424, 4425, 4193, 4921, 4193, 4166, 4193, 4166, 4150,
  4166, 4151, 4167, 4151, 4167, 4406, 4341, 4406, 4341, 4405, 4404, 4405, 4404,
  4403, 4345, 4403, 4345, 4402, 4344, 4402, 4813, 4402, 4813, 4812, 4813, 3500,
  1768, 3500, 1337, 3500, 1337, 1336, 1337, 917, 858, 917, 861, 917, 861, 916,
  920, 916, 920, 919, 855, 919, 855, 918, 679, 918, 679, 665, 678, 665,
];

const hip = [
  866, 932, 932, 823, 1454, 823, 1454, 809, 1446, 809, 1446, 810, 1447, 810,
  1447, 3085, 3084, 3085, 3084, 3091, 3136, 3091, 3136, 3115, 3137, 3115, 3138,
  3115, 3138, 3108, 3116, 3108, 3116, 3109, 3116, 1246, 3117, 1246, 3117, 1247,
  3118, 1247, 3118, 3120, 3119, 3120, 6541, 3120, 6541, 4729, 6539, 4729, 6539,
  4730, 6540, 4730, 6540, 6530, 6540, 6531, 6559, 6531, 6559, 6538, 6558, 6538,
  6557, 6538, 6557, 6515, 6509, 6515, 6509, 6508, 4919, 6508, 4919, 4296, 4920,
  4296, 4920, 4297, 4927, 4297, 4927, 4310, 4418, 4310, 4418, 4352, 4418, 4353,
  4984, 4353, 4984, 4983, 4985, 4983, 4399, 4983, 4399, 4923, 4690, 4923, 4690,
  5266, 4690, 5265, 4692, 5265, 4692, 5268, 4351, 5268, 4351, 5267, 4350, 5267,
  4350, 1806, 1807, 1806, 864, 1806, 864, 1805, 863, 1805, 863, 1804, 1205,
  1804, 1205, 1803, 1205, 1204, 912, 1204, 915, 1204, 915, 1450, 915, 1511,
  1512, 1511, 1513, 1511, 1513, 869, 932, 869, 932, 866,
];

const calf = [
  1087, 1088, 1088, 1086, 1088, 1089, 1528, 1089, 1528, 1530, 1092, 1530, 1092,
  1111, 1091, 1111, 1091, 1108, 1464, 1108, 1464, 1466, 1464, 1465, 1467, 1465,
  1467, 1473, 1469, 1473, 1469, 1095, 1096, 1095, 1096, 1094, 1097, 1094, 1097,
  1470, 1097, 1100, 1082, 1100, 1101, 1100, 1101, 1099, 1098, 1099, 1098, 1103,
  1102, 1103, 1102, 1371, 1177, 1371, 1177, 1155, 1151, 1155, 1153, 1155, 1153,
  1087, 1525, 1087, 1088, 1087,
];

const rightElbow = [
  5033, 5034, 5084, 5085, 5086, 5092, 5093, 5098, 5099, 5164, 5169, 5170, 5193,
  5212, 5213, 5221,
];

const leftElbow = [
  1564, 1567, 1614, 1615, 1617, 1623, 1626, 1628, 1629, 1695, 1701, 1702, 1724,
  1743, 1744, 1752,
];

const rightKnee = [
  4481, 4482, 4485, 4486, 4491, 4492, 4493, 4495, 4496, 4497, 4498, 4500, 4501,
  4503, 4504, 4505, 4506, 4529, 4530, 4531, 4532, 4535, 4536, 4537, 4538, 4539,
  4543, 4544, 4548, 4549, 4554, 4633, 4634, 4635, 4993, 4994,
];

const rightHip = [
  4318, 4322, 4323, 4325, 4348, 4349, 4350, 4368, 4369, 4370, 4371, 4398, 4399,
  4400, 4401, 4691, 4712, 4745, 4758, 4759, 4801, 4802, 4814, 4815, 4919, 4920,
  4927, 4950, 4951, 4952, 4969, 4984, 4985, 6504, 6505, 6506, 6507, 6509, 6511,
  6512, 6513, 6516, 6517, 6518, 6519, 6520, 6526, 6527, 6528, 6539, 6540, 6541,
  6549, 6550, 6551, 6554, 6557, 6558, 6559, 6560, 6561, 6562, 6563, 6564, 6565,
  6873, 6877,
];

const rightFoot = [
  6611, 6612, 6613, 6614, 6615, 6616, 6617, 6618, 6619, 6621, 6622, 6623, 6624,
  6626, 6627, 6629, 6630, 6631, 6632, 6633, 6634, 6635, 6636, 6637, 6638, 6639,
  6640, 6641, 6643, 6644, 6645, 6646, 6647, 6648, 6649, 6650, 6651, 6652, 6653,
  6654, 6655, 6656, 6657, 6658, 6660, 6661, 6662, 6664, 6666, 6668, 6669, 6670,
  6671, 6672, 6673, 6674, 6675, 6676, 6677, 6678, 6679, 6680, 6681, 6682, 6683,
  6684, 6685, 6686, 6687, 6688, 6689, 6690, 6691, 6692, 6693, 6695, 6696, 6697,
  6698, 6699, 6700, 6701, 6702, 6703, 6704, 6706, 6707, 6708, 6710, 6711, 6712,
  6713, 6714, 6715, 6716, 6717, 6718, 6736, 6737, 6738, 6739, 6740, 6741, 6742,
  6743, 6744, 6745, 6746, 6747, 6749, 6750, 6751, 6752, 6753, 6754, 6755, 6757,
  6758, 6759, 6760, 6761, 6762, 6763, 6764, 6765, 6797,
];

const leftKnee = [
  1012, 1018, 1019, 1043, 1044, 1047, 1048, 1049, 1050, 1051, 1052, 1053, 1054,
  1055, 1058, 1059, 1061, 1062, 1064, 1065, 1066, 1069, 1148, 1149, 1369, 1523,
];

const leftFoot = [
  3318, 3327, 3328, 3329, 3330, 3331, 3332, 3333, 3334, 3335, 3336, 3337, 3338,
  3339, 3340, 3341, 3342, 3343, 3344, 3345, 3346, 3347, 3349, 3364, 3365, 3366,
  3367, 3368, 3369, 3370, 3371, 3372, 3373, 3374, 3375, 3376, 3377, 3378, 3379,
  3380, 3381, 3382, 3383, 3384, 3385, 3386, 3387, 3388, 3389, 3390, 3391, 3392,
  3393, 3394, 3395, 3397, 3398, 3399, 3400, 3401, 3402, 3403, 3404, 3405, 3409,
  3410, 3411, 3412, 3413, 3414, 3415, 3416, 3417, 3418, 3432, 3433, 3434, 3435,
  3436, 3469,
];

const leftCollarbone = [
  584, 588, 590, 591, 699, 700, 701, 733, 1243, 1244, 1297, 1298, 1299, 1316,
  1317, 1535, 1808, 1812, 1814, 1815, 1819, 1820, 1894, 1899, 2881, 2882, 2885,
  2886, 2887,
];

const leftHip = [
  832, 835, 837, 883, 884, 913, 914, 1228, 1263, 1275, 1276, 1321, 1322, 1477,
  1479, 1480, 3080, 3081, 3082, 3083, 3087, 3088, 3089, 3092, 3094, 3095, 3096,
  3103, 3104, 3105, 3128, 3129, 3130, 3133, 3139, 3475, 3479,
];

const POINT_SOURCES = {
  calf,
  forehead,
  heart,
  hip,
  leftAnkle,
  leftEye,
  leftHip,
  leftHand,
  leftElbow,
  leftCollarbone,
  leftIndexFinger,
  leftShoulder,
  leftToe,
  leftKnee,
  leftFoot,
  leftUpperArm,
  neck,
  rightAnkle,
  rightCollarbone,
  rightEye,
  rightHand,
  rightHip,
  rightElbow,
  rightKnee,
  rightShoulder,
  rightFoot,
  rightToe,
  rightUpperArm,
  thorax,
  top,
  waist,
};

export class BufferVector {
  buffer: THREE.BufferAttribute;

  constructor(buffer: THREE.BufferAttribute) {
    this.buffer = buffer;
  }

  getVec3(index: number) {
    return new THREE.Vector3(
      this.buffer.getX(index),
      this.buffer.getY(index),
      this.buffer.getZ(index)
    );
  }

  getBox3(indices: number[]) {
    const box = new THREE.Box3(
      new THREE.Vector3(Infinity, Infinity, Infinity),
      new THREE.Vector3(-Infinity, -Infinity, -Infinity)
    );

    const { min, max } = box;

    for (const index of indices) {
      const pos = this.getVec3(index);

      if (pos.x < box.min.x) {
        min.x = pos.x;
      }
      if (pos.x > max.x) {
        max.x = pos.x;
      }

      if (pos.y < min.y) {
        min.y = pos.y;
      }
      if (pos.y > max.y) {
        max.y = pos.y;
      }

      if (pos.z < min.z) {
        min.z = pos.z;
      }
      if (pos.z > max.z) {
        max.z = pos.z;
      }
    }

    return box;
  }
}

export type BodyArea = keyof typeof POINT_SOURCES;

export type BodyAreas = Record<BodyArea, THREE.Box3>;

export function findSMPLAreas(points: THREE.Points): BodyAreas {
  const lookup = new BufferVector(
    points.geometry.attributes.position as THREE.BufferAttribute
  );

  function createBounds(indices: number[]) {
    return lookup.getBox3(indices);
  }

  const areaEntries = Object.entries(POINT_SOURCES).map(([key, index]) => {
    return [key, createBounds(index)] as [BodyArea, THREE.Box3];
  });

  return Object.fromEntries(areaEntries) as BodyAreas;
}

export function createFakeSMPLPositions(): BodyAreas {
  function createBox(
    x: number,
    y: number,
    z: number,
    width: number = 0,
    height: number = 0,
    depth: number = 0
  ) {
    const w = width / 2;
    const h = height / 2;
    const d = depth / 2;

    const box = new THREE.Box3();
    box.min.set(x - w, y - d, z - h);
    box.max.set(x + w, y + d, z + h);
    return box;
  }

  return {
    top: createBox(0, 0, 1800),
    forehead: createBox(0, 0, 1750),
    leftEye: createBox(50, 0, 1750),
    rightEye: createBox(-50, 0, 1750),
    calf: createBox(200, 0, 350, 150),
    hip: createBox(0, 0, 950, 400),
    leftAnkle: createBox(200, 0, 100),
    leftHand: createBox(400, -160, 800),
    rightHand: createBox(-400, -160, 800),
    leftCollarbone: createBox(220, 0, 1500),
    leftIndexFinger: createBox(400, -160, 800),
    leftShoulder: createBox(320, 0, 1500),
    leftElbow: createBox(320, 0, 1200),
    leftHip: createBox(200, 0, 1000),
    leftFoot: createBox(200, 0, 0),
    leftKnee: createBox(200, 0, 600),
    leftToe: createBox(200, 0, 0),
    leftUpperArm: createBox(280, 0, 1300),
    heart: createBox(0, 0, 1400),
    neck: createBox(0, 0, 1600),
    rightAnkle: createBox(-200, 0, 100),
    rightCollarbone: createBox(-220, 0, 1500),
    rightShoulder: createBox(-320, 0, 1500),
    rightElbow: createBox(-320, 0, 1200),
    rightHip: createBox(-200, 0, 1000),
    rightKnee: createBox(-200, 0, 600),
    rightFoot: createBox(-200, 0, 0),
    rightToe: createBox(-200, 0, 0),
    rightUpperArm: createBox(-280, 0, 1300),
    thorax: createBox(0, 0, 1500, 400),
    waist: createBox(0, 0, 1100, 300),
  };
}
