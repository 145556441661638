import { useNav } from "@pomle/react-router-paths";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import { paths } from "render/routes/paths";
import CloseIcon from "./assets/close.svg?react";
import BackButton from "./components/BackButton";
import styles from "./styles.module.sass";

interface BackControlProps {
  patientId: string;
}

export default function BackControl({ patientId }: BackControlProps) {
  const { ui } = useDashboardContext();

  const layer = ui.layer;

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  return (
    <div className={styles.BackControl}>
      <div className={styles.item} data-active={layer === Layer.Identity}>
        <BackButton onClick={nav.patient.on({ patientId })}>Back</BackButton>
      </div>

      <div
        className={styles.item}
        data-active={
          layer === Layer.Body ||
          layer === Layer.Cardio ||
          (layer === Layer.Skin && !ui.camera)
        }
      >
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.Identity });
          }}
        >
          Identity
        </BackButton>
      </div>

      <div
        className={styles.item}
        data-active={[Layer.Diabetes, Layer.Diabetes, Layer.Bloodwork].includes(
          layer
        )}
      >
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.Body });
          }}
        >
          Body
        </BackButton>
      </div>

      <div
        className={styles.item}
        data-active={
          layer === Layer.Circulation ||
          layer === Layer.Cholesterol ||
          layer === Layer.Heart ||
          layer === Layer.PulseWaves
        }
      >
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.Cardio });
          }}
        >
          Cardio
        </BackButton>
      </div>

      <div className={styles.item} data-active={false}>
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.Circulation });
          }}
        >
          Circulation
        </BackButton>
      </div>

      <div className={styles.item} data-active={layer === Layer.LesionLibrary}>
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.Skin });
          }}
        >
          Skin
        </BackButton>
      </div>

      <div
        className={styles.item}
        data-active={layer === Layer.LesionMacro && !ui.dermaId}
      >
        <BackButton
          onClick={() => {
            ui.set({ layer: Layer.LesionLibrary });
          }}
        >
          Library
        </BackButton>
      </div>

      <div
        className={styles.item}
        data-active={layer === Layer.Skin && !!ui.camera}
      >
        <button
          onClick={() => {
            ui.set({ camera: undefined });
          }}
        >
          <CloseIcon />
        </button>
      </div>

      <div
        className={styles.item}
        data-active={layer === Layer.LesionMacro && !!ui.dermaId}
      >
        <button
          onClick={() => {
            ui.set({ dermaId: undefined });
          }}
        >
          <CloseIcon />
        </button>
      </div>
    </div>
  );
}
