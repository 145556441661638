import { useCallback, useState } from "react";
import { Context } from "./context";

function useKeyboardContextState() {
  const [blockers, setBlockers] = useState(0);

  const block = useCallback(() => {
    setBlockers((blockers) => blockers + 1);
  }, []);

  const unblock = useCallback(() => {
    setBlockers((blockers) => blockers - 1);
  }, []);

  return {
    blocked: blockers > 0,
    block,
    unblock,
  };
}

interface KeyboardContextProps {
  children: React.ReactNode;
}

export default function KeyboardContext({ children }: KeyboardContextProps) {
  const state = useKeyboardContextState();

  return <Context.Provider value={state}>{children}</Context.Provider>;
}
