import styles from "./styles.module.sass";

interface SectionHeaderProps {
  header: React.ReactNode;
  toggle?: React.ReactNode;
  legends?: React.ReactNode;
}

export default function SectionHeader(props: SectionHeaderProps) {
  return (
    <div className={styles.Header}>
      <div className={styles.container}>
        <div>{props.header}</div>
        <div className={styles.legend}>{props.legends}</div>
      </div>

      <div className={styles.toggleContainer}>{props.toggle}</div>
    </div>
  );
}
