import { DateTime } from "luxon";
import { createContext } from "react";

type NotificationType = "success" | "error" | "info";

type NotificationEntry = {
  id: string;
  timestamp: DateTime;
  type: NotificationType;
  content: React.ReactNode;
};

interface ContextType {
  entries: NotificationEntry[];
  emit: (content: React.ReactNode, type: NotificationType) => void;
  clear: () => void;
}

export const Context = createContext<ContextType | null>(null);
