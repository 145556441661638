import { Patient } from "@cur8/rich-entity";
import { FLAGS, Flags } from "lib/flags";
import { useCallback, useState } from "react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useMSAL } from "render/context/MSALContext";
import { useLLMConsent } from "render/fragments/visit/DoctorScribe/hooks/useLLMConsent";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import PatientEncyclopediaPage from "render/pages/PatientEncyclopediaPage/components/PatientEncyclopediaComponent";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import DataTableIcon from "./assets/datatable.svg?react";
import PreBriefDataIcon from "./assets/prebrief.svg?react";
import SummariesIcon from "./assets/summaries.svg?react";
import HideButton from "./components/HideButton";
import JSONView from "./components/JSONView";
import LLMItem from "./components/LLMItem";
import { Questionnaire } from "./components/Questionnaire";
import RightDrawer from "./components/RightDrawer";
import VisitSummaryView from "./components/VisitSummaryView";
import styles from "./styles.module.sass";
import { useBrief } from "./useBrief";

interface LLMBriefProps {
  patient: Patient;
}

export default function LLMBrief({ patient }: LLMBriefProps) {
  const [showBrief, setShowBrief] = useState(false);
  const [showDataView, setShowDataView] = useState(false);
  const [showPreviousSummaries, setShowPreviousSummaries] = useState(false);
  const [showDataTable, setShowDataTable] = useState(false);
  const [showQuestionnaireResponse, setShowQuestionnaireResponse] =
    useState(false);

  const { canUseSuggestions } = useLLMConsent(patient.patientId);
  const { auth } = useMSAL();
  const appInsights = useAppInsights();

  const { data: questionnaire } = useQuestionnaire(patient.patientId);

  const { brief, results, visits, regenerateBrief, isGenerating } =
    useBrief(patient);

  const trackEvent = useCallback(
    (name: string) => {
      if (patient && auth && auth.account) {
        appInsights.trackEvent(
          { name },
          {
            userId: auth.account.homeAccountId,
            patient: patient.patientId,
          }
        );
      }
    },
    [appInsights, auth, patient]
  );

  const closeAllDrawers = useCallback(() => {
    setShowPreviousSummaries(false);
    setShowDataTable(false);
    setShowDataView(false);
    setShowQuestionnaireResponse(false);
  }, []);

  return (
    <div className={styles.LLMBrief}>
      <RightDrawer
        title="Visit Summaries"
        onClose={() => {
          setShowPreviousSummaries(false);
        }}
        active={showPreviousSummaries}
      >
        <VisitSummaryView visits={visits} />
      </RightDrawer>

      <RightDrawer
        title="Data Table"
        active={showDataTable}
        onClose={() => setShowDataTable(false)}
      >
        <PatientEncyclopediaPage patientId={patient.patientId} />
      </RightDrawer>

      <RightDrawer
        title="Pre-brief Data"
        active={showDataView}
        onClose={() => setShowDataView(false)}
      >
        <JSONView data={results} />
      </RightDrawer>

      <RightDrawer
        title="Questionnaire response"
        active={showQuestionnaireResponse}
        onClose={() => setShowQuestionnaireResponse(false)}
      >
        <Questionnaire data={questionnaire?.questionsAndAnswers} />
      </RightDrawer>

      {canUseSuggestions || FLAGS.has(Flags.DoctorBrief) ? (
        <div className={styles.container}>
          <div className={styles.title}>
            <h1>Pre-brief</h1>
            <HideButton
              dohide={!showBrief}
              onClick={() => {
                setShowBrief((p) => !p);
                trackEvent("brief_toggled");
              }}
            />
          </div>

          <div className={styles.header}>
            {showBrief && (
              <div>
                <p>
                  The pre-brief below is AI generated. Always use your own
                  clinical judgment to assess the relevant underlying data and
                  to consider any suggestions independently.
                </p>
                {brief.generatedAt && !isGenerating() && (
                  <div className={styles.generated}>
                    <p className={styles.generatedTime}>
                      Generated {brief.generatedAt.toFormat("yyyy-LL-dd HH:mm")}
                    </p>
                    <div className={styles.noWrap}>
                      <HoverTextButton
                        onClick={() => {
                          regenerateBrief();
                        }}
                      >
                        Re-generate Brief
                      </HoverTextButton>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className={showBrief ? styles.body : styles.hideBody}>
            <LLMItem
              title="Summary of latest scan"
              response={brief.latestSummary}
            />
            {brief.compareSummary === null ? null : (
              <LLMItem
                title="Comparison with earlier scans"
                response={brief.compareSummary}
              />
            )}
            <LLMItem
              title="Suggestions for consideration"
              response={brief.suggestions}
            />
            <LLMItem
              title="Suggested questions to ask"
              response={brief.questions}
            />
          </div>
        </div>
      ) : (
        <div className={styles.missing}>
          Cannot generate briefs due to missing consent.
        </div>
      )}
      <div className={styles.buttons}>
        {visits.length > 0 && (
          <HoverTextButton
            onClick={() => {
              closeAllDrawers();
              if (!showPreviousSummaries) {
                setShowPreviousSummaries(true);
                trackEvent("brief_lookedAtSummaries");
              }
            }}
          >
            <div className={styles.buttonLabel}>
              Visit summaries <SummariesIcon />
            </div>
          </HoverTextButton>
        )}
        <HoverTextButton
          onClick={() => {
            closeAllDrawers();
            if (!showDataTable) {
              setShowDataTable(true);
              trackEvent("brief_lookedAtDataTable");
            }
          }}
        >
          <div className={styles.buttonLabel}>
            Data table
            <DataTableIcon />
          </div>
        </HoverTextButton>
        <HoverTextButton
          onClick={() => {
            closeAllDrawers();
            if (!showDataView) {
              setShowDataView(true);
              trackEvent("brief_lookedAtAllData");
            }
          }}
        >
          <div className={styles.buttonLabel}>
            Pre-brief data <PreBriefDataIcon />
          </div>
        </HoverTextButton>
        <HoverTextButton
          onClick={() => {
            closeAllDrawers();
            if (!showQuestionnaireResponse) {
              setShowQuestionnaireResponse(true);
              trackEvent("brief_lookedAtAnswers");
            }
          }}
        >
          <div className={styles.buttonLabel}>Questionnaire response</div>
        </HoverTextButton>
      </div>
    </div>
  );
}
