import { APITypesV2 } from "@cur8/api-client";
import { fetchLatestOnboardingQuestionnaireResponse } from "lib/questionnaire/api";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

type Questionnaire = APITypesV2.QuestionnaireResponse;

export function useQuestionnaire(patientId: string) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const [result, setResult] = useState<Partial<AsyncResult<Questionnaire>>>({});

  useEffect(() => {
    const request = fetchLatestOnboardingQuestionnaireResponse(api, patientId);

    request.result.then((q) => setResult({ data: q })).catch(handleError);

    return () => {
      request.abandon();
      setResult({});
    };
  }, [api, handleError, patientId]);

  return result;
}
