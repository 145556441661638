import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan, fromAPI } from "@cur8/rich-entity";
import { createQuery } from "@pomle/paths";
import {
  PathLink,
  useHistory,
  useNav,
  useQueryParams,
} from "@pomle/react-router-paths";
import { ValidScanTypes, parseScanType } from "lib/scanType";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import ScanType from "render/fragments/scan/ScanType";
import { codecs } from "render/routes/codec";
import { paths } from "render/routes/paths";
import FullDate from "render/ui/format/FullDate";
import PageContent from "render/ui/layouts/PageContent";
import styles from "./styles.module.sass";

type ImmutableScanWithUrl = { url: string } & ImmutableScan;

const query = createQuery({
  deviceId: codecs.string,
  intent: codecs.string,
  patientId: codecs.string,
  recordingId: codecs.string,
  recordingType: codecs.string,
});

export default function InternalEntryPage() {
  const api = useAPIClient();
  const [params] = useQueryParams(query);
  const history = useHistory();

  const nav = {
    patient: useNav(paths.patient.detail),
    tissue: {
      regular: useNav(paths.patient.scans.tissue.regular),
      mind: useNav(paths.patient.scans.tissue.mind),
      pad: useNav(paths.patient.scans.tissue.pad),
    },
    atlas: useNav(paths.patient.recording.atlas),
  };

  const [matches, setMatches] = useState<ImmutableScanWithUrl[]>([]);

  const getTissueNav = useCallback(
    (scan: ImmutableScan) => {
      const scanType = parseScanType(scan.scanType);
      let tissueNav = nav.tissue.regular;
      console.debug("scanType", scanType, scan);
      if (scanType && scanType.type === ValidScanTypes.Tissue) {
        if (scanType.subtype === "mind") {
          tissueNav = nav.tissue.mind;
        } else if (scanType.subtype === "pad") {
          tissueNav = nav.tissue.pad;
        }
      }
      return tissueNav;
    },
    [nav.tissue.mind, nav.tissue.pad, nav.tissue.regular]
  );

  const fetchTissueScans = useCallback(() => {
    api.scan
      .queryImmutableScansForPatient({
        patientId: params.patientId[0],
        state: APITypesV1.ScanState.Finished,
        sourceUri: [
          "cur8",
          "recording",
          params.deviceId[0],
          params.recordingId[0],
        ].join(":"),
        pageSize: 10,
        order: APITypesV1.SortOrder.Desc,
      })
      .result.then((res) => {
        const scans = res.items.map(fromAPI.toImmutableScan);
        if (scans.length === 1) {
          const scan = scans[0];
          const tissueNav = getTissueNav(scan);
          const url = tissueNav.to({
            patientId: params.patientId[0],
            scanId: scan.id,
            scanVersion: scan.version,
          });
          history.push(url);
        } else {
          const scansUrl = [] as ImmutableScanWithUrl[];
          scans.forEach((s) => {
            const tissueNav = getTissueNav(s);
            scansUrl.push({
              url: tissueNav.to({
                patientId: params.patientId[0],
                scanId: s.id,
                scanVersion: s.version,
              }),
              ...s,
            });
          });
          setMatches(scansUrl);
        }
      });
  }, [
    api.scan,
    getTissueNav,
    history,
    params.deviceId,
    params.patientId,
    params.recordingId,
  ]);

  const recType = params.recordingType[0];
  useEffect(() => {
    if (recType.startsWith("tci/")) {
      fetchTissueScans();
    } else if (recType.startsWith("skin/")) {
      const url = nav.atlas.to({
        patientId: params.patientId[0],
        recordingId: params.recordingId[0],
        deviceId: params.deviceId[0],
      });
      history.push(url);
    } else {
      // Default URL to patient detail page
      history.push(nav.patient.to({ patientId: params.patientId[0] }));
    }
  }, [
    fetchTissueScans,
    history,
    nav.atlas,
    nav.patient,
    params.deviceId,
    params.patientId,
    params.recordingId,
    recType,
  ]);

  return (
    <PageContent>
      <div className={styles.InternalEntryPage}>
        <h1>Matching scans</h1>
        <p>
          No direct match for patient: <b>{params.patientId[0]}</b>
          <br />
          and recording: <b>{params.recordingId[0]}</b>.
        </p>
        {matches && (
          <div className={styles.matches}>
            <b>Select from matching scans below:</b>
            {matches.map((scan, idx) => {
              return (
                <PathLink to={scan.url} key={idx}>
                  <ScanType scanType={scan.scanType} />
                  <FullDate date={scan.versionCreatedAt} />
                  <span title={`Version: ${scan.version}`}>{scan.id}</span>
                </PathLink>
              );
            })}
          </div>
        )}
      </div>
    </PageContent>
  );
}
