import { Risk, ToePressure } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { RISK_COLORS } from "render/risk";
import FloatingValueTarget from "render/ui/presentation/FloatingValueTarget";
import ValueItem from "render/ui/symbol/ValueItem";

interface LeftToePressureProps {
  toePressure: Metric<"cardio.toe_pressure.left">[];
}

export default function LeftToePressure({ toePressure }: LeftToePressureProps) {
  const pressure = toePressure.at(0);

  const risk = useMemo(() => {
    if (pressure) {
      return ToePressure.rangesFor().findRisk(pressure.unit);
    }
  }, [pressure]);

  const color = RISK_COLORS[risk ?? Risk.Unknown];

  return (
    <FloatingValueTarget
      label="Toe Pressure"
      value={
        <ValueItem value={pressure ? pressure.unit.mmHg : "--"} unit="mmHg" />
      }
      color={color}
      align="right"
    />
  );
}
