import { APITypesV1 } from "@cur8/api-client";
import { PatientBlobURI } from "@cur8/uri";
import { useAPIClient } from "render/context/APIContext";
import { useMSAL } from "render/context/MSALContext";
import { ResultType } from "../../../pages/PatientAppointmentPage/components/LLMBrief/results/results";

type Prompts = {
  name: string;
  instruction: string;
  prompt: string;
  temp: number;
};

export type ProblemReport = {
  prompts: Prompts;
  transcription: string | undefined;
  results: ResultType | string | undefined;
  content: string;
  feedback: string;
};

/**
 * Save problem report
 *
 */

export function useReportProblem() {
  const api = useAPIClient();
  const { auth } = useMSAL();

  async function saveProblemReport(patientId: string, report: ProblemReport) {
    const reporter = auth?.account?.name;
    const user = auth?.account?.username;
    const recordingTimestamp = new Date().getTime().toString();
    const fileName = `speech/${recordingTimestamp}_problem.json`;

    try {
      await api.blob.putPatientBlob({
        patientId: patientId,
        file: new Blob([JSON.stringify({ ...report, reporter, user })], {
          type: "application/json;charset=utf-8",
        }),
        path: fileName,
      }).result;
    } catch (error) {
      console.error("Could not upload pair: ", error);
      return;
    }
    const targetUri = new PatientBlobURI(patientId, fileName);

    // create an annotation so we can find the file
    api.annotation.createAnnotation(
      { patientId: patientId },
      {
        acceptState: APITypesV1.AcceptState.Proposed,
        targetUri: targetUri.toString(),
        applicationSpecificTarget: "speech:problem",
      }
    );
  }

  return { saveProblemReport };
}
