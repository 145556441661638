import { MemberSummary } from "lib/doctor-scribe/types";
import {
  MSToTSPair,
  daysOld,
  triggerSummaryGeneration,
} from "lib/doctor-scribe/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import LineThrobber from "render/ui/throbber/LineThrobber";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { useReportProblem } from "../ReportLLMProblem/useReportProblem";
import { useReportProblemDialog } from "../ReportLLMProblem/useReportProblemDialog";
import MemberSummarySelect from "./components/AnnotationSelect";
import ReloadIcon from "./components/AnnotationSelect/assets/reload.svg?react";
import Feedback from "./components/Feedback";
import Improve from "./components/Improve";
import RightDrawer from "./components/RightDrawer";
import SummaryDetails from "./components/SummaryDetails";
import { EMPTY } from "./constants";
import { useMemberSummary } from "./hooks/useMemberSummary";
import { TransSumPair } from "./hooks/useTranscriptionSummaryPair";
import { postProcess } from "./postProcessing";
import styles from "./styles.module.sass";

interface DoctorScribeProps {
  patientId: string;
  onSummary: (summary: TransSumPair) => void;
  onLike: (isLike: boolean) => void;
}

enum State {
  loading,
  noConsent,
  noSummary,
  oldSummary,
  generating,
  hasSummary,
}

export default function DoctorScribe({
  patientId,
  onSummary,
  onLike,
}: DoctorScribeProps) {
  const api = useAPIClient();

  const reloadRef = useRef<HTMLDivElement>(null);
  const isReloadingRef = useRef(false);

  const [state, setState] = useState(State.loading);
  const [selectedMemberSummaryId, setSelectedMemberSummaryId] =
    useState<string>();
  const [memberSummary, setMemberSummary] = useState<MemberSummary>();
  const [showSummaryDetails, setShowSummaryDetails] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const { emitProblemDialog } = useReportProblemDialog();
  const { saveProblemReport } = useReportProblem();

  const {
    memberSummaryAnnotations,
    fetchMemberSummary,
    reloadMemberSummaryAnnotations,
    pollForMemberSummaryAnnotation,
  } = useMemberSummary({
    patientId,
  });

  const pushSummary = useCallback(
    (ms: MemberSummary) => {
      ms.summary = postProcess(ms.summary);
      onSummary(MSToTSPair(ms));
    },
    [onSummary]
  );

  //** When a new transcription is selected, fetch it and its corresponding summary */
  useEffect(() => {
    if (
      selectedMemberSummaryId &&
      memberSummaryAnnotations &&
      memberSummaryAnnotations.length > 0
    ) {
      const filtered = selectedMemberSummaryId
        ? memberSummaryAnnotations.filter((a) => {
            return a.id === selectedMemberSummaryId;
          })
        : [];

      const anno = filtered[0];
      fetchMemberSummary(anno.targetUri).then((s) => {
        setMemberSummary(s);
        pushSummary(s);
      });
    } else {
      setMemberSummary(undefined);
      onSummary(EMPTY);
    }
  }, [
    selectedMemberSummaryId,
    memberSummaryAnnotations,
    fetchMemberSummary,
    onSummary,
    pushSummary,
  ]);

  const handleGenerateNew = useCallback(async () => {
    setState(State.generating);
    const timestamp = await triggerSummaryGeneration(api, patientId);
    await pollForMemberSummaryAnnotation(timestamp);
  }, [api, patientId, pollForMemberSummaryAnnotation]);

  const handleReload = useCallback(() => {
    if (reloadRef.current) {
      reloadRef.current.classList.add(styles.spin);
      reloadRef.current.onanimationend = () => {
        reloadRef.current?.classList.remove(styles.spin);
      };
    }
    isReloadingRef.current = true;
    reloadMemberSummaryAnnotations();
  }, [reloadMemberSummaryAnnotations]);

  const onReportProblem = useCallback(() => {
    if (!memberSummary) {
      return;
    }

    emitProblemDialog(memberSummary.summary, (feedback: string) => {
      saveProblemReport(patientId, {
        prompts: {
          name: "MemberSummary",
          prompt: memberSummary.prompt,
          instruction: memberSummary.instruction,
          temp: -1,
        },
        transcription: memberSummary.transcription?.summary,
        results: memberSummary?.patient_record,
        content: memberSummary.summary,
        feedback,
      });
    });
  }, [memberSummary, emitProblemDialog, patientId, saveProblemReport]);

  // Init selector with latest summary and set rendering state
  useEffect(() => {
    if (
      memberSummaryAnnotations &&
      memberSummaryAnnotations.length > 0 &&
      (selectedMemberSummaryId === undefined || isReloadingRef.current)
    ) {
      isReloadingRef.current = false;
      const latest = memberSummaryAnnotations[0];
      const age = daysOld(latest.createdAt);
      if (age < 7) {
        // Arbitrary number, when is a summary "old"?
        setSelectedMemberSummaryId(memberSummaryAnnotations[0].id);
        setState(State.hasSummary);
      } else if (state !== State.generating) {
        setState(State.oldSummary);
      }
    } else if (
      memberSummaryAnnotations !== undefined &&
      memberSummaryAnnotations.length === 0 &&
      state !== State.generating
    ) {
      setState(State.noSummary);
    }
  }, [memberSummaryAnnotations, selectedMemberSummaryId, state]);

  const noSummary = (
    <div className={styles.warning}>
      No recent pre-generated summary available since there is no audio
      transcription available from the recent de-brief.
    </div>
  );

  const buttons = (
    <div className={styles.buttons}>
      <HoverTextButton
        onClick={() => setShowSummaryDetails((prev) => !prev)}
        active={showSummaryDetails}
      >
        Details
      </HoverTextButton>

      <HoverTextButton
        onClick={() => setShowChat((prev) => !prev)}
        active={showChat}
      >
        Improve
      </HoverTextButton>
    </div>
  );

  function renderComponent(state: State) {
    switch (state) {
      case State.loading:
        return <LineThrobber />;
      case State.noSummary:
        return (
          <div className={styles.row}>
            <div className={styles.warningContainer}>
              {noSummary}
              <div className={styles.reloadIcon} ref={reloadRef}>
                <ReloadIcon onClick={handleReload} />
              </div>
            </div>

            <HoverTextButton onClick={handleGenerateNew}>
              Generate summary
            </HoverTextButton>
          </div>
        );
      case State.hasSummary:
        return (
          <div className={styles.row}>
            <MemberSummarySelect
              label="Select pre-generated"
              annotations={memberSummaryAnnotations}
              selectedValue={selectedMemberSummaryId || ""}
              onSelectedId={setSelectedMemberSummaryId}
              onReload={() => {
                isReloadingRef.current = true;
                reloadMemberSummaryAnnotations();
              }}
            />
            {buttons}
          </div>
        );

      case State.generating:
        return <LineThrobber />;

      case State.oldSummary:
        return (
          <div className={styles.container}>
            <div className={styles.row}>
              <MemberSummarySelect
                label="Select pre-generated"
                annotations={memberSummaryAnnotations}
                selectedValue={selectedMemberSummaryId || ""}
                onSelectedId={setSelectedMemberSummaryId}
                onReload={() => {
                  isReloadingRef.current = true;
                  reloadMemberSummaryAnnotations();
                }}
              />
              {buttons}
            </div>
            <div className={styles.centerRow}>{noSummary}</div>
          </div>
        );

      default:
        return <></>;
    }
  }

  return (
    <div className={styles.DoctorScribe}>
      {renderComponent(state)}
      {memberSummary && (
        <Feedback onLike={onLike} onReportProblem={onReportProblem} />
      )}
      <RightDrawer
        title="Pre-generated summary details"
        onClose={() => setShowSummaryDetails(false)}
        active={showSummaryDetails}
      >
        <SummaryDetails patientId={patientId} memberSummary={memberSummary} />
      </RightDrawer>
      <RightDrawer title="Improve on summary" active={showChat}>
        {memberSummary ? (
          <Improve
            patientId={patientId}
            summary={memberSummary}
            active={showChat}
            onOK={(newSummary) => {
              if (newSummary) {
                setMemberSummary(newSummary);
                pushSummary(newSummary);
              }
              setShowChat(false);
            }}
          />
        ) : (
          <div>No generated summary to improve on</div>
        )}
      </RightDrawer>
    </div>
  );
}
