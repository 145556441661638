import { VisitSummary } from "@cur8/rich-entity";
import { useCallback, useState } from "react";
import Field from "render/ui/form/Field";
import Input from "render/ui/form/Input";
import RadioButtons from "render/ui/form/RadioButtons";
import styles from "./styles.module.sass";

type TimeRange = "days" | "weeks" | "months";

const TIME_RANGE_OPTIONS: { value: TimeRange; content: string }[] = [
  { value: "days", content: "days" },
  { value: "weeks", content: "weeks" },
  { value: "months", content: "months" },
];

type ChangeEvent<T> = React.Dispatch<React.SetStateAction<T>>;

interface VisitFormProps {
  visit: VisitSummary;
  onChange: ChangeEvent<VisitSummary>;
  disabled?: boolean;
}

export default function VisitForm({
  visit,
  onChange,
  disabled,
}: VisitFormProps) {
  const message = visit.summaryText;
  const followUpAt = visit.followUpDate;

  const handleMessage = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const summaryText = event.currentTarget.value;

      onChange((visit) => {
        return {
          ...visit,
          summaryText,
        };
      });
    },
    [onChange]
  );

  const [delay, setDelay] = useState(6);
  const [range, setRange] = useState<TimeRange>("months");

  const triggerFollowUpChange = useCallback(
    (range: TimeRange, delay: number) => {
      if (!isFinite(delay)) {
        return;
      }

      onChange((visit) => {
        const followUpDate = visit.visitDate.plus({ [range]: delay });

        return {
          ...visit,
          followUpDate,
        };
      });
    },
    [onChange]
  );

  const handleRange = useCallback(
    (nextRange: TimeRange) => {
      setRange(nextRange);
      triggerFollowUpChange(nextRange, delay);
    },
    [triggerFollowUpChange, delay]
  );

  const handleDelay = useCallback(
    (nextDelay: number) => {
      setDelay(nextDelay);
      triggerFollowUpChange(range, nextDelay);
    },
    [triggerFollowUpChange, range]
  );
  return (
    <>
      <Field label="Message">
        <Input>
          <textarea
            disabled={disabled}
            placeholder="What should the member take away from this visit?"
            value={message}
            onChange={handleMessage}
            rows={10}
            required
          />
        </Input>
      </Field>

      <Field
        label={
          followUpAt ? (
            <>
              Member should follow up on {followUpAt.toFormat("dd LLLL yyyy")}
            </>
          ) : (
            <>Member should follow up in</>
          )
        }
      >
        <div className={styles.range}>
          <Input>
            <input
              disabled={disabled}
              type="number"
              min={1}
              max={36}
              maxLength={2}
              required
              value={isFinite(delay) ? delay.toString() : ""}
              onChange={(event) => {
                const delay = parseFloat(event.currentTarget.value);
                handleDelay(delay);
              }}
            />
          </Input>

          <RadioButtons
            disabled={disabled}
            entries={TIME_RANGE_OPTIONS}
            value={range}
            onChange={handleRange}
          />
        </div>
      </Field>
    </>
  );
}
