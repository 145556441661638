import { parseAsJSON } from "lib/parse";
import { useEffect, useState } from "react";
import { Config, Context } from "./context";

interface ConfigContextProps {
  children: React.ReactNode;
}

export default function ConfigContext({ children }: ConfigContextProps) {
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    fetch("/config.json")
      .then((res) => parseAsJSON<Config>(res))
      .then(setConfig)
      .catch((error) => {
        console.error("Config init failed: %s", error);
      });
  }, []);

  if (!config) {
    return null;
  }

  return <Context.Provider value={config}>{children}</Context.Provider>;
}
