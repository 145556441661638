import styles from "./styles.module.sass";

const ClassForVariantMap: Record<Variant, string> = {
  solid: styles.solid,
  dashed: styles.dashed,
  outlined: styles.outlined,
};

type Variant = "solid" | "dashed" | "outlined";
type Highlight = "bold";

const getStylesForVariantAndColor = (variant: Variant, color: string) => {
  switch (variant) {
    case "dashed":
      return {
        borderColor: color,
      };
    case "solid":
      return {
        backgroundColor: color,
      };
    case "outlined":
      return {
        borderColor: color,
      };
  }
};

export default function Legend({
  variant = "solid",
  color,
  title,
  highlight,
}: {
  variant?: Variant;
  title: string;
  color: string;
  highlight?: Highlight;
}) {
  const className = ClassForVariantMap[variant];
  const style = getStylesForVariantAndColor(variant, color);

  return (
    <div className={styles.item} data-highlight={highlight}>
      <div className={className} style={style} />
      {title}
    </div>
  );
}
