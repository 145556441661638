import { useNav } from "@pomle/react-router-paths";
import { useCallback, useState } from "react";
import NekoIcon from "render/assets/neko-icon.svg?react";
import { useAppInsights } from "render/context/AppInsightsContext";
import { useMSAL } from "render/context/MSALContext";
import { useNotifications } from "render/context/NotificationContext";
import { useRoleBasedAccess } from "render/hooks/useRoleBasedAccess";
import { paths } from "render/routes/paths";
import MaxNumber from "render/ui/format/MaxNumber";
import Badged from "render/ui/presentation/Badged";
import FigureButton from "render/ui/trigger/FigureButton";
import BellIcon from "./assets/bell.svg?react";
import Schedule from "./assets/calendar-clock.svg?react";
import CogIcon from "./assets/cog.svg?react";
import PatientsIcon from "./assets/patients.svg?react";
import SkinBookIcon from "./assets/skin-book.svg?react";
import AudioRecorder from "./components/AudioRecordingButton";
import FeedbackIcon from "./components/FeedbackIcon";
import MenuButton, { MenuButtonLink } from "./components/MenuButton";
import styles from "./styles.module.sass";

export default function Sidebar() {
  const { entries } = useNotifications();
  const roleBasedAccess = useRoleBasedAccess();
  const { auth } = useMSAL();
  const appInsights = useAppInsights();

  const root = useNav(paths.root);
  const schedule = useNav(paths.scheduleSiteSelect);
  const findPatient = useNav(paths.patient.overview);
  const notifications = useNav(paths.notifications);
  const settings = useNav(paths.settings);
  const skinCRM = useNav(paths.skinCRM);

  const [feedbackSent, setFeedbackSent] = useState<boolean>(false);
  const resetFeedback = useCallback(() => {
    setFeedbackSent(false);
  }, []);
  const sendFeedback = useCallback(() => {
    if (!auth || !auth.account) {
      return;
    }
    appInsights.trackEvent(
      { name: "user_reported_issue" },
      {
        user: auth.account.username,
      }
    );
    setFeedbackSent(true);
    setTimeout(resetFeedback, 7331);
  }, [appInsights, auth, resetFeedback]);

  return (
    <div className={styles.Sidebar}>
      <div className={styles.main}>
        <FigureButton onClick={root.on({})}>
          <NekoIcon />
        </FigureButton>

        <div className={styles.buttonset}>
          <MenuButtonLink to={findPatient.to({})} title="Members">
            <PatientsIcon />
          </MenuButtonLink>

          {roleBasedAccess.feature.calendar && (
            <MenuButtonLink to={schedule.to({})} title="Schedule">
              <Schedule />
            </MenuButtonLink>
          )}

          {roleBasedAccess.feature.skinCRM && (
            <MenuButtonLink to={skinCRM.to({})} title="SkinCRM">
              <SkinBookIcon />
            </MenuButtonLink>
          )}
        </div>
      </div>

      <div className={styles.buttonset}>
        <AudioRecorder />
        <MenuButton
          onClick={sendFeedback}
          title={feedbackSent ? "Report sent" : "Report Issue"}
        >
          <FeedbackIcon active={feedbackSent} />
        </MenuButton>

        <MenuButtonLink to={notifications.to({})} title="Alerts">
          <Badged
            content={
              entries.length > 0 ? (
                <MaxNumber value={entries.length} max={9} />
              ) : null
            }
          >
            <BellIcon />
          </Badged>
        </MenuButtonLink>

        <MenuButtonLink to={settings.to({})} title="Settings">
          <CogIcon />
        </MenuButtonLink>
      </div>
    </div>
  );
}
