import { ImmutableScan } from "@cur8/rich-entity";
import { loadImage } from "lib/loader";
import { createContext, useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import { useQueries } from "../../hooks/useQueries";
import {
  BloodVesselsMask,
  BloodVesselsMode,
  ImageType,
  Property,
  TissueContextMode,
  TissueImage,
  TissueImages,
} from "../../lib/types";

function rangeParser(buffer: ArrayBuffer) {
  const view = new DataView(buffer);
  // check header
  if (
    view.byteLength < 8 ||
    view.getUint32(0) !== 0x89504e47 ||
    view.getUint32(4) !== 0x0d0a1a0a
  ) {
    return undefined;
  }
  // parse chunks
  let pos = 8;
  const dec = new TextDecoder("ascii");
  const ret: Record<string, number> = {};
  while (pos < buffer.byteLength - 8) {
    // chunk header
    const size = view.getUint32(pos);
    const fourCC = dec.decode(buffer.slice(pos + 4, pos + 8));
    // data offset
    const payload = pos + 8;
    pos = payload + size + 4;
    if (fourCC === "tEXt") {
      const [k, v] = dec
        .decode(buffer.slice(payload, payload + size))
        .split("\0", 2);
      ret[k] = parseFloat(v);
    }
  }
  return ret;
}

function toBlob(res: Response) {
  if (res.status !== 200) {
    throw Error(`Failed to fetch image: ${res.status} - ${res.statusText}`);
  }
  return res.blob();
}

async function blob2Image(blob: Blob): Promise<TissueImage> {
  const url = URL.createObjectURL(blob);
  const image = await loadImage(url);
  await image.decode();
  return { image, ...rangeParser(await blob.arrayBuffer()) };
}

export function useValue(
  scan: ImmutableScan,
  property: Property,
  index: number,
  maxIndex: number,
  mode: TissueContextMode,
  version: number,
  hasBloodVesselsMask: boolean,
  indexRemap?: number[]
) {
  const { handleError } = useReporting();
  const api = useAPIClient().blob;
  const { bloodVesselsMode, imageType } = useQueries();

  const [images, setImages] = useState<TissueImages>({
    viewer: undefined,
    chart: undefined,
  });
  const [bloodVesselsImage, setBloodVesselsImage] = useState<TissueImage>();
  const [bloodVesselsMask, setBloodVesselsMask] = useState<BloodVesselsMask>();
  useState<boolean>(false);

  const fetchImage = useCallback(
    (resultName: string, path: string) => {
      return api
        .fetchImmutableScanBlob({
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
          resultName,
          path,
        })
        .result.then(toBlob)
        .then((blob) => {
          return blob2Image(blob);
        })
        .catch(handleError);
    },
    [api, handleError, scan.id, scan.patientId, scan.version]
  );

  useEffect(() => {
    // Delete old BV-image when switching scan
    setBloodVesselsImage(undefined);
  }, [scan]);

  const regularImages = useCallback(() => {
    if (index < 0 || index > maxIndex) {
      return;
    }
    const realindex = indexRemap ? indexRemap[index] : index;

    let path = `${property}.png`;
    if (imageType === ImageType.rgb) {
      path = "rgb.png";
    } else if (version < 3 && imageType === ImageType.highContrast) {
      path = `${property}_dyn.png`;
    }
    const resultName =
      (version >= 3 && imageType === ImageType.normal
        ? "normalized_"
        : "acq_") + realindex;

    const viewer = fetchImage(resultName, path);
    let chart = viewer;
    if (imageType !== ImageType.normal && property === Property.thermal) {
      // Always fetch the normal picture for Thermal
      chart = fetchImage(
        (version >= 3 ? "normalized_" : "acq_") + realindex,
        path
      );
    }

    Promise.all([viewer, chart]).then((res) => {
      if (res[0]) {
        setImages({
          viewer: res[0],
          chart: res[1] ?? res[0],
        });
      }
    });
  }, [fetchImage, imageType, index, indexRemap, maxIndex, property, version]);

  const PADImages = useCallback(() => {
    let resultName = `pad`;
    let path = `mean_${property}.png`;

    if (imageType === ImageType.rgb) {
      path = "rgb.png";
      resultName = "acq_" + (indexRemap ? indexRemap[0] : 0);
    }

    fetchImage(resultName, path).then((res) => {
      if (res) {
        setImages({
          viewer: res,
          chart: res,
        });
      }
    });
  }, [fetchImage, imageType, indexRemap, property]);

  useEffect(() => {
    if (mode === TissueContextMode.PAD) {
      PADImages();
    } else {
      regularImages();
    }
  }, [mode, PADImages, regularImages]);

  useEffect(() => {
    if (!scan.id || !hasBloodVesselsMask) {
      return;
    }

    fetchImage("blood_vessels", "blood_vessels.png").then((img) => {
      if (img) {
        setBloodVesselsImage(img);
      }
    });
  }, [fetchImage, hasBloodVesselsMask, scan]);

  useEffect(() => {
    if (!bloodVesselsImage || property === Property.thermal) {
      setBloodVesselsMask(undefined);
      return;
    }
    // PAD should ALWAYS use BV-mode: exclude
    if (mode === TissueContextMode.PAD) {
      setBloodVesselsMask({
        threshold: bloodVesselsImage.threshold_exclude_blood_vessels,
        extract: false,
      });
      return;
    }

    switch (bloodVesselsMode) {
      case BloodVesselsMode.excluded:
        setBloodVesselsMask({
          threshold: bloodVesselsImage.threshold_exclude_blood_vessels,
          extract: false,
        });
        break;
      case BloodVesselsMode.extracted:
        setBloodVesselsMask({
          threshold: bloodVesselsImage.threshold_extract_blood_vessels,
          extract: true,
        });
        break;
      default:
        setBloodVesselsMask(undefined);
    }
  }, [bloodVesselsImage, bloodVesselsMode, mode, property]);

  return {
    bloodVessels: bloodVesselsImage,
    bloodVesselsMask,
    chart: images.chart,
    viewer: images.viewer,
  };
}

type TissueImageContextValue = ReturnType<typeof useValue>;

export const Context = createContext<TissueImageContextValue | null>(null);
