import { APITypesV2 } from "@cur8/api-client";
import { HabitualStyle } from "@cur8/measurements";
import { createResolver } from "./resolve";

export type ConvertedQuestionnaire = ReturnType<typeof convertQuestionnaire>;

export function getIsSmoker({
  value,
  isV1,
}: {
  value: string | undefined;
  isV1: boolean;
}): boolean | undefined {
  if (value == null) {
    return undefined;
  }

  if (isV1) {
    if (value === HabitualStyle.Currently.toString()) {
      return true;
    }
    return false;
  }

  if (value === "Yes") {
    return true;
  }

  return false;
}

export function convertQuestionnaire(
  questionnaireResponse: APITypesV2.QuestionnaireResponse
) {
  const getValue = createResolver(questionnaireResponse);

  const v1Questions = {
    exerciseHoursPerWeek: getValue("weeklyExerciseHours"),
    snusCansPerWeek: getValue("snus.weeklyCans"),
    v1Medications: getValue("medication"),
  };

  const isV1 = questionnaireResponse.questionnaireType === "onboarding/1";

  const isSmoker = getIsSmoker({ value: getValue("smoking.style"), isV1 });

  return {
    ...v1Questions,
    isV1,
    medications: getValue("medications.type"),
    allergies: getValue("allergies.list"),
    diet: getValue("diet"),
    cardioExercise: getValue("weeklyCardioExerciseHours"),
    strengthExercise: getValue("weeklyStrengthExerciseHours"),
    alcohol: getValue("alcohol.weeklyDrinks"),
    isSmoker,
    smoking: getValue("smoking.dailyCigarettes"),
    snus: getValue("snus.frequency"),
    additionalWorries: getValue("additionalWorries"),
    healthConditions: getValue("healthConditions"),
    healthConditionsFamily: getValue("healthConditionsFamily"),
    cardiovascular: getValue("cardiovascular"),
    skinCancer: getValue("skinCancer"),
    diabetes: getValue("diabetes"),
    relevantConditions: getValue("relevantConditions"),
  };
}
