import { Risk } from "@cur8/health-risks-calc";

export function getHighestRisk(risks: Risk[]): Risk {
  return Math.max(...risks);
}

export function getRiskColor(risk: Risk): string {
  const record: Record<Risk, string> = {
    [Risk.HighRisk]: "#F96452",
    [Risk.ImmediateRisk]: "#F96452",
    [Risk.Optimal]: "#9EBCC3",
    [Risk.Normal]: "#9EBCC3",
    [Risk.Risk]: "#FFB647",
    [Risk.Unknown]: "#9EBCC3",
  };

  return record[risk];
}
