import MetricUnitContext, {
  MetricsUnits,
} from "render/context/MetricsUnitContext";
import EntryPoint from "./components/EntryPoint";
import DashboardContext from "./context/DashboardContext";
import PatientDataContext from "./context/PatientDataContext";
import styles from "./styles.module.sass";

function onContextMenu(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  event.preventDefault();
}

const units: MetricsUnits = {
  force: "metric",
  length: "metric",
  pressure: "metric",
  weight: "metric",
};

interface DashboardPageProps {
  patientId: string;
}

export default function DashboardPage({ patientId }: DashboardPageProps) {
  return (
    <MetricUnitContext units={units}>
      <PatientDataContext patientId={patientId}>
        <DashboardContext>
          <div className={styles.DashboardPage} onContextMenu={onContextMenu}>
            <EntryPoint />
          </div>
        </DashboardContext>
      </PatientDataContext>
    </MetricUnitContext>
  );
}
