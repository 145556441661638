import ArrowIcon from "render/ui/symbol/ArrowIcon";
import styles from "./styles.module.sass";

export default function BackButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button
      type="button"
      className={styles.BackButton}
      data-clickabe={!!props.onClickCapture || !!props.onClick}
      {...props}
    >
      <ArrowIcon /> {children}
    </button>
  );
}
