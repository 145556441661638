import { Sex } from "@cur8/rich-entity";

const KIND_MAP: Record<Sex, "neutral" | "male" | "female"> = {
  [Sex.Male]: "male",
  [Sex.Female]: "female",
  [Sex.Unknown]: "neutral",
};

export function toPose(sex: Sex) {
  return KIND_MAP[sex];
}
