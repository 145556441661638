import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useLatestScans(patientId: string) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<ImmutableScan[]>>();

  useEffect(() => {
    const request = api.scan.queryImmutableScans({
      patientId,
      order: APITypesV1.SortOrder.Desc,
      pageSize: 1,
    });

    request.result
      .then((result) => result.items.map(fromAPI.toImmutableScan))
      .then((scans) => {
        setResult({
          data: scans,
        });
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [api, patientId]);

  return result;
}
