import React, { useMemo } from "react";
import RangeLine, { getBounds } from "render/ui/presentation/RangeLine";
import { Highlight } from "../ChartMarker/Marker";
import { RangeLineEntry, RangePointEntry } from "../RangeLine/lib";
import styles from "./styles.module.sass";

export type Entry = {
  ranges: RangeLineEntry[];
  points: RangePointEntry[];
  label: React.ReactNode;
};

export type PositionalNote = {
  value: number;
  label: React.ReactNode;
  content: React.ReactNode;
  highlight?: Highlight;
};

interface BarRangeGraphProps {
  entries: Entry[];
  thresholds?: PositionalNote[];
  children?: ({
    bounds,
  }: {
    bounds: ReturnType<typeof getBounds>;
  }) => React.ReactNode;
}

export default function BarRangeGraph({
  entries,
  thresholds,
  children,
}: BarRangeGraphProps) {
  const bounds = useMemo(() => {
    const ranges = entries.flatMap((entry) => entry.ranges);
    const points = entries.flatMap((entry) => entry.points);
    return getBounds(ranges, points);
  }, [entries]);

  const isThresholdOnUpperBound = thresholds?.some(
    (threshold) => threshold.value >= bounds.max
  );

  return (
    <div className={styles.BarRangeGraph}>
      <div
        className={styles.graph}
        data-hide-top-line={isThresholdOnUpperBound}
      >
        <div>
          {thresholds &&
            thresholds.map((threshold, index) => {
              return (
                <div
                  key={index}
                  className={styles.thresholdContainer}
                  data-highlight={threshold.highlight}
                  style={{
                    position: "absolute",
                    bottom: `${bounds.lerp(threshold.value) * 100}%`,
                    transform: "translateY(50%)",
                  }}
                >
                  {threshold.content && (
                    <span className={styles.leftLabel}>
                      {threshold.content}
                    </span>
                  )}
                  <div className={styles.threshold}></div>
                  {threshold.value !== undefined && (
                    <span className={styles.rightLabel}>{threshold.label}</span>
                  )}
                </div>
              );
            })}
        </div>

        <div className={styles.values}>
          <div className={styles.bars}>
            <>
              {children ? children({ bounds }) : null}

              {entries.map((entry, index) => {
                return (
                  <div key={index} style={{ width: 2, height: "100%" }}>
                    <RangeLine
                      bounds={bounds}
                      ranges={entry.ranges}
                      points={entry.points}
                      orientation="vertical"
                    />
                  </div>
                );
              })}
            </>
          </div>
        </div>
      </div>

      <div className={styles.labels}>
        {entries.map((entry, index) => {
          return <div key={index}>{entry.label}</div>;
        })}
      </div>
    </div>
  );
}
