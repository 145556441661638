import { APITypesV2 } from "@cur8/api-client";

type QuestionId =
  | "additionalWorries"
  | "diet"
  | "healthConditions"
  | "healthConditionsFamily"
  | "medications.type"
  | "ethnicities"
  | "allergies"
  | "allergies.list"
  | "alcohol"
  | "alcohol.weeklyDrinks"
  | "weeklyCardioExerciseHours"
  | "weeklyStrengthExerciseHours"
  | "smoking.style"
  | "smoking.dailyCigarettes"
  | "snus.style"
  | "snus.frequency"
  | "cardiovascular"
  | "skinCancer"
  | "diabetes"
  | "relevantConditions";

type v1QuestionId = "weeklyExerciseHours" | "snus.weeklyCans" | "medication";

export function createResolver(response: APITypesV2.QuestionnaireResponse) {
  return (questionId: QuestionId | v1QuestionId) =>
    getValue(
      response.questionsAndAnswers.find((q) => q.questionId === questionId)
        ?.answer?.value
    );
}

function getValue(answer: string | number | boolean | string[] | undefined) {
  if (answer == null) {
    return;
  }
  if (Array.isArray(answer)) {
    return answer.join(", ");
  }
  if (typeof answer === "boolean") {
    return answer ? "Yes" : "No";
  }
  return String(answer);
}
