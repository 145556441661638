import { useCallback, useState } from "react";
import { DateTime } from "luxon";
import { Context } from "./context";

type NotificationType = "success" | "error" | "info";

type NotificationEntry = {
  id: string;
  timestamp: DateTime;
  type: NotificationType;
  content: React.ReactNode;
};

let counter = 0;

function useNotificationState() {
  const [entries, setEntries] = useState<NotificationEntry[]>([]);

  const emit = useCallback(
    (content: React.ReactNode, type: NotificationType) => {
      const entry: NotificationEntry = {
        id: (++counter).toString(),
        timestamp: DateTime.now(),
        type,
        content,
      };

      setEntries((entries) => {
        return [...entries, entry];
      });
    },
    []
  );

  const clear = useCallback(() => {
    setEntries([]);
  }, []);

  return { entries, emit, clear };
}

interface NotificationContextProps {
  children: React.ReactNode;
}

export default function NotificationContext({
  children,
}: NotificationContextProps) {
  const value = useNotificationState();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
