import { useState } from "react";
import Field from "render/ui/form/Field";
import Input from "render/ui/form/Input";
import PopupDialog from "render/ui/layouts/PopupDialog";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";
interface ReportLLMProblemProps {
  content: string;
  onOK(feedback: string): void;
  onCancel(): void;
}

export default function ReportLLMProblem({
  content,
  onOK,
  onCancel,
}: ReportLLMProblemProps) {
  const [feedback, setFeedback] = useState("");
  return (
    <>
      <PopupDialog>
        <div className={styles.container}>
          <h1>Give feedback on the generated content</h1>
          <Field label="Generated content">
            <Input>
              <textarea rows={12} value={content} readOnly />
            </Input>
          </Field>
          <Field label="Feedback">
            <Input>
              <textarea
                placeholder={
                  "Enter feedback on the generated content. Use your preferred language, but do not enter any sensitive information here."
                }
                value={feedback}
                onChange={(e) => {
                  setFeedback(e.target.value);
                }}
                rows={5}
                required
                data-testid="feedback"
              />
            </Input>
          </Field>
          <div className={styles.footer}>
            <HoverTextButton type="button" onClick={() => onCancel()}>
              Cancel
            </HoverTextButton>
            <HoverTextButton
              type="button"
              onClick={() => {
                onOK(feedback);
              }}
            >
              SUBMIT
            </HoverTextButton>
          </div>
        </div>
      </PopupDialog>
    </>
  );
}
