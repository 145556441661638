import React from "react";
import { ToggleItemProps } from "./ToggleItem";
import styles from "./styles.module.sass";

interface ToggleProps {
  children:
    | React.ReactElement<ToggleItemProps>[]
    | React.ReactElement<ToggleItemProps>;
}

export default function Toggle({ children }: ToggleProps) {
  return (
    <div className={styles.Toggle}>
      {React.Children.map(children, (child, index) => {
        const isFirst = index === 0;

        return (
          <>
            {!isFirst && <div className={styles.Separator} />}
            {child}
          </>
        );
      })}
    </div>
  );
}
