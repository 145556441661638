import { HbA1c } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import MetricResultHeader, {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import RangeChart, {
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";
import { getTitlesForValues } from "./getTitlesForValues";
import styles from "./styles.module.sass";

interface HbA1cSegmentProps {
  patient: Patient;
  metrics: Metric<"bloodwork.hba1c">[];
}

export default function HbA1cSegment({ patient, metrics }: HbA1cSegmentProps) {
  const patientAge = useAge(patient);
  const latestMetric = metrics[0];
  const currentValue = latestMetric.unit["mmol/mol"];

  const prevMetric = metrics.length > 1 ? metrics[1] : undefined;
  const prevValue = prevMetric?.unit["mmol/mol"];

  const valuesDiff = prevValue ? currentValue - prevValue : undefined;

  const riskRanges = useMemo(() => {
    return HbA1c.rangesFor({ age: patientAge });
  }, [patientAge]);

  const titles = getTitlesForValues(currentValue, prevValue);
  const rangesForValue = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRanges.entries), currentValue);
  }, [riskRanges, currentValue]);

  return (
    <div className={styles.HbA1cSegment}>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{titles.title}</MainTitle>
          {titles.subtitle ? <Subtitle>{titles.subtitle}</Subtitle> : null}
          {titles.aux ? <AuxTitle>{titles.aux}</AuxTitle> : null}
        </Titles>
        <Diff>
          <DiffValue valuesDiff={valuesDiff} unit="mmol/mol" />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={rangesForValue}
        value={currentValue}
        previousValue={prevValue}
      >
        {({ ranges, values }) => <RangeChart ranges={ranges} values={values} />}
      </RangeChartDataComposer>
    </div>
  );
}
