import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { PatientBlobURI } from "@cur8/uri";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";

export class RecordingSession {
  date: DateTime;
  stream: MediaStream;

  constructor(stream: MediaStream) {
    this.date = DateTime.now();
    this.stream = stream;
  }

  stop() {
    this.stream.getTracks().forEach((track) => track.stop());
  }
}

export function audiorecordingFilename(timestamp: string) {
  return `speech/${timestamp}_audiorecording.json`;
}

export function webmFilename(timestamp: string, n: number) {
  return `speech/${timestamp}_${n}.webm`;
}

export async function uploadPatientAudioBlob(
  api: APIClient,
  patient: Patient,
  data: Blob,
  fileName: string
) {
  const patientId = patient.patientId;

  const res = await api.blob.putPatientBlob({
    patientId: patientId,
    file: data,
    path: fileName,
  }).result;

  if (!res.ok) {
    console.error(
      "Could not upload recording, upload resulted in: ",
      res.status,
      res.statusText
    );
    return false;
  }

  // create an annotation so we can find the file
  try {
    const targetUri = new PatientBlobURI(patientId, fileName);
    await api.annotation.createAnnotation(
      { patientId },
      {
        acceptState: APITypesV1.AcceptState.Proposed,
        targetUri: targetUri.toString(),
        applicationSpecificTarget: fileName.includes("webm")
          ? "speech:audiofile"
          : "speech:audiorecording",
      }
    ).result;
  } catch (err) {
    console.error(
      "Could not create recording annotation, upload resulted in: ",
      err
    );
    return false;
  }
  return true;
}
