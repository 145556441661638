import { clamp } from "lib/math";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataPlot from "../DataPlot";
import DataWindow, { Range } from "../DataWindow";
import NavGraph from "../NavGraph";
import Ruler from "../Ruler";
import { MINIMUM_SELECTION } from "../constants";
import { JSXResult } from "../fragments";
import { PlotData } from "../types";
import styles from "./styles.module.sass";
export interface GraphWithRulerProps {
  title: string;
  data: PlotData[];
  diffData: PlotData[];
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
  onMeasurement?: (data: RulerData | undefined) => void; // Renders delay only when callback *not* set
  plugin?: React.ReactNode;
}

export type RulerData = {
  distanceMs: number;
  bpm: number | undefined;
};

function bpm(distanceMs: number) {
  return distanceMs > 100 ? Math.round((60 * 1000) / distanceMs) : undefined;
}

export default function GraphWithRuler({
  title,
  data,
  diffData,
  range,
  setRange,
  onMeasurement,
  plugin,
}: GraphWithRulerProps) {
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    onMeasurement
      ? null
      : JSXResult("", "Make a selection in the plot to measure delay")
  );
  const [measurement, setMeasurement] = useState<RulerData>();
  const ref = useRef<HTMLDivElement>(null);

  const signalLengthMs = data[0].signal.lengthMs;

  const handleScroll = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const stepSize = MINIMUM_SELECTION;
      const step = event.deltaY > 0 ? stepSize : -stepSize;
      const start = clamp(Math.min(...range) + step, 0, 1);
      let end = clamp(Math.max(...range) - step, 0, 1);
      setRange((p) => (start < end ? [start, end] : p));
    },
    [range, setRange]
  );

  useEffect(() => {
    const div = ref.current;
    if (div) {
      div.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (div) {
        div.removeEventListener("wheel", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (!onMeasurement && measurement) {
      setResultElement(JSXResult(`Delay: ${measurement.distanceMs} ms`, ""));
    }
  }, [onMeasurement, measurement]);

  if (data.length === 0) {
    console.warn("No signal provided");
    return null;
  }

  return (
    <div className={styles.GraphWithRuler} data-testid="graph">
      <div className={styles.signal}>
        <div className={styles.edge}>
          <h2>{title}</h2>
        </div>

        <div className={styles.grow}>
          <DataWindow range={range} onRange={setRange}>
            <div className={styles.fullSignal} ref={ref}>
              <NavGraph data={data} height={60} width="100%" />
            </div>
          </DataWindow>
        </div>
        <div className={styles.edge}>{plugin}</div>
      </div>

      <div className={styles.window} data-testid="ruler">
        <Ruler
          selectedRange={range}
          signalLengthMs={signalLengthMs}
          onDistance={(d) => {
            if (d) {
              setMeasurement({ distanceMs: d, bpm: bpm(d) });
              onMeasurement && onMeasurement({ distanceMs: d, bpm: bpm(d) });
            }
          }}
          onZoomTo={setRange}
        >
          <DataPlot
            range={range}
            grid={{ x: 40 }}
            lengthMs={signalLengthMs}
            data={data}
            height={140}
            width="100%"
          />
          <DataPlot
            range={range}
            grid={{ x: 40 }}
            lengthMs={signalLengthMs}
            data={diffData}
            height={140}
            width="100%"
          />
        </Ruler>
      </div>
      <div data-testid="resultElement">{resultElement}</div>
    </div>
  );
}
