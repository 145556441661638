import { Patient } from "@cur8/rich-entity";
import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import BloodOxygenMetric from "render/fragments/avatar/overlay/BloodOxygenMetric";
import HeartRateMetric from "render/fragments/avatar/overlay/HeartRateMetric";
import LeftAnklePressure from "render/fragments/avatar/overlay/LeftAnklePressure";
import LeftArmBloodPressure from "render/fragments/avatar/overlay/LeftArmBloodPressure";
import LeftToePressure from "render/fragments/avatar/overlay/LeftToePressure";
import RightAnklePressure from "render/fragments/avatar/overlay/RightAnklePressure";
import RightArmBloodpressure from "render/fragments/avatar/overlay/RightArmBloodpressure";
import RightToePressure from "render/fragments/avatar/overlay/RightToePressure";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import Overlay from "../../components/Overlay";
import styles from "./styles.module.sass";

const ACTIVE = new Set([Layer.Cardio, Layer.Circulation]);

interface CardioLayerProps {
  layer: Layer;
  patient: Patient;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export default function CardioLayer({
  layer,
  patient,
  areas,
  projector,
}: CardioLayerProps) {
  const {
    metrics: { cardio },
  } = usePatientData();

  const positions = useMemo(() => {
    const leftUpperArm = toCenter(areas.leftUpperArm);
    const leftAnkle = toCenter(areas.leftAnkle);
    const rightAnkle = toCenter(areas.rightAnkle);
    const leftToe = toCenter(areas.leftToe);
    const rightToe = toCenter(areas.rightToe);
    const rightUpperArm = toCenter(areas.rightUpperArm);
    const rightKnee = toCenter(areas.rightKnee);
    const leftKnee = toCenter(areas.leftKnee);

    leftAnkle.z += 100;
    rightAnkle.z += 100;
    leftToe.z -= 20;
    leftToe.x += 50;
    leftToe.y -= 100;
    rightToe.z -= 20;
    rightToe.x -= 50;
    rightToe.y -= 100;
    rightKnee.x = -625;
    rightKnee.z += 150;
    leftKnee.x += 520;
    leftKnee.z += 170;

    return {
      leftUpperArm,
      leftAnkle,
      rightAnkle,
      leftToe,
      rightToe,
      rightUpperArm,
      rightKnee,
      leftKnee,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.CardioLayer}>
      {anchors && (
        <>
          {cardio.brachialPressure.left && (
            <Overlay pos={anchors.leftUpperArm}>
              <div className={styles.item} data-active={active}>
                <LeftArmBloodPressure
                  patient={patient}
                  brachialPressure={cardio.brachialPressure.left}
                />
              </div>
            </Overlay>
          )}

          {cardio.brachialPressure.right && (
            <Overlay pos={anchors.rightUpperArm}>
              <div className={styles.item} data-active={active}>
                <RightArmBloodpressure
                  patient={patient}
                  brachialPressure={cardio.brachialPressure.right}
                />
              </div>
            </Overlay>
          )}

          {cardio.anklePressure.left && (
            <Overlay pos={anchors.leftAnkle}>
              <div className={styles.item} data-active={active}>
                <LeftAnklePressure anklePressure={cardio.anklePressure.left} />
              </div>
            </Overlay>
          )}

          {cardio.anklePressure.right && (
            <Overlay pos={anchors.rightAnkle}>
              <div className={styles.item} data-active={active}>
                <RightAnklePressure
                  anklePressure={cardio.anklePressure.right}
                />
              </div>
            </Overlay>
          )}

          {!!cardio.toePressure.left?.length && (
            <Overlay pos={anchors.leftToe}>
              <div className={styles.item} data-active={active}>
                <LeftToePressure toePressure={cardio.toePressure.left} />
              </div>
            </Overlay>
          )}

          {!!cardio.heartRate && (
            <Overlay pos={anchors.rightKnee}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <HeartRateMetric heartRate={cardio.heartRate} />
              </div>
            </Overlay>
          )}

          {!!cardio.bloodOxygen && (
            <Overlay pos={anchors.leftKnee}>
              <div className={styles.item} data-active={layer === Layer.Cardio}>
                <BloodOxygenMetric bloodOxygen={cardio.bloodOxygen} />
              </div>
            </Overlay>
          )}

          {!!cardio.toePressure.right?.length && (
            <Overlay pos={anchors.rightToe}>
              <div className={styles.item} data-active={active}>
                <RightToePressure toePressure={cardio.toePressure.right} />
              </div>
            </Overlay>
          )}
        </>
      )}
    </div>
  );
}
