import { ABI, BrachialPressure as BP, TBI } from "@cur8/health-risks-calc";
import { BrachialPressure } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";
import { ABIScore } from "render/hooks/api/metrics/useABIScore";
import { TBIScore } from "render/hooks/api/metrics/useTBIScore";
import { useAge } from "render/hooks/patient/useAge";
import MetricStatus from "render/ui/presentation/MetricStatus";
import MetricStatusBadge from "render/ui/presentation/MetricStatusBadge";
import ArterialHealthIcon from "./assets/arterial-health.svg?react";

interface ArterialHealthItemProps {
  patient: Patient;
  abi: { left: ABIScore | undefined; right: ABIScore | undefined };
  tbi: TBIScore | undefined;
  highestBrachial: BrachialPressure | undefined;
}

export default function ArterialHealthItem({
  patient,
  abi,
  tbi,
  highestBrachial,
}: ArterialHealthItemProps) {
  const age = useAge(patient);

  const risk = useMemo(() => {
    if (!abi.left || !abi.right || !tbi || !highestBrachial) {
      return;
    }

    const { systolic, diastolic } = highestBrachial;

    const risks = [
      ABI.rangesFor().findRisk({ ratio: abi.left.score }),
      ABI.rangesFor().findRisk({ ratio: abi.right.score }),
      TBI.rangesFor().findRisk({ ratio: tbi.score }),
      BP.riskFor({ systolic, diastolic, age }),
    ];

    return Math.max(...risks);
  }, [age, abi, tbi, highestBrachial]);

  return (
    <MetricStatus
      caption="Arterial Health"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <ArterialHealthIcon />
    </MetricStatus>
  );
}
