import { Sex } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import { concatPercent } from "../../../../components/ProjectionChart/lib/util";
import {
  getBenchmarkMedianFor,
  getBenchmarkValueClampLimitsForResultDiff,
  getMemberValueClampLimitsForResultDiff,
  toFixedIfNecessary,
} from "../../lib/benchmark";
import { BloodPressureType } from "../../lib/projection";
import styles from "./styles.module.sass";

interface BloodPressureBenchmarkProps {
  bloodPressureType: BloodPressureType;
  currentValue: number;
  age: number;
  sex: Sex;
}

const SexToGenderBinTrack: Record<Sex, string> = {
  [Sex.Female]: "female",
  [Sex.Male]: "male",
  [Sex.Unknown]: "",
};

function normalizePercentageDifference(value: number) {
  if (value <= 80) {
    return 60;
  }

  if (value <= 90) {
    return 70;
  }

  return 100;
}

export default function BloodPressureBenchmark({
  bloodPressureType,
  age,
  sex,
  currentValue,
}: BloodPressureBenchmarkProps) {
  const ageBin = getAgeBinForAge(age);

  const benchmarkMedianValue = getBenchmarkMedianFor(
    bloodPressureType,
    age,
    sex
  );

  const isAboveTheBenchmarkValue = currentValue > benchmarkMedianValue;
  const highlight = isAboveTheBenchmarkValue ? "warning" : "better";

  const yourResultDiff = currentValue - benchmarkMedianValue;

  const clampValuesForBenchmarkValue =
    getBenchmarkValueClampLimitsForResultDiff(yourResultDiff);
  const clampValuesForMembeerkValue =
    getMemberValueClampLimitsForResultDiff(yourResultDiff);

  const totalHeight = Math.max(benchmarkMedianValue, currentValue);
  const heightMemberValue = clamp(
    normalizePercentageDifference((currentValue / totalHeight) * 100),
    clampValuesForMembeerkValue.from,
    clampValuesForMembeerkValue.to
  );
  const heightBenchmarkValue = clamp(
    normalizePercentageDifference((benchmarkMedianValue / totalHeight) * 100),
    clampValuesForBenchmarkValue.from,
    clampValuesForBenchmarkValue.to
  );

  return (
    <div className={styles.Benchmark}>
      <div className={styles.chart}>
        <div className={styles.content}>
          <div
            className={styles.benchmark}
            style={{
              width: concatPercent(heightBenchmarkValue),
            }}
          >
            <div className={styles.benchmarkDescription}>
              <div className={styles.type}>
                TYPICAL NEKO MEMBER{" "}
                <span className={styles.subtype}>
                  [{SexToGenderBinTrack[sex]} {ageBin} yrs]
                </span>
              </div>
              <div className={styles.valueContainer}>
                <div className={styles.value}>
                  {toFixedIfNecessary(benchmarkMedianValue)}
                </div>
                <div className={styles.unit}>mmHg</div>
              </div>
            </div>
          </div>
          <div className={styles.population}></div>
        </div>
        <div className={styles.content}>
          <div
            className={styles.benchmark}
            data-highlight={highlight}
            style={{
              width: concatPercent(heightMemberValue),
            }}
          >
            <div className={styles.benchmarkDescription}>
              <div className={styles.type}>Your result</div>
              <div className={styles.valueContainer}>
                <div className={styles.value}>
                  {toFixedIfNecessary(currentValue)}
                </div>
                <div className={styles.unit}>mmHg</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.population}></div>
      </div>
    </div>
  );
}
