import { APITypesV1, LinkingResultAnnotation } from "@cur8/api-client";
import { Annotation, fromAPI } from "@cur8/rich-entity";
import { createCodec, createQuery } from "@pomle/paths";
import { useQueryParams } from "@pomle/react-router-paths";
import { ViewStack } from "@pomle/react-viewstack";
import { Slide } from "@pomle/react-viewstack-transitions";
import { Camera, SIDES } from "lib/api/rigg";
import { PanoramaImageURI } from "lib/api/uri";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useImmutableScanQuery } from "render/hooks/api/useImmutableScanQuery";
import { usePatient } from "render/hooks/api/usePatient";
import { useRecording } from "render/hooks/api/useRecording";
import { useThermalURIs } from "render/hooks/api/useThermalURIs";
import { query } from "render/routes/querys";
import PageFrame from "render/ui/layouts/PageFrame";
import MessagePage from "../MessagePage";
import PhysicalArtifactExplorer from "./components/PhysicalArtifactExplorer";
import ScanExplorer from "./components/ScanExplorer";
import ScanOverview from "./components/ScanOverview";
import SkinAssessment from "./components/SkinAssessment";
import ViewSwitcher from "./components/ViewSwitcher";
import { useRelevantRecordingAnnotations } from "./hooks/useRelevantRecordingAnnotations";
import { ViewState } from "./viewstate";

const CAMERAS: Camera[] = [
  "c3",
  "b3",
  "a3",
  "c2",
  "b2",
  "a2",
  "c1",
  "b1",
  "a1",
];

const THERMAL_CAMERAS: Camera[] = ["c3", "b3", "c2", "b2", "c1", "b1"];

const VIEW_STATES = new Set<ViewState>([
  ViewState.Overview,
  ViewState.Explore,
  ViewState.Assessment,
]);

const viewStateCodec = createCodec<ViewState>(
  (source) => {
    return source;
  },
  (source: string) => {
    if (VIEW_STATES.has(source as ViewState)) {
      return source as ViewState;
    }
    return ViewState.Explore;
  }
);

const stateQuery = createQuery({
  view: viewStateCodec,
});

const PRELOAD_WIDTH = 1;

interface AtlasPageProps {
  patientId: string;
  recordingId: string;
  deviceId: string;
}

export default function AtlasPage({
  patientId,
  recordingId,
  deviceId,
}: AtlasPageProps) {
  const patient = usePatient(patientId);
  const recording = useRecording(deviceId, recordingId);

  const { entries: recordingEntries, fetch: reloadEntries } =
    useRelevantRecordingAnnotations(deviceId, recordingId);

  const annotations = useMemo(() => {
    return recordingEntries.flatMap((entry) => entry.annotations);
  }, [recordingEntries]);

  const [stateParams, setStateParams] = useQueryParams(stateQuery);
  const [uriParams, setURIParams] = useQueryParams(query.atlas);

  const scanId = uriParams.scanId[0];
  const scanVersion = uriParams.scanVersion[0];
  const { fetch: fetchScan, scan } = useImmutableScanQuery(
    patientId,
    scanId,
    scanVersion
  );
  const api = useAPIClient();

  const [detectedAnnotations, setDetectedAnnotations] = useState<Annotation[]>(
    []
  );

  useEffect(() => {
    if (!scanId || !scanVersion) {
      return;
    }

    const now = DateTime.now();

    function toAnnotation(source: LinkingResultAnnotation): Annotation {
      return fromAPI.toAnnotation({
        ...source,
        createdAt: now.toISO(),
        updatedAt: now.toISO(),
        comment: "Hydrated from reduced",
        previewUrl: "",
        acceptState: APITypesV1.AcceptState.Proposed,
        audit: {},
      });
    }

    api.scan
      .fetchLinkingResults({ patientId, scanId, scanVersion })
      .then((links) => {
        return links.map(toAnnotation);
      })
      .then(setDetectedAnnotations);
  }, [api, patientId, scanId, scanVersion]);

  const viewState = stateParams.view[0] || ViewState.Overview;

  const setViewState = useCallback(
    (viewState: ViewState) => {
      setStateParams({
        view: [viewState],
      });
    },
    [setStateParams]
  );

  const handleActivateURI = useCallback(
    (uri: PanoramaImageURI) => {
      setURIParams({
        camera: [uri.cameraName],
        side: [uri.side],
      });
    },
    [setURIParams]
  );

  useEffect(() => {
    if (!recording || !recording.uri || !patientId || scanId || scanVersion) {
      return;
    }

    //load scan
    api.scan
      .queryImmutableScansForPatient({
        patientId,
        sourceUri: recording.uri.toString(),
      })
      .result.then((scans) => {
        const scan = scans.items.at(0);
        if (!scan) {
          return;
        }

        setURIParams({
          scanVersion: [scan.version],
          scanId: [scan.id],
        });
      });
  }, [api.scan, patientId, recording, scanId, scanVersion, setURIParams]);

  const panoramaURIs = useMemo(() => {
    return SIDES.flatMap((side) => {
      return CAMERAS.map((cameraName) => {
        const uri = new PanoramaImageURI(
          deviceId,
          recordingId,
          side,
          cameraName
        );

        return uri;
      });
    });
  }, [recordingId, deviceId]);

  const thermalURIs = useThermalURIs({
    deviceId: deviceId,
    recordingId: recordingId,
    cameras: THERMAL_CAMERAS,
  });

  const activeIndex = useMemo(() => {
    const { camera, side } = uriParams;

    const activeSide = side[0] || SIDES[0];
    const activeCamera = camera[0] || CAMERAS[0];

    return (
      panoramaURIs.findIndex((uri) => {
        return uri.cameraName === activeCamera && activeSide === uri.side;
      }) ?? 0
    );
  }, [panoramaURIs, uriParams]);

  if (!patient) {
    return <MessagePage>Looking for Member</MessagePage>;
  }

  if (!recording) {
    return <MessagePage>Fetching recording</MessagePage>;
  }

  if (!scan) {
    return <MessagePage>Fetching scan</MessagePage>;
  }

  const isDetailsViewActive = viewState === ViewState.Explore;
  const isAssessmentViewActive = viewState === ViewState.Assessment;

  return (
    <ViewStack>
      <PageFrame>
        <ScanOverview
          patient={patient}
          recording={recording}
          annotations={annotations}
          detectedAnnotations={detectedAnnotations}
          panoramaURIs={panoramaURIs}
          thermalURIs={thermalURIs || []}
          selectedURI={panoramaURIs[activeIndex]}
          scan={scan}
          onSelect={handleActivateURI}
          onEnter={() => setViewState(ViewState.Explore)}
          onAssess={() => setViewState(ViewState.Assessment)}
        />
      </PageFrame>

      <Slide direction={[0, 1]} active={isDetailsViewActive}>
        <ViewSwitcher activeIndex={activeIndex}>
          {panoramaURIs.map((uri, index) => {
            const offset = Math.abs(index - activeIndex);
            const key = uri.toString();

            if (offset > PRELOAD_WIDTH) {
              return <div key={key} />;
            }

            return (
              <ScanExplorer
                key={key}
                patient={patient}
                recording={recording}
                annotations={annotations}
                detectedAnnotations={detectedAnnotations}
                reloadAnnotations={reloadEntries}
                side={uri.side}
                cameraName={uri.cameraName}
                onExit={() => {
                  setViewState(ViewState.Overview);
                  reloadEntries();
                }}
              />
            );
          })}
        </ViewSwitcher>
      </Slide>

      <PhysicalArtifactExplorer
        annotations={annotations}
        reloadAnnotations={reloadEntries}
      />

      <SkinAssessment
        active={isAssessmentViewActive}
        scan={scan}
        onClose={() => {
          setViewState(ViewState.Overview);
          fetchScan();
        }}
      />
    </ViewStack>
  );
}
