import { useMemo } from "react";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import ValueItem from "render/ui/symbol/ValueItem";
import InfoPanel from "../InfoPanel";
import MainUSPItem from "../MainUSPItem";
import ValueBox from "../ValueBox";
import ECGIcon from "./assets/ecg.svg?react";
import styles from "./styles.module.sass";

function useBloodpressureCount(metrics: PatientMetrics["cardio"]) {
  const subjects = [
    metrics.anklePressure.left,
    metrics.anklePressure.right,
    metrics.brachialPressure.left,
    metrics.brachialPressure.right,
    metrics.toePressure.left,
    metrics.toePressure.right,
  ];

  return useMemo(() => {
    return subjects.filter((s) => s?.length).length;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, subjects);
}

function useBloodfatsCount(metrics: PatientMetrics["bloodwork"]) {
  const subjects = [
    metrics.cholesterol,
    metrics.cholesterolHDL,
    metrics.ldl,
    metrics.triglycerides,
    metrics.nonHDL,
    metrics.hdl,
  ];

  return useMemo(() => {
    return subjects.filter((s) => s?.length).length;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, subjects);
}

export default function HeartPanel() {
  const { metrics } = usePatientData();

  const bloodPressureCount = useBloodpressureCount(metrics.cardio);
  const bloodFatsCount = useBloodfatsCount(metrics.bloodwork);

  const { ui } = useDashboardContext();

  return (
    <div className={styles.HeartPanel}>
      <InfoPanel
        onClick={() => {
          ui.set({ layer: Layer.Cardio });
        }}
        align="left"
        caption={
          <>
            <h3>Heart & Circulation</h3>
            <MainUSPItem value={10} unit="MEASURING POINTS" />
          </>
        }
      >
        <div className={styles.values}>
          <ValueBox
            caption="Pulse waves"
            value={<ValueItem value={360} unit="s" />}
          />

          <ValueBox
            caption="Heart sound"
            value={<ValueItem value={120} unit="s" />}
          />

          <ValueBox caption="ECG" value={<ECGIcon />} />

          <ValueBox caption="Pulse rates" value={<ValueItem value={7} />} />

          <ValueBox
            caption="Blood fats"
            value={<ValueItem value={bloodFatsCount} />}
          />

          <ValueBox
            caption="Blood pressures"
            value={<ValueItem value={bloodPressureCount} />}
          />
        </div>
      </InfoPanel>
    </div>
  );
}
