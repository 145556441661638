import { Sex } from "@cur8/rich-entity";
import {
  AgeBinsType,
  getAgeBinForAge,
  getFutureAgeGroups,
} from "render/pages/DashboardPage/lib/projection/age";
import distributionModel from "render/pages/DashboardPage/lib/projection/assets/hba1c_blood_pressure_grip_strength_distribution_model.json";
import {
  cpvToDecile,
  cpvToQuartile,
} from "render/pages/DashboardPage/lib/projection/math";
import {
  SexBinsType,
  mapSexToModel,
} from "render/pages/DashboardPage/lib/projection/sex";

function hba1cToCpv(hba1cValue: number, sex: SexBinsType, age: number): number {
  // Get age group for given age
  const ageGroup = getAgeBinForAge(age);

  // Find distribution arrays from data model
  const distributionArrays = distributionModel[sex][ageGroup]["hba1c"];

  // Extract distribution arrays
  const binCenters = distributionArrays["bin_centers"];
  const cdf = distributionArrays["cdf_values"];

  // Compute what bin the given hba1c value belongs to
  const differences = binCenters.map((value) => Math.abs(value - hba1cValue));
  const binCenterIdx = differences.indexOf(Math.min(...differences));

  if (binCenterIdx !== -1) {
    const binCenterValue = binCenters[binCenterIdx];

    // Get the cumulative probability value by indexing the cdf_values array
    const c = cdf[binCenterIdx];

    return c;
  } else {
    // Handle the case where no bin center index was found
    throw new Error("No matching bin center found for the given HbA1c value.");
  }
}

interface FutureHba1cValues {
  qMeanHba1c: number;
  dMeanHba1c: number;
  binCenterValue: number;
}

function cpvToFutureHba1cValue(
  cpv: number,
  sex: SexBinsType,
  ageGroup: AgeBinsType
): FutureHba1cValues {
  // Find distribution arrays from data model
  const distributionArrays = distributionModel[sex][ageGroup]["hba1c"];

  // Extract distribution arrayss
  const binCenters: number[] = distributionArrays["bin_centers"];
  const pdf: number[] = distributionArrays["pdf_values"];
  const cdf: number[] = distributionArrays["cdf_values"];

  // 1)
  // Assuming we work with quartiles (i.e., Q1, Q2, Q3, and Q4), map the cpv to a quartile
  const [qStart, qStop] = cpvToQuartile(cpv);
  // Find indices where the cdf array contains cpv in the same quartile
  const qIndices: number[] = [];
  for (let i = 0; i < cdf.length; i++) {
    if (qStart <= cdf[i] && cdf[i] <= qStop) {
      qIndices.push(i);
    }
  }
  const qSumWeights: number = qIndices.reduce(
    (sum, index) => sum + pdf[index],
    0
  );
  const qMeanHba1c: number = qIndices.reduce(
    (sum, index) => sum + (binCenters[index] * pdf[index]) / qSumWeights,
    0
  );

  // 2)
  // Assuming we work with deciles, map the cpv to a decile
  const { decileStart: dStart, decileStop: dStop } = cpvToDecile(cpv);
  // Find indices where the cdf array contains cpv in the same decile
  let dIndices: number[] = [];
  for (let i = 0; i < cdf.length; i++) {
    if (dStart <= cdf[i] && cdf[i] <= dStop) {
      dIndices.push(i);
    }
  }

  if (dIndices.length === 0) {
    const diffStart = cdf.map((value) => Math.abs(value - dStart));
    const diffStop = cdf.map((value) => Math.abs(value - dStop));

    // Find the index of the closest value to either bound
    const minDifferences = diffStart.map((value, index) =>
      Math.min(value, diffStop[index])
    );
    const minDifferenceIndex = minDifferences.indexOf(
      Math.min(...minDifferences)
    );

    dIndices = [minDifferenceIndex];
  }

  const dSumWeights: number = dIndices.reduce(
    (sum, index) => sum + pdf[index],
    0
  );
  const dMeanHba1c: number = dIndices.reduce(
    (sum, index) => sum + (binCenters[index] * pdf[index]) / dSumWeights,
    0
  );

  // 3)
  // Get the index of the cumulative probability value in this distribution and find bin center value
  const differences: number[] = cdf.map((value) => Math.abs(value - cpv));
  const cpvIdx: number = differences.indexOf(Math.min(...differences));

  // Get the bin center value
  const binCenterValue: number = binCenters[cpvIdx];

  return {
    qMeanHba1c,
    dMeanHba1c,
    binCenterValue,
  };
}

function getPercentileMean(
  sex: SexBinsType,
  age: number,
  pStart: number,
  pStop: number
): number {
  const ageGroup = getAgeBinForAge(age);
  // Find distribution arrays from data model
  const distributionArrays = distributionModel[sex][ageGroup]["hba1c"];

  // Extract distribution arrays
  const binCenters = distributionArrays["bin_centers"];
  const pdf = distributionArrays["pdf_values"];
  const cdf = distributionArrays["cdf_values"];

  // Find indices where the cdf array contains cpv in the same quartile
  const indices = [];
  for (let i = 0; i < cdf.length; i++) {
    if (pStart <= cdf[i] && cdf[i] <= pStop) {
      indices.push(i);
    }
  }

  const sumWeights: number = indices.reduce(
    (sum, index) => sum + pdf[index],
    0
  );
  const meanHba1c: number = indices.reduce(
    (sum, index) => sum + (binCenters[index] * pdf[index]) / sumWeights,
    0
  );

  return meanHba1c;
}

export function getProjection(
  hba1c: number,
  age: number,
  sex: Sex,
  maxProjectionAge?: number
) {
  const bestHba1c = getPercentileMean(mapSexToModel(sex), age, 0, 0.1);
  const worstHba1c = getPercentileMean(mapSexToModel(sex), age, 0.9, 1);

  const cpvValue = hba1cToCpv(hba1c, mapSexToModel(sex), age);
  const bestCpvValue = hba1cToCpv(bestHba1c, mapSexToModel(sex), age);
  const worstCpvValue = hba1cToCpv(worstHba1c, mapSexToModel(sex), age);

  try {
    let futureAgeGroups = getFutureAgeGroups(age);
    if (maxProjectionAge) {
      const maxAgeGroup = getAgeBinForAge(maxProjectionAge);
      const maxAgeGroupIndex = futureAgeGroups.indexOf(maxAgeGroup);
      futureAgeGroups = futureAgeGroups.slice(0, maxAgeGroupIndex + 1);
    }
    console.log(`Future age groups: ${futureAgeGroups}`);

    const centerHba1cBinValues = [hba1c];
    const bestHba1cValues = [bestHba1c];
    const worstHba1cValues = [worstHba1c];

    // Traverse future age groups and compute estimated hba1c for each age group
    for (const newAgeGroup of futureAgeGroups) {
      const { dMeanHba1c: decileHba1cValue } = cpvToFutureHba1cValue(
        cpvValue,
        mapSexToModel(sex),
        newAgeGroup
      );
      const { dMeanHba1c: bestHba1cDecileValue } = cpvToFutureHba1cValue(
        bestCpvValue,
        mapSexToModel(sex),
        newAgeGroup
      );
      const { dMeanHba1c: worstHba1cDecileValue } = cpvToFutureHba1cValue(
        worstCpvValue,
        mapSexToModel(sex),
        newAgeGroup
      );

      centerHba1cBinValues.push(decileHba1cValue);
      bestHba1cValues.push(bestHba1cDecileValue);
      worstHba1cValues.push(worstHba1cDecileValue);
    }

    return {
      centerHba1cBinValues,
      bestHba1cValues,
      worstHba1cValues,
    };
  } catch (e) {
    return {
      centerHba1cBinValues: [],
      bestHba1cValues: [],
      worstHba1cValues: [],
    };
  }
}

export function getBenchmarkCpvFor(
  currentHba1cValue: number,
  age: number,
  sex: Sex
) {
  return hba1cToCpv(currentHba1cValue, mapSexToModel(sex), age);
}

export function getBenchmarkMedianFor(age: number, sex: Sex) {
  const ageGroup = getAgeBinForAge(age);
  const sexGroup = mapSexToModel(sex);
  // Find distribution arrays from data model
  const distributionArrays = distributionModel[sexGroup][ageGroup]["hba1c"];

  // Extract distribution arrays
  const binCenters: number[] = distributionArrays["bin_centers"];
  const cdf: number[] = distributionArrays["cdf_values"];

  // Find bin center value for cpv=0.5
  const closestIndex = cdf.reduce((prevIndex, value, currentIndex) => {
    const prevValue = cdf[prevIndex];
    return Math.abs(value - 0.5) < Math.abs(prevValue - 0.5)
      ? currentIndex
      : prevIndex;
  }, 0);

  return binCenters[closestIndex];
}
