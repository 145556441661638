import { BrachialPressure } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { TBIScore } from "render/hooks/api/metrics/useTBIScore";
import { useAge } from "render/hooks/patient/useAge";
import MetricStatus from "render/ui/presentation/MetricStatus";
import MetricStatusBadge from "render/ui/presentation/MetricStatusBadge";
import BloodpressureIcon from "./assets/bloodpressure.svg?react";

interface BloodPressureItemProps {
  patient: Patient;
  brachialPressure:
    | Metric<"cardio.brachial_pressure.left">
    | Metric<"cardio.brachial_pressure.right">
    | undefined;
  previousBrachialPressure:
    | Metric<"cardio.brachial_pressure.left">
    | Metric<"cardio.brachial_pressure.right">
    | undefined;
  tbi: TBIScore | undefined;
}

export default function BloodPressureItem({
  patient,
  brachialPressure,
  previousBrachialPressure,
}: BloodPressureItemProps) {
  const age = useAge(patient);

  const summary = useMemo(() => {
    if (brachialPressure) {
      const { systolic, diastolic } = brachialPressure.unit;
      return {
        risk: BrachialPressure.riskFor({
          systolic,
          diastolic,
          age,
        }),
      };
    }
  }, [age, brachialPressure]);

  const differenceToPreviousValue = useMemo(() => {
    if (!brachialPressure || !previousBrachialPressure) {
      return undefined;
    }

    return Number(
      (
        brachialPressure.unit.systolic.mmHg -
        previousBrachialPressure.unit.systolic.mmHg
      ).toFixed(0)
    );
  }, [brachialPressure, previousBrachialPressure]);

  return (
    <MetricStatus
      caption="Blood Pressure"
      summaryContent={
        summary ? <MetricStatusBadge risk={summary.risk} /> : "--"
      }
      differenceToPreviousValue={differenceToPreviousValue}
      unit="mmHg"
    >
      <BloodpressureIcon />
    </MetricStatus>
  );
}
