import FeedbackIcon from "render/ui/layouts/PageFrame/components/Sidebar/components/FeedbackIcon/FeedbackIcon";
import HoverTextButton from "render/ui/trigger/HoverTextButton";

import styles from "./style.module.sass";

interface FeedbackProps {
  onLike: (like: boolean) => void;
  onReportProblem: () => void;
}

export default function Feedback({ onLike, onReportProblem }: FeedbackProps) {
  return (
    <div className={styles.Feedback}>
      <div className={styles.like}>
        <div>What do you think about the generated summary?</div>
        <>
          <HoverTextButton onClick={() => onLike(true)}>
            👍 Great
          </HoverTextButton>
          <HoverTextButton onClick={() => onLike(false)}>
            👎Could be better
          </HoverTextButton>
        </>
        <HoverTextButton onClick={onReportProblem}>
          <div className={styles.feedback}>
            <FeedbackIcon /> Feedback
          </div>
        </HoverTextButton>
      </div>
    </div>
  );
}
