import { fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "../useReporting";

export function useImmutableScanQuery(
  patientId: string,
  scanId?: string,
  scanVersion?: string
) {
  const { handleError } = useReporting();

  const api = useAPIClient().scan;

  const { get, set } = useStore().scan.entries;

  const storeKey = useMemo(
    () => (scanId && scanVersion ? scanId + scanVersion : `${Date.now()}`),
    [scanId, scanVersion]
  );

  const fetch = useCallback(() => {
    if (!scanId || !scanVersion) return;

    return api
      .fetchImmutableScan({ patientId, scanId, scanVersion })
      .result.then(fromAPI.toImmutableScan)
      .then((scan) => {
        set(storeKey, scan);
      })
      .catch(handleError);
  }, [scanId, scanVersion, api, patientId, handleError, set, storeKey]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return useMemo(() => {
    return {
      scan: scanId && scanVersion ? get(storeKey).data : null,
      fetch,
    };
  }, [fetch, get, storeKey, scanId, scanVersion]);
}
