import { Sex } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import BenchmarkResultDescription from "../BenchmarkResultDescription";
import styles from "./styles.module.sass";

interface HeadeProps {
  sex: Sex;
  age: number;
  metrics: Metric<"bloodwork.hba1c">[];
}
export default function Header({ metrics, sex, age }: HeadeProps) {
  const currentScan = metrics[0];
  const currentValue = currentScan?.unit["mmol/mol"];

  return (
    <div className={styles.Header}>
      <div className={styles.titles}>
        <h3>Benchmark</h3>
        <span className={styles.aux}>[HbA1c]</span>
      </div>
      <div className={styles.subtitle}>
        <BenchmarkResultDescription
          sex={sex}
          age={age}
          currentValue={currentValue}
        />
      </div>
    </div>
  );
}
