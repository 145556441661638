import { MemberSummary, Transcription } from "lib/doctor-scribe/types";
import { TransSumPair } from "./hooks/useTranscriptionSummaryPair";

export const EMPTY: TransSumPair = {
  language: "sv",
  transcriptionSummary: "",
  results: undefined,
  instruction: "",
  prompt: "",
  temp: 0,
  generatedSummary: "",
  savedSummary: "",
  readTime: 0,
  isLiked: undefined,
};

export const EMPTY_TRANSCRIPTION: Transcription = {
  text: "",
  text_tokens: 0,
  anonymized: "",
  anonymized_tokens: 0,
  language: "sv",
};

export const EMPTY_MEMBER_SUMMARY: MemberSummary = {
  timestamp: "",
  transcription: EMPTY_TRANSCRIPTION,
  patient_record: null,
  summary: "",
  instruction: "",
  prompt: "",
};

export const BinaryResponse = [
  { value: false, content: "No" },
  { value: true, content: "Yes" },
];
