import { fromAPI, Visit } from "@cur8/rich-entity";
import { useCallback, useEffect } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import PopupDialog from "render/ui/layouts/PopupDialog";
import PopupDialogContent from "render/ui/layouts/PopupDialogContent";
import ActionButton from "render/ui/trigger/ActionButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";

interface Props {
  visit: Visit;
  onDone(visit: Visit): void;
  onCancel(): void;
}

export default function CheckInVisitPopup({ visit, onDone, onCancel }: Props) {
  const { patientId, visitId, checkinStartTimestamp } = visit;
  const apiClient = useAPIClient();
  const { logError } = useReporting();

  useEffect(() => {
    function keyDownHandler(ev: KeyboardEvent) {
      if (ev.key === "Escape") {
        onCancel();
      }
    }

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [onCancel]);

  const checkInHandler = useCallback(async () => {
    try {
      if (!checkinStartTimestamp)
        await apiClient.visit.startCheckInProcess({ patientId, visitId })
          .result;
      const result = await apiClient.visit
        .endCheckInProcess({ patientId, visitId })
        .result.then(fromAPI.toVisit);
      onDone(result);
    } catch (e) {
      logError(e);
    }
  }, [
    apiClient.visit,
    logError,
    onDone,
    patientId,
    visitId,
    checkinStartTimestamp,
  ]);

  const handleCheckIn = useAsyncHandle(checkInHandler);
  const disabled = handleCheckIn.busy;

  return (
    <PopupDialog>
      <PopupDialogContent
        head={
          <h2>
            <Text visit={visit} />
          </h2>
        }
        body={<p>Would you like to continue?</p>}
        buttons={
          <>
            <HoverTextButton type="button" onClick={onCancel}>
              Cancel
            </HoverTextButton>
            <ActionButton handle={handleCheckIn} disabled={disabled}>
              <Text visit={visit} />
            </ActionButton>
          </>
        }
      />
    </PopupDialog>
  );
}

function Text({ visit }: { visit: Visit }) {
  if (!visit.checkinStartTimestamp) return <>Check in</>;
  if (!visit.checkinEndTimestamp) return <>Finish check in</>;
  return <>Assign to room</>;
}
