import { NonHDL } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { isScore2HighRisk } from "lib/scores/score2";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientHealthData } from "render/hooks/patient/usePatientHealthData";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface NonHDLRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.non_hdl">[];
}

export default function NonHDLRange({ patient, metrics }: NonHDLRangeProps) {
  const age = useAge(patient);
  const { aggregates } = usePatientHealthData(patient.patientId);

  const isScore2WithHighRisk = aggregates.score2?.score
    ? isScore2HighRisk(age, aggregates.score2?.score)
    : false;

  const riskRange = useMemo(() => {
    return NonHDL.rangesFor({
      sex: patient.sex,
      age,
      isScore2HighRisk: isScore2WithHighRisk,
    });
  }, [patient.sex, age, isScore2WithHighRisk]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  return (
    <MetricRange
      title="Non-HDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
