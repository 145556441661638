import { getSelected } from "lib/lesion";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import RampUpNumber from "render/ui/format/RampUpNumber";
import TrackedLesionsImagePool from "./components/TrackedLesionsImagePool";
import styles from "./styles.module.sass";

export default function TrackedSection({ active }: { active: boolean }) {
  const {
    skin: { lesions },
  } = usePatientData();

  const presentable = useMemo(() => {
    if (!lesions) {
      return [];
    }

    return getSelected(lesions);
  }, [lesions]);

  return (
    <div className={styles.TrackedSection}>
      <div className={styles.caption}>
        <h2>Selected</h2> [
        <RampUpNumber
          value={presentable ? presentable.length : 0}
          duration={1}
          precision={0}
        />
        ]
      </div>

      <TrackedLesionsImagePool active={active} lesions={presentable} />
    </div>
  );
}
