import { Weight } from "@cur8/measurements";
import { UnitConverter, UnitSystem } from "./UnitConverter";

export function toPounds(force: Weight) {
  return {
    lbs: force.kilograms * 2.20462262185,
  };
}

export const Converters: Record<
  UnitSystem,
  UnitConverter<"kilograms" | "lbs">
> = {
  metric: new UnitConverter({
    unit: "kilograms",
  }),
  imperial: new UnitConverter({
    unit: "lbs",
  }),
};
