import { createQuery } from "@pomle/paths";
import { codecs } from "./codec";

const panorama = createQuery({
  camera: codecs.string,
  side: codecs.side,
});

const schedule = createQuery({
  from: codecs.date,
  to: codecs.date,
  assignPatientId: codecs.string,
  censorAppointments: codecs.boolean,
  slotManagement: codecs.boolean,
  roomId: codecs.string,
});

const atlas = createQuery({
  camera: codecs.string,
  side: codecs.side,
  view: codecs.string,
  selectedAnnotation: codecs.string,
  scanId: codecs.string,
  scanVersion: codecs.string,
});

const skinCRM = createQuery({
  tab: codecs.string,
});

export const query = {
  atlas,
  panorama,
  schedule,
  skinCRM,
};
