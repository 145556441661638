import { Patient } from "@cur8/rich-entity";
import BasophilsRange from "render/fragments/metric/range-charts/BasophilsRange";
import CRPRange from "render/fragments/metric/range-charts/CRPRange";
import EosinophilsRange from "render/fragments/metric/range-charts/EosinophilsRange";
import HDLRange from "render/fragments/metric/range-charts/HDLRange";
import HaemoglobinRange from "render/fragments/metric/range-charts/HaemoglobinRange";
import HbA1cRange from "render/fragments/metric/range-charts/HbA1cRange";
import LDLRange from "render/fragments/metric/range-charts/LDLRange";
import LymphocytesRange from "render/fragments/metric/range-charts/LymphocytesRange";
import NeutrophilsRange from "render/fragments/metric/range-charts/NeutrophilsRange";
import NonHDLRange from "render/fragments/metric/range-charts/NonHDLRange";
import TotalCholesterolRange from "render/fragments/metric/range-charts/TotalCholesterolRange";
import TriglyceridesRange from "render/fragments/metric/range-charts/TriglyceridesRange";
import WhiteBloodCellsRange from "render/fragments/metric/range-charts/WhiteBloodCellsRange";

import GlucoseRange from "render/fragments/metric/range-charts/GlucoseRange";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import MetricValueChangeToggler from "../MetricValueChangeToggler";
import styles from "./styles.module.sass";

interface BloodworkSectionProps {
  patient: Patient;
}

export default function BloodworkSection({ patient }: BloodworkSectionProps) {
  const {
    metrics: { bloodwork },
    aggregates: { score2 },
  } = usePatientData();

  return (
    <div className={styles.BloodworkSection}>
      <h2>Bloodwork</h2>

      <div className={styles.metricGroups}>
        <div className={styles.metricGroup}>
          {!!bloodwork.cholesterol && (
            <TotalCholesterolRange
              patient={patient}
              metrics={bloodwork.cholesterol.slice(0, 2)}
            />
          )}

          {!!bloodwork.hdl && (
            <HDLRange patient={patient} metrics={bloodwork.hdl.slice(0, 2)} />
          )}

          {!!bloodwork.nonHDL && (
            <NonHDLRange
              patient={patient}
              metrics={bloodwork.nonHDL.slice(0, 2)}
            />
          )}

          {!!bloodwork.triglycerides && (
            <TriglyceridesRange metrics={bloodwork.triglycerides.slice(0, 2)} />
          )}

          {!!bloodwork.ldl && score2 && (
            <LDLRange
              patient={patient}
              metrics={bloodwork.ldl.slice(0, 2)}
              score2={score2}
            />
          )}
        </div>

        <div className={styles.vl} />

        <div className={styles.metricGroup}>
          {!!bloodwork.whiteBloodCells && (
            <MetricValueChangeToggler
              metrics={bloodwork.whiteBloodCells}
              content={(metrics) => <WhiteBloodCellsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.neutrophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.neutrophils}
              content={(metrics) => <NeutrophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.basophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.basophils}
              content={(metrics) => <BasophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.eosinophils && (
            <MetricValueChangeToggler
              metrics={bloodwork.eosinophils}
              content={(metrics) => <EosinophilsRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.lymphocytes && (
            <MetricValueChangeToggler
              metrics={bloodwork.lymphocytes}
              content={(metrics) => <LymphocytesRange metrics={metrics} />}
            />
          )}
        </div>

        <div className={styles.vl} />

        <div className={styles.metricGroup}>
          {!!bloodwork.glucose && (
            <MetricValueChangeToggler
              metrics={bloodwork.glucose}
              content={(metrics) => <GlucoseRange metrics={metrics} />}
            />
          )}

          {!!bloodwork.hba1c && (
            <HbA1cRange
              patient={patient}
              metrics={bloodwork.hba1c.slice(0, 2)}
            />
          )}

          {!!bloodwork.haemoglobin && (
            <HaemoglobinRange
              patient={patient}
              metrics={bloodwork.haemoglobin.slice(0, 2)}
            />
          )}

          {!!bloodwork.crp && <CRPRange metrics={bloodwork.crp.slice(0, 2)} />}
        </div>
      </div>
    </div>
  );
}
