import { BrachialPressure } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { BodySide } from "lib/types/body";
import { useState } from "react";
import { useABIScore } from "render/hooks/api/metrics/useABIScore";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import SectionHeader from "../../../../components/SectionHeader";
import Toggle, { ToggleItem } from "../../../../components/Toggle";
import { BloodPressureType } from "../../lib/projection";
import ArterialFlowResultItem from "../ArterialFlowResultItem";
import BloodPressureBenchmark from "../BloodPressureBenchmark";
import BloodPressureResultItem from "../BloodPressureResultItem";
import Header from "../Header";
import styles from "./styles.module.sass";

interface CirculationSectionProps {
  patient: Patient;
  side: BodySide;
  showBenchmark?: boolean;
}

const TileForSide: Record<BodySide, string> = {
  left: "Left",
  right: "Right",
};

const TitleForBloodPressureType: Record<BloodPressureType, string> = {
  systolic_blood_pressure: "SYSTOLIC",
  diastolic_blood_pressure: "DIASTOLIC",
};

const MetricTypeForBloodPressureType: Record<
  BloodPressureType,
  keyof BrachialPressure
> = {
  diastolic_blood_pressure: "diastolic",
  systolic_blood_pressure: "systolic",
};

export default function CirculationSection({
  patient,
  side,
  showBenchmark = true,
}: CirculationSectionProps) {
  const {
    metrics: { cardio },
  } = usePatientData();

  const bloodPressureType: BloodPressureType = "systolic_blood_pressure";
  const [activeSection, setActiveSection] = useState<"benchmark" | "results">(
    "results"
  );

  const bloodPressureValue =
    cardio.brachialPressure[side]?.at(0)?.unit[
      MetricTypeForBloodPressureType[bloodPressureType]
    ].mmHg;

  const prevBloodPressureValue =
    cardio.brachialPressure[side]?.at(1)?.unit[
      MetricTypeForBloodPressureType[bloodPressureType]
    ].mmHg;

  const patientAge = useAge(patient);

  const currentAbiValue = useABIScore(side, cardio);

  const prevBrachialLeft = cardio.brachialPressure["left"]?.at(1)
    ? [cardio.brachialPressure["left"]?.at(1)!]
    : undefined;
  const prevBrachialRight = cardio.brachialPressure["right"]?.at(1)
    ? [cardio.brachialPressure["right"]?.at(1)!]
    : undefined;

  const prevAnkleLeft = cardio.anklePressure["left"]?.at(1)
    ? [cardio.anklePressure["left"]?.at(1)!]
    : undefined;
  const prevAnkleRight = cardio.anklePressure["right"]?.at(1)
    ? [cardio.anklePressure["right"]?.at(1)!]
    : undefined;

  const prevCardio = {
    brachialPressure: {
      left: prevBrachialLeft,
      right: prevBrachialRight,
    },
    anklePressure: {
      left: prevAnkleLeft,
      right: prevAnkleRight,
    },
  };
  const prevAbiValue = useABIScore(side, prevCardio);

  return (
    <div className={styles.CirculationSection}>
      <SectionHeader
        header={
          <>
            <h2>{TileForSide[side]}</h2>
            <hr />
          </>
        }
        toggle={
          showBenchmark ? (
            <Toggle>
              <ToggleItem
                isActive={activeSection === "results"}
                onClick={() => setActiveSection("results")}
              >
                Results
              </ToggleItem>
              <ToggleItem
                isActive={activeSection === "benchmark"}
                onClick={() => setActiveSection("benchmark")}
              >
                Benchmark
              </ToggleItem>
            </Toggle>
          ) : null
        }
      />

      {bloodPressureValue && activeSection === "benchmark" && (
        <>
          <SectionHeader
            header={
              <Header
                title="Benchmark"
                subtitle={TitleForBloodPressureType[bloodPressureType]}
                age={patientAge}
                bloodPressureType={bloodPressureType}
                currentValue={bloodPressureValue}
                sex={patient.sex}
              />
            }
          />
          <BloodPressureBenchmark
            bloodPressureType={bloodPressureType}
            age={patientAge}
            sex={patient.sex}
            currentValue={bloodPressureValue}
          />
        </>
      )}

      {activeSection === "results" && (
        <div className={styles.ResultContainer}>
          {bloodPressureValue && (
            <BloodPressureResultItem
              patient={patient}
              currentValue={bloodPressureValue}
              previousValue={prevBloodPressureValue}
              bloodPressureType={bloodPressureType}
            />
          )}
          {currentAbiValue && (
            <ArterialFlowResultItem
              currentValue={currentAbiValue?.score}
              previousValue={prevAbiValue?.score}
            />
          )}
        </div>
      )}
    </div>
  );
}
