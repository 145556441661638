import {
  APIContextOptions,
  createAPIContext,
  createAnnotationAPI,
  createAssignmentAPI,
  createAuditAPI,
  createBillingAPIv1,
  createBillingAPIv2,
  createBlobAPI,
  createBookingAPIv2,
  createConsentAPIv1,
  createIdentityAPI,
  createMeasurementsAPI,
  createMeshugaAPI,
  createNLPAPI,
  createNotificationAPI,
  createPatientAPI,
  createPhysicalArtefactAPI,
  createQuestionnaireAPI,
  createRecordingAPI,
  createRoomAPI,
  createScanAPI,
  createSiteAPI,
  createSkinBookAPI,
  createTranscodeAPI,
  createUserAPI,
  createVisitAPI,
  createVisitSummaryAPI,
} from "@cur8/api-client";

export function createClient(options: APIContextOptions) {
  const context = createAPIContext(options);

  return {
    sites: createSiteAPI(context),
    annotation: createAnnotationAPI(context),
    audit: createAuditAPI(context),
    blob: createBlobAPI(context),
    billing: createBillingAPIv1(context),
    billingV2: createBillingAPIv2(context),
    bookingV2: createBookingAPIv2(context),
    consent: createConsentAPIv1(context),
    identity: createIdentityAPI(context),
    measurement: createMeasurementsAPI(context),
    meshuga: createMeshugaAPI(context),
    nlp: createNLPAPI(context),
    notification: createNotificationAPI(context),
    patient: createPatientAPI(context),
    physicalArtefact: createPhysicalArtefactAPI(context),
    recording: createRecordingAPI(context),
    scan: createScanAPI(context),
    transcode: createTranscodeAPI(context),
    user: createUserAPI(context),
    visit: createVisitAPI(context),
    visitSummary: createVisitSummaryAPI(context),
    assignment: createAssignmentAPI(context),
    skinBook: createSkinBookAPI(context),
    questionnaire: createQuestionnaireAPI(context),
    room: createRoomAPI(context),
  };
}

export type APIClient = ReturnType<typeof createClient>;
