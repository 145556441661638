import { Patient } from "@cur8/rich-entity";
import { useAge } from "render/hooks/patient/useAge";
import styles from "./styles.module.sass";

interface PatientIdentityProps {
  patient?: Patient;
}

export default function PatientIdentity({ patient }: PatientIdentityProps) {
  const age = useAge(patient);

  return (
    <div className={styles.PatientIdentity}>
      <div className={styles.name} data-ready={!!patient}>
        {patient?.name?.displayName ?? "--"}
      </div>

      <div className={styles.age} data-ready={!!age}>
        {age} years
      </div>
    </div>
  );
}
