import { Context } from "./context";
import { useAudioRecordingState } from "./hooks/useAudioRecordingState";

interface AudioRecordingContextProps {
  children: React.ReactNode;
}

export default function AudioRecordingContext({
  children,
}: AudioRecordingContextProps) {
  const value = useAudioRecordingState();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
