import { Risk } from "@cur8/health-risks-calc";
import styles from "./styles.module.sass";

interface FixedAvatarValueProps {
  label: React.ReactNode;
  value: React.ReactNode;
  risk: Risk;
  align?: "left" | "right";
}

type Variant = "normal" | "monitor";

const VariantMap: Record<Risk, Variant> = {
  [Risk.Unknown]: "monitor",
  [Risk.Optimal]: "normal",
  [Risk.Normal]: "normal",
  [Risk.Risk]: "monitor",
  [Risk.HighRisk]: "monitor",
  [Risk.ImmediateRisk]: "monitor",
};

const LabelMap: Record<Risk, string> = {
  [Risk.Unknown]: "Unknown",
  [Risk.Optimal]: "Optimal",
  [Risk.Normal]: "Normal",
  [Risk.Risk]: "Monitor",
  [Risk.HighRisk]: "Monitor",
  [Risk.ImmediateRisk]: "Monitor",
};

export default function FixedAvatarValue({
  label,
  value,
  risk,
  align = "left",
}: FixedAvatarValueProps) {
  return (
    <div className={styles.FixedAvatarValue} data-align={align}>
      {value}
      <label className={styles.label}>{label}</label>
      {risk && (
        <div className={styles.risk} data-variant={VariantMap[risk]}>
          {LabelMap[risk]}
        </div>
      )}
    </div>
  );
}
