import { MetricName } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { queryMetrics } from "lib/api/measurement";
import { Result } from "lib/doctor-scribe/types";
import { fetchLatestOnboardingQuestionnaireResponse } from "lib/questionnaire/api";
import { metricNames } from "./metrics";
import { refineRecord } from "./refine";
import {
  computeAggregates,
  getResultRecord,
  getResults,
  mergeRecords,
  notEmpty,
  uniqueByDay,
} from "./resultHelpers";

export async function fetchResults(api: APIClient, patient: Patient) {
  async function fetch(metricName: MetricName) {
    return await queryMetrics(api.measurement, {
      patientId: patient.patientId,
      pageSize: 3,
      metricName,
    });
  }

  const metrics = (
    await Promise.all(
      metricNames.map((metricName) => {
        return fetch(metricName);
      })
    )
  )
    .filter(notEmpty)
    .map(uniqueByDay);

  const results: Result[] = getResults(metrics, patient);
  const resultRecord = getResultRecord(results);
  const aggregates = computeAggregates(resultRecord, patient);
  const mergedRecords = mergeRecords(resultRecord, aggregates);

  const questionnaire = await fetchLatestOnboardingQuestionnaireResponse(
    api,
    patient.patientId
  ).result.catch((e) => {
    // Onboarding data is sometimes an empty string and getOnboarding throws an exception
    // This is to allow doctors to still use the summary feature etc.
    console.error("Onboarding data corrupt: ", e);
    return undefined;
  });

  return refineRecord(mergedRecords, patient, questionnaire);
}

type UnwrapPromise<T> = T extends Promise<infer U> ? U : T;
export type ResultType = UnwrapPromise<ReturnType<typeof fetchResults>>;
