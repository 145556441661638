import { APITypesV1 } from "@cur8/api-client";
import { CanvasImage } from "./CanvasImage";
import { AnnotationImage } from "./AnnotationImage";
import { Mode } from "../types";
import { useEffect, useState } from "react";
import styles from "./styles.module.sass";

interface LesionProps {
  annotations: APITypesV1.Annotation[];
  mode: Mode;
}

export default function Lesion({ annotations, mode }: LesionProps) {
  const [images, setImages] = useState<JSX.Element[]>([]);
  const [firstAnno, setFirstAnno] = useState<JSX.Element>();

  useEffect(() => {
    const unlinkedDermaLesions = annotations.filter((a) => {
      return a.applicationSpecificTarget === "derma:unlinked";
    });

    const panoramaAnnotations = annotations
      .filter((a) => {
        return a.applicationSpecificTarget?.startsWith("panorama");
      })
      .sort((a, b) => {
        return a.createdAt < b.createdAt ? -1 : 1;
      });

    if (panoramaAnnotations.length === 0 && unlinkedDermaLesions.length === 0)
      return;

    switch (mode) {
      case "derma":
        const dermaAnnotations = annotations
          .filter((a) => {
            return a.applicationSpecificTarget?.startsWith("derma");
          })
          .sort((a, b) => {
            return a.createdAt < b.createdAt ? -1 : 1;
          });

        setImages(
          dermaAnnotations.map((da) => {
            return (
              <AnnotationImage
                key={da.id}
                anno={da}
                isOverview={false}
                deletable={true}
                onDelete={() => {}}
              />
            );
          })
        );
        break;
      case "macro":
        setImages(
          panoramaAnnotations.map((da) => {
            return (
              <AnnotationImage
                key={da.id}
                anno={da}
                isOverview={false}
                deletable={true}
                onDelete={() => {}}
              />
            );
          })
        );
        break;
      case "overview":
        setImages(
          panoramaAnnotations.slice(1, panoramaAnnotations.length).map((da) => {
            return <CanvasImage key={da.id} anno={da} />;
          })
        );
    }

    let firstImage = <></>;

    if (unlinkedDermaLesions.length > 0) {
      firstImage =
        mode !== "derma" ? (
          <AnnotationImage
            key={unlinkedDermaLesions[0].id}
            anno={unlinkedDermaLesions[0]}
            isOverview={false}
            deletable={true}
            onDelete={() => {}}
          />
        ) : (
          <></>
        );
    } else {
      firstImage = <CanvasImage anno={panoramaAnnotations[0]} />;
    }

    setFirstAnno(firstImage);
  }, [mode, annotations]);

  return firstAnno ? (
    <div className={styles.Lesion}>
      {firstAnno}
      {images}
    </div>
  ) : null;
}
