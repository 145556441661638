import { APITypesV1 } from "@cur8/api-client";
import { FrameSequenceURI } from "@cur8/uri";
import { PanoramaImageURI } from "lib/api/uri";
import { loadImage } from "lib/loader";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

function parseUri(targetUri: string) {
  const tokens = targetUri.split(":");
  return { filenameAndPath: tokens.pop() || "", externalId: tokens[2] };
}

type OriginalSize = {
  w: number;
  h: number;
} | null;

export function useAnnotationImage(
  annotation: APITypesV1.Annotation,
  isOverview: boolean
) {
  const api = useAPIClient();
  const [image, setImage] = useState<HTMLImageElement>();
  const [originalSize, setOriginalSize] = useState<OriginalSize>();

  const { filenameAndPath, externalId } = parseUri(annotation.targetUri);

  useEffect(() => {
    async function fetchDermaImage(externalId: string, path: string) {
      const response = await api.blob.fetchPatientBlob({
        patientId: externalId,
        path,
      }).result;
      const blob = await response.blob();
      return { blob: blob, originalSize: null };
    }

    async function fetchRigImage() {
      if (annotation.applicationSpecificTarget?.startsWith("panorama:")) {
        return fetchPanoramaImage();
      } else if (annotation.applicationSpecificTarget?.startsWith("rgbino:")) {
        return fetchRgbinoImage();
      } else {
        console.error(
          "unknown applicationSpecificTarget in annotation: ",
          annotation
        );
      }
    }

    async function fetchRgbinoImage() {
      const uri = FrameSequenceURI.parse(annotation.targetUri);
      if (uri) {
        const rect = (annotation.data as APITypesV1.AnnotationData2D).rect;
        const { blob, meta } = await api.transcode.fetchFrame(
          {
            deviceId: uri.deviceId,
            recordingId: uri.recordingId,
            trackId: uri.trackId,
            frameId: uri.frameId,
          },
          isOverview
            ? undefined
            : {
                x: rect.left,
                y: rect.top,
                w: rect.width,
                h: rect.height,
              },
          undefined
        ).result;
        return { blob: blob, originalSize: meta.original.size };
      }
    }

    async function fetchPanoramaImage() {
      if (!annotation.applicationSpecificTarget) {
        console.error("no applicationSpecificTarget");
        return;
      }

      const rect = (annotation.data as APITypesV1.AnnotationData2D).rect;

      const uri = PanoramaImageURI.fromString(
        annotation.applicationSpecificTarget
      );
      const { blob, meta } = await api.transcode.fetchPanorama(
        {
          deviceId: uri.deviceId,
          recordingId: uri.recordingId,
          side: uri.side,
          cameraName: uri.cameraName,
        },
        isOverview
          ? undefined
          : {
              x: rect.left,
              y: rect.top,
              w: rect.width,
              h: rect.height,
            },
        { w: 1024, h: 1024 }
      ).result;

      return { blob: blob, originalSize: meta.original.size };
    }

    const annoImageFetch = annotation.targetUri.startsWith("cur8:recording")
      ? fetchRigImage
      : fetchDermaImage;

    annoImageFetch(externalId, filenameAndPath)
      .then(async (res) => {
        if (!res) throw new Error("Could not fetch image");
        const url = URL.createObjectURL(res.blob);
        const image = await loadImage(url);
        return { image: image, originalSize: res.originalSize };
      })
      .then((res) => {
        setImage(res.image);
        setOriginalSize(res.originalSize);
      })
      .catch(console.error);
  }, [api, externalId, filenameAndPath, annotation, isOverview]);

  return { image: image, originalSize: originalSize };
}
