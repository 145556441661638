import { createTimer } from "lib/timer";
import { useEffect } from "react";
import {
  AvatarPodium,
  PodiumView,
} from "render/pages/DashboardPage/context/DashboardContext/hooks/usePodium";
import { Layer } from "render/pages/DashboardPage/types";

enum Mode {
  Front,
  Profile,
  Swivel,
}

function toMode(layer: Layer) {
  if (
    [
      Layer.Identity,
      Layer.Cardio,
      Layer.Cholesterol,
      Layer.Circulation,
      Layer.PulseWaves,
    ].includes(layer)
  ) {
    return Mode.Front;
  }

  if (layer === Layer.Diabetes) {
    return Mode.Profile;
  }

  if ([Layer.Body, Layer.Bloodwork].includes(layer)) {
    return Mode.Swivel;
  }
}

interface PodiumMotionProps {
  layer: Layer;
  podium: AvatarPodium;
}

export default function PodiumMotion({ layer, podium }: PodiumMotionProps) {
  const mode = toMode(layer);

  useEffect(() => {
    if (mode === Mode.Front) {
      podium.moveTo(PodiumView.Front);
    } else if (mode === Mode.Profile) {
      podium.moveTo(PodiumView.Profile);
    } else if (mode === Mode.Swivel) {
      podium.moveTo(PodiumView.Front);

      let acc = 0;

      const swivel = PodiumView.Front.clone();

      return createTimer((deltaTime) => {
        acc += deltaTime;

        const s = acc / 1000;

        swivel.angle.z = Math.sin(s * 0.12) * (Math.PI / 6);
        podium.moveTo(swivel);
      });
    }
  }, [podium, mode]);

  return null;
}
