import { HeartRate, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import FixedAvatarValue from "render/ui/presentation/FixedAvatarValue";
import ValueItem from "render/ui/symbol/ValueItem";

interface HeartRateMetricProps {
  heartRate: Metric<"cardio.heart_rate">[];
}

export default function HeartRateMetric({ heartRate }: HeartRateMetricProps) {
  const latest = heartRate.at(0);

  const ranges = useMemo(() => HeartRate.rangesFor(), []);

  const risk = useMemo(() => {
    if (latest) {
      return ranges.findRisk(latest.unit);
    }
  }, [latest, ranges]);

  return (
    <>
      <FixedAvatarValue
        label="Heart Rate"
        value={
          <ValueItem
            value={latest ? latest.unit.bpm.toFixed(0) : "--"}
            unit="bpm"
          />
        }
        risk={risk ?? Risk.Unknown}
      />
    </>
  );
}
