import styles from "./styles.module.sass";

interface ValueItemProps {
  value: React.ReactNode;
  unit?: React.ReactNode;
  size?: "normal" | "huge";
}

export default function ValueItem({
  value,
  unit,
  size = "normal",
}: ValueItemProps) {
  return (
    <span className={styles.ValueItem} data-size={size}>
      <span className={styles.amount}>{value}</span>
      {unit && <span className={styles.unit}>{unit}</span>}
    </span>
  );
}
