import { APITypesV1 } from "@cur8/api-client";
import { Annotation } from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { useNav } from "@pomle/react-router-paths";
import { PanoramaImageURI } from "lib/api/uri";
import { useCallback } from "react";
import { ViewState } from "render/pages/AtlasPage";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import PhysicalArtefactState from "render/ui/format/PhysicalArtefactState";
import Table from "render/ui/layouts/Table";
import ArrowIcon from "render/ui/symbol/ArrowIcon";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import PhysicalArtefactStateMarker from "../PhysicalArtefactStateMarker";
import styles from "./styles.module.sass";

interface MarkingsTablePros {
  onLoadMore: () => void;
  hasNextPage: boolean;
  items: Annotation[];
  patientId: string;
  physicalArtefactsByPhysicalArtifactId: Record<
    string,
    APITypesV1.PhysicalArtefact
  >;
}

export default function MarkingsTable({
  onLoadMore,
  items,
  hasNextPage,
  patientId,
  physicalArtefactsByPhysicalArtifactId,
}: MarkingsTablePros) {
  const nav = {
    atlas: useNav(paths.patient.recording.atlas, query.atlas),
  };

  const buildAtlasPath = useCallback(
    (annotation: Annotation) => {
      const parsedTargetURI = RecordingURI.parse(
        annotation.targetURI.toString()
      );
      const panoramaUri = PanoramaImageURI.fromString(
        (annotation.applicationSpecificTarget || "").toString()
      );

      if (!parsedTargetURI || !panoramaUri) return;

      const { deviceId, recordingId } = parsedTargetURI;
      const { cameraName, side } = panoramaUri;

      return nav.atlas.to(
        {
          patientId,
          recordingId,
          deviceId,
        },
        {
          view: [ViewState.Explore],
          selectedAnnotation: [annotation.id],
          camera: [cameraName],
          side: [side],
        }
      );
    },
    [nav.atlas, patientId]
  );

  return (
    <div className={styles.MarkingsTable}>
      <Table>
        <thead>
          <tr>
            <th>Size</th>
            <th>Lesion ID</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {items.map((annotation) => {
            const physicalArtefact = annotation.physicalArtefactId
              ? physicalArtefactsByPhysicalArtifactId[
                  annotation.physicalArtefactId
                ]
              : null;

            const detailsUrl = buildAtlasPath(annotation);

            return (
              <tr
                key={annotation.id}
                className={styles.Row}
                data-state={physicalArtefact?.state}
              >
                <td>
                  {physicalArtefact &&
                    `${physicalArtefact.size?.toFixed(1)} mm`}
                </td>
                <td>{annotation.id}</td>
                <td>
                  <div className={styles.ArtefactStateColorColumn}>
                    {physicalArtefact && (
                      <>
                        <PhysicalArtefactStateMarker
                          state={physicalArtefact.state}
                        />
                        <PhysicalArtefactState state={physicalArtefact.state} />
                      </>
                    )}
                  </div>
                </td>
                <td>
                  {detailsUrl && (
                    <a
                      className={styles.viewBtn}
                      href={detailsUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View
                      <ArrowIcon direction="right" />
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>

      <div className={styles.tableFooter}>
        {hasNextPage && (
          <HoverTextButton onClick={onLoadMore}>Load more</HoverTextButton>
        )}
      </div>
    </div>
  );
}
