import { APITypesV1 } from "@cur8/api-client";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { useEffect, useState } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import ContextHeader from "render/ui/layouts/ContextHeader";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageFrame from "render/ui/layouts/PageFrame";
import ImageViewer from "../components/ImageViewer";
import ImageToggles from "../components/ImageViewer/components/Controls/ImageToggles";
import PatientSummary from "../components/PatientSummary";
import ProcessErrors from "../components/ProcessErrors";
import RegionTracker from "../components/RegionTracker";
import TissueAnnotationContext from "../context/TissueAnnotationContext";
import TissueImageContext from "../context/TissueImageContext";
import { useCameraCalibration } from "../hooks/useCameraCalibration";
import { useProcessedMetadata } from "../hooks/useProcessedMetadata";
import { useQueries } from "../hooks/useQueries";
import {
  Property,
  PropertyLabel,
  PropertyRange,
  TissueContextMode,
} from "../lib/types";
import { rangeParser } from "../lib/utils";
import PropertyRatio from "./components/PropertyRatio";
import styles from "./styles.module.sass";

interface TissuePADPageProps {
  patientId: string;
  scanId: string;
  scanVersion: string;
}

export default function TissuePADPage({
  patientId,
  scanId,
  scanVersion,
}: TissuePADPageProps) {
  const patientNav = useNav(paths.patient.detail);
  const scan = useImmutableScan(patientId, scanId, scanVersion);

  const patientRequest = usePatient(patientId);
  const { processedMetadata, metadataError } = useProcessedMetadata(scan);
  const { property, setProperty } = useQueries();

  const [indexRemap, setIndexRemap] = useState<number[]>();
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [range, setRange] = useState<PropertyRange | undefined>();
  const [hasBloodVesselsMask, setHasBloodVesselsMask] =
    useState<boolean>(false);
  const visibleProperties: PropertyLabel[] = [
    [Property.t1BloodVolume, "Shallow Blood Concentration"],
    [Property.t1Oxygenation, "Shallow Oxygenation"],
  ];

  const [pixelsPerCm, setPixelsPerCm] = useState<number>();
  const cameraCalibration = useCameraCalibration(scan);
  useEffect(() => {
    if (!cameraCalibration) {
      return;
    }
    setPixelsPerCm(cameraCalibration.camera.pix_per_cm);
  }, [cameraCalibration]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    if (processedMetadata.blood_vessels_mask !== undefined) {
      setHasBloodVesselsMask(processedMetadata.blood_vessels_mask);
    }
  }, [processedMetadata]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    const r = rangeParser(
      processedMetadata.ranges[property],
      processedMetadata.version
    );
    setRange(r);
  }, [processedMetadata, property]);

  useEffect(() => {
    if (!scan) {
      return;
    }

    const aPrefix = "acq_";
    const acqs = Object.entries(scan.resultStateSummary)
      .filter(
        ([k, v]) =>
          k.startsWith(aPrefix) && v === APITypesV1.ResultState.Complete
      )
      .map(([k, _]) => parseInt(k.slice(aPrefix.length)))
      .sort((a, b) => a - b);
    setIndexRemap(acqs);

    if (!processedMetadata) {
      return;
    }
    // The old processing pipeline contained only the processed timestamps
    // Now that we do incremental processing we get all timestamps and need
    // to filter out the ones that we don't need.
    const ts =
      (processedMetadata.version ?? 1) > 1
        ? acqs.map((i) => processedMetadata.timestamp_s[i])
        : processedMetadata.timestamp_s;

    setTimestamps(ts);
  }, [scan, processedMetadata]);

  if (scan && metadataError && !processedMetadata) {
    return (
      <FramedPage>
        <h1>{metadataError}</h1>
      </FramedPage>
    );
  }
  if (!patientRequest || !scan || !processedMetadata) {
    return (
      <FramedPage>
        <h1>Fetching data...</h1>
      </FramedPage>
    );
  }

  return (
    <PageFrame>
      <div className={styles.TissuePage} data-name="TissuePAD">
        <header>
          <ContextHeader
            context={
              <PathLink
                to={patientNav.to({
                  patientId: patientRequest.patientId,
                })}
              >
                <BackIcon />
              </PathLink>
            }
          >
            {scan && <PatientSummary patient={patientRequest} scan={scan} />}
          </ContextHeader>
        </header>
        <TissueAnnotationContext
          mode={TissueContextMode.PAD}
          patientId={patientId}
          scanId={scanId}
          scanVersion={scanVersion}
        >
          <TissueImageContext
            scan={scan}
            property={property}
            index={0}
            maxIndex={0}
            mode={TissueContextMode.PAD}
            version={processedMetadata?.version || 1}
            hasBloodVesselsMask={hasBloodVesselsMask}
          >
            <section className={styles.top}>
              <div className={styles.Sidebox}>
                {scan && timestamps && indexRemap && hasBloodVesselsMask ? (
                  <PropertyRatio
                    indexRemap={indexRemap}
                    onSelectProperty={setProperty}
                    scan={scan}
                    timestamps={timestamps}
                    properties={visibleProperties}
                    selected={property}
                  />
                ) : (
                  <h3 className={styles.loading}>Loading ratios</h3>
                )}
                <RegionTracker allowEdit={false} pixelsPerCm={pixelsPerCm} />
              </div>
              {scan && range && processedMetadata && (
                <ImageViewer
                  allowAdd={false}
                  allowResize={false}
                  range={range}
                  showControls={false}
                >
                  <div className={styles.imgTooglePos}>
                    <ImageToggles autoflow="column" isPAD={true} />
                  </div>
                </ImageViewer>
              )}
            </section>
          </TissueImageContext>
        </TissueAnnotationContext>
        <ProcessErrors processErrors={processedMetadata.errors} />
      </div>
    </PageFrame>
  );
}
