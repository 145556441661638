import { APITypesV1 } from "@cur8/api-client";
import { Annotation } from "@cur8/rich-entity";
import { throttle } from "@pomle/throb";
import { Box, Point } from "lib/math";
import { PanoramaCoord } from "lib/panorama-coords";
import React, { MouseEvent, useCallback, useMemo } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import AddIcon from "./assets/add.svg?react";
import styles from "./styles.module.sass";

interface AutoDetectionProps {
  annotation: Annotation;
  coords: PanoramaCoord;
  pan: Point;
  scale: number;
  scaleFactor: number;
  smooth: boolean;
  onAddMarking: (
    box: Box,
    physicalArtefactId: string,
    event: MouseEvent<Element>
  ) => Promise<void>;
}

export default function AutoDetection({
  annotation,
  coords,
  pan,
  scale,
  scaleFactor,
  smooth,
  onAddMarking,
}: AutoDetectionProps) {
  const radius = (coords.radius * scale) / scaleFactor;

  const createMarkingHandle = useAsyncHandle(
    useCallback(
      async (e: React.MouseEvent) => {
        e.preventDefault();
        if (coords) {
          const box = new Box(
            coords.center.x - coords.radius,
            coords.center.y - coords.radius,
            2 * coords.radius,
            2 * coords.radius
          );
          return onAddMarking(box, annotation.physicalArtefactId || "", e);
        }
      },
      [annotation, coords, onAddMarking]
    )
  );

  const handleClick = useMemo(() => {
    return throttle(createMarkingHandle.callback, 1500);
  }, [createMarkingHandle.callback]);

  const size = (annotation.classification as APITypesV1.MoleClassification)
    .size;
  const sizeLabel = size ? size.toFixed(1) : "";

  const rectSize = 2 * radius - 2;

  const delay = useMemo(() => {
    return 2 * Math.random();
  }, []);

  return (
    <div
      className={styles.AutoDetection}
      data-smooth={smooth}
      key={annotation.id}
      data-loading={createMarkingHandle.busy}
      style={{
        animationDelay: `${delay}s`,
        left: (coords.center.x * scale) / scaleFactor + -pan.x - radius,
        top: (coords.center.y * scale) / scaleFactor + -pan.y - radius,
        height: rectSize,
        width: rectSize,
      }}
      onClick={handleClick}
    >
      <div className={styles.InfoPanel}>
        {sizeLabel && <span>Size:&nbsp;{sizeLabel}&nbsp;mm</span>}
      </div>

      <AddIcon className={styles.icon} />
    </div>
  );
}
