import { Context } from "./context";
import { usePatientRecorder } from "./hooks/usePatientRecorder";

interface PatientAudioRecordContextProps {
  children: React.ReactNode;
}

export default function PatientAudioRecordContext({
  children,
}: PatientAudioRecordContextProps) {
  const value = usePatientRecorder();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
