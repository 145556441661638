import { Side } from "@cur8/api-client";
import { RecordingURI } from "@cur8/uri";
import fallbackPCD from "lib/meshuga/neural_vectra.pcd";
import { createPoints, parsePointCloud } from "lib/meshuga/pointcloud";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import * as THREE from "three";

export const ANTICIPATED_POINT_COUNT = 60000;

const SCALE: Record<Side, number> = {
  front: 1,
  back: -1,
};

export function usePointCloud(pose: Side, recordingURI?: RecordingURI | Error) {
  const { meshuga } = useAPIClient();

  const [result, setResult] = useState<AsyncResult<THREE.Points>>();

  useEffect(() => {
    if (!(recordingURI instanceof Error)) {
      return;
    }

    fetch(fallbackPCD)
      .then((r) => r.arrayBuffer())
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        points.geometry.scale(1, SCALE[pose], 1);
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      setResult(undefined);
    };
  }, [recordingURI, pose]);

  useEffect(() => {
    if (!recordingURI || recordingURI instanceof Error) {
      return;
    }

    const { deviceId, recordingId } = recordingURI;

    const request = meshuga.fetchPointCloud({
      deviceId,
      recordingId,
      pose,
      downsample: "doctor-ui-dashboard/1:6",
      nocolor: true,
    });

    request.result
      .then(parsePointCloud)
      .then(createPoints)
      .then((points) => {
        setResult({ data: points });
      })
      .catch((error) => {
        setResult({ error });
      });

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [meshuga, recordingURI, pose]);

  return result;
}
