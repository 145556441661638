import { Patient, Sex } from "@cur8/rich-entity";
import { lerp } from "lib/anim";
import { easeOutCubic } from "lib/ease";
import { patientScore2 } from "lib/scores/score2";
import { toPolyline } from "lib/svg";
import { useCallback, useMemo } from "react";
import { useHighestBrachialPressure } from "render/hooks/api/metrics/useHighestBrachialPressure";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import RampUpNumber from "render/ui/format/RampUpNumber";
import { clamp } from "three/src/math/MathUtils";
import styles from "./styles.module.sass";

interface ProtectiveScoreGraphProps {
  patient: Patient;
  rangeY: number;
  labels: {
    value: number;
    title: string;
  }[];
  ageMin: number;
  ageMax: number;
  ageBreakPoints: number[];
}

export default function ProtectiveScoreGraph({
  patient,
  ageBreakPoints,
  ageMax,
  ageMin,
  labels,
  rangeY,
}: ProtectiveScoreGraphProps) {
  const {
    metrics: { cardio, bloodwork },
    lifestyle,
  } = usePatientData();

  const age = useAge(patient);
  const sex = patient.sex;

  const highestBrachial = useHighestBrachialPressure(cardio.brachialPressure);
  const nonHDL = bloodwork.nonHDL?.at(0);
  const isSmoker = lifestyle?.isSmoker;

  const ageToX = useCallback(
    (age: number) => {
      const frac = (age - ageMin) / (ageMax - ageMin);
      return frac * 100;
    },
    [ageMin, ageMax]
  );

  const scoreToY = useCallback(
    (score: number) => {
      return (score / rangeY) * 100;
    },
    [rangeY]
  );

  const scores = useMemo(() => {
    if (!highestBrachial || !nonHDL || isSmoker == null) {
      return [];
    }

    const metric = { brachialPressure: highestBrachial, isSmoker, nonHDL };

    const scores: { score?: number; age: number }[] = [];

    for (let age = ageMin + 2; age <= ageMax - 3; age += 1) {
      const score = patientScore2({
        age: clamp(age, 40, 89),
        sex,
        metric,
      });
      scores.push({ score: score ?? undefined, age });
    }

    return scores;
  }, [ageMax, ageMin, highestBrachial, isSmoker, nonHDL, sex]);

  const score = scores.find((score) => score.age === age)?.score;

  const interpolated = useMemo(() => {
    const index = scores.findIndex((score) => score.age >= age);
    const from = scores.at(index);
    const to = scores.at(index + 1);

    if (from?.score && to?.score) {
      const score = lerp(
        from.score,
        to.score,
        (age - from.age) / (age - to.age)
      );

      return scoreToY(score);
    }

    return NaN;
  }, [age, scores, scoreToY]);

  const plot = useMemo(() => {
    return scores.map(({ score, age }) => ({
      x: ageToX(age),
      y: scoreToY(score ?? 0),
    }));
  }, [scores, scoreToY, ageToX]);

  const ages = useMemo(() => {
    const ages = ageBreakPoints.filter((sub) => Math.abs(sub - age) >= 2);

    if (age > 37 && age < 92) {
      ages.push(age);
    }

    return ages;
  }, [age, ageBreakPoints]);

  const score2Max = useMemo(() => {
    if (sex === Sex.Male) {
      return "98";
    }
    if (sex === Sex.Female) {
      return "99";
    }
    return "--";
  }, [sex]);

  return (
    <div className={styles.ProtectiveScore}>
      <h3>
        Protective Score&emsp;
        {score ? (
          <>
            <b>
              <RampUpNumber
                value={100 - score}
                duration={4}
                precision={0}
                ease={easeOutCubic}
              />
            </b>
            &nbsp; / {score2Max}
          </>
        ) : (
          "--"
        )}
      </h3>

      <div className={styles.graph}>
        <div className={styles.sunset} />

        <div className={styles.labels}>
          {labels.map(({ value, title }) => {
            return (
              <div
                key={value}
                className={styles.label}
                style={{
                  top: `${scoreToY(100 - value)}%`,
                }}
              >
                <label>
                  {title} {value}
                </label>
              </div>
            );
          })}
        </div>

        <svg
          viewBox="0 0 100 100"
          width="100%"
          height="100%"
          preserveAspectRatio="none"
        >
          <g stroke="#019dc9">
            {isFinite(interpolated) && (
              <>
                <line
                  className={styles.ageLine}
                  x1={ageToX(age)}
                  x2={ageToX(age)}
                  y1={interpolated}
                  y2="100%"
                  stroke="#019dc9"
                />
              </>
            )}
          </g>

          <g stroke="#4c6a70">
            {ageBreakPoints.map((age) => {
              const x = ageToX(age);
              return <line key={age} x1={x} x2={x} y1="97" y2="100" />;
            })}
          </g>

          <polyline
            points={toPolyline(plot)}
            fill="none"
            className={styles.curve}
          />
        </svg>

        {score && (
          <div
            className={styles.target}
            style={{
              left: `${ageToX(age)}%`,
              top: `${scoreToY(score)}%`,
            }}
          />
        )}
      </div>

      <div className={styles.ages}>
        {ages.map((ageEntry) => {
          return (
            <div
              key={ageEntry}
              className={styles.age}
              style={{
                left: `${ageToX(ageEntry)}%`,
              }}
            >
              {ageEntry === age ? <b>{ageEntry}</b> : ageEntry}
            </div>
          );
        })}
        Age
      </div>
    </div>
  );
}
