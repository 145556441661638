import { Glucose } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import MetricStatus from "render/ui/presentation/MetricStatus";
import MetricStatusBadge from "render/ui/presentation/MetricStatusBadge";
import ValueItem from "render/ui/symbol/ValueItem";

interface GlucoseItemProps {
  glucose?: Metric<"bloodwork.glucose">;
  previousGlucose: Metric<"bloodwork.glucose"> | undefined;
}

export default function GlucoseItem({
  glucose,
  previousGlucose,
}: GlucoseItemProps) {
  const summary = useMemo(() => {
    if (glucose) {
      return {
        risk: Glucose.rangesFor().findRisk(glucose.unit),
      };
    }
  }, [glucose]);

  const differenceToPreviousValue = useMemo(() => {
    if (!glucose?.unit["mmol/L"] || !previousGlucose?.unit["mmol/L"]) {
      return undefined;
    }

    return Number(
      (glucose.unit["mmol/L"] - previousGlucose.unit["mmol/L"]).toFixed(0)
    );
  }, [glucose?.unit, previousGlucose?.unit]);

  return (
    <MetricStatus
      caption="Glucose"
      summaryContent={
        summary ? <MetricStatusBadge risk={summary.risk} /> : "--"
      }
      differenceToPreviousValue={differenceToPreviousValue}
      unit="mmol/L"
    >
      <ValueItem value={glucose?.unit["mmol/L"] ?? "--"} unit="mmol/L" />
    </MetricStatus>
  );
}
