import { useCallback, useEffect } from "react";
import { debounce } from "@pomle/throb";

export function useRenderScale(
  renderer: THREE.WebGLRenderer,
  scale: number = 1
) {
  const canvas = renderer.domElement;

  const setResolution = useCallback(
    (width: number, height: number) => {
      renderer.setSize(width * scale, height * scale, false);
      renderer.setPixelRatio(window.devicePixelRatio);
    },
    [renderer, scale]
  );

  useEffect(() => {
    const rect = canvas.getBoundingClientRect();
    setResolution(rect.width, rect.height);
  }, [canvas, setResolution]);

  useEffect(() => {
    const updateResolution = debounce(setResolution, 100);

    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.target === canvas) {
          const { width, height } = entry.contentRect;
          updateResolution(width, height);
        }
      });
    });

    observer.observe(canvas);

    return () => {
      observer.disconnect();
    };
  }, [setResolution, canvas]);
}
