import { BodyAreas } from "lib/smpl";
import { useEffect, useMemo } from "react";
import Calf from "render/fragments/avatar/overlay/Calf";
import Height from "render/fragments/avatar/overlay/Height";
import Hip from "render/fragments/avatar/overlay/Hip";
import Thorax from "render/fragments/avatar/overlay/Thorax";
import Waist from "render/fragments/avatar/overlay/Waist";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import Overlay from "../../components/Overlay";
import styles from "./styles.module.sass";

interface BodyMeasurementsLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export default function BodyMeasurementsLayer({
  layer,
  areas,
  projector,
}: BodyMeasurementsLayerProps) {
  const active = layer === Layer.Body;

  const {
    metrics: { body },
    smpl: { metrics: smplMetrics },
  } = usePatientData();

  const positions = useMemo(() => {
    const height = new THREE.Vector3();

    const eyes = new THREE.Vector3();
    areas.leftEye.getCenter(eyes);
    eyes.y = 0;

    areas.top.getCenter(height);
    height.x = 0;
    height.y = 0;

    const thorax = new THREE.Vector3(
      areas.thorax.min.x - 50,
      (areas.thorax.min.y + areas.thorax.max.y) / 2,
      (areas.thorax.min.z + areas.thorax.max.z) / 2
    );

    const waist = new THREE.Vector3(
      areas.waist.max.x + 50,
      (areas.waist.min.y + areas.waist.max.y) / 2,
      (areas.waist.min.z + areas.waist.max.z) / 2
    );

    const hip = new THREE.Vector3(
      areas.hip.min.x - 50,
      (areas.hip.min.y + areas.hip.max.y) / 2,
      (areas.hip.min.z + areas.hip.max.z) / 2
    );

    const calf = new THREE.Vector3(
      areas.calf.max.x + 50,
      (areas.calf.min.y + areas.calf.max.y) / 2,
      (areas.calf.min.z + areas.calf.max.z) / 2
    );

    return {
      eyes,
      height,
      waist,
      hip,
      calf,
      thorax,
    };
  }, [areas]);

  const height = useMemo(() => {
    if (!body.height || !smplMetrics) {
      return;
    }

    if (body.height.length > 0) {
      return body.height[0].unit;
    }

    return smplMetrics.at(0)?.unit.distances.height;
  }, [body.height, smplMetrics]);

  const waist = useMemo(() => {
    if (!body.waist || !smplMetrics) {
      return;
    }

    if (body.waist.length > 0) {
      return body.waist[0].unit;
    }

    return smplMetrics.at(0)?.unit.circumferences.waist;
  }, [body.waist, smplMetrics]);

  const thorax = useMemo(() => {
    return smplMetrics?.at(0)?.unit.circumferences.thorax;
  }, [smplMetrics]);

  const hip = useMemo(() => {
    return smplMetrics?.at(0)?.unit.circumferences.hip;
  }, [smplMetrics]);

  const calf = useMemo(() => {
    return smplMetrics?.at(0)?.unit.circumferences.calf;
  }, [smplMetrics]);

  useEffect(() => {
    if (height) {
      positions.height.z = height.meters * 1000;
    }
  }, [positions, height]);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.BodyMeasurementsLayer} data-active={active}>
      {anchors && (
        <>
          {thorax && (
            <Overlay pos={anchors.thorax}>
              <div className={styles.item}>
                <Thorax circumference={thorax} />
              </div>
            </Overlay>
          )}

          {waist && (
            <Overlay pos={anchors.waist}>
              <div className={styles.item}>
                <Waist circumference={waist} />
              </div>
            </Overlay>
          )}

          {hip && (
            <Overlay pos={anchors.hip}>
              <div className={styles.item}>
                <Hip circumference={hip} />
              </div>
            </Overlay>
          )}

          {calf && (
            <Overlay pos={anchors.calf}>
              <div className={styles.item}>
                <Calf circumference={calf} />
              </div>
            </Overlay>
          )}

          {height && (
            <Overlay pos={anchors.height}>
              <div className={styles.item}>
                <Height height={height} />
              </div>
            </Overlay>
          )}
        </>
      )}
    </div>
  );
}
