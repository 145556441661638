import { Patient } from "@cur8/rich-entity";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import PWVGraphs from "./components/PWVGraphs";
import styles from "./styles.module.sass";

interface PulseWavesPanelProps {
  patient: Patient;
}

export default function PulseWavesPanel({ patient }: PulseWavesPanelProps) {
  const { scans } = usePatientData();

  const cardioScan = scans?.cardio.at(0);

  return (
    <div className={styles.PulseWavesPanel}>
      <h2>Arterial Stiffness</h2>

      <hr />

      <h3>Pulse Waves</h3>

      {cardioScan && <PWVGraphs scan={cardioScan} />}
    </div>
  );
}
