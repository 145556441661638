import * as THREE from "three";
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import ribbonTexture from "./assets/ribbon.png";

export function createCircumference(box: THREE.Box3) {
  const size = new THREE.Vector3();
  box.getSize(size);

  const radius = Math.max(size.x, size.y) / 2;

  const ribbon = new THREE.CylinderGeometry(radius, radius, 8, 32, 1, true);

  const texture = new THREE.TextureLoader().load(ribbonTexture);
  texture.magFilter = THREE.NearestFilter;
  texture.minFilter = THREE.NearestFilter;
  texture.repeat.set(5, 1);
  texture.wrapS = THREE.RepeatWrapping;

  const material = new THREE.MeshBasicMaterial({
    color: "#019dc9",
    transparent: true,
    // opacity controlled by tween
    depthWrite: false,
    depthTest: false,
    side: THREE.FrontSide,
    map: texture,
  });

  ribbon.rotateX(Math.PI / 2);
  const ribbons = [ribbon];

  ribbons.forEach((ribbon, index) => {
    ribbon.translate(0, 0, 16 * index);
  });

  const geometry = mergeBufferGeometries(ribbons);

  const mesh = new THREE.Mesh(geometry, material);

  mesh.renderOrder = 2;

  box.getCenter(mesh.position);

  return mesh;
}
