import React, { PropsWithChildren } from "react";
import SquircleArrow from "render/assets/svg/squircle-arrow";
import styles from "./styles.module.sass";

interface MetricStatusProps extends PropsWithChildren {
  caption: React.ReactNode;
  summaryContent: React.ReactNode;
  differenceToPreviousValue?: number | undefined;
  unit?: string;
}

export default function MetricStatus({
  children,
  caption,
  summaryContent,
  differenceToPreviousValue,
  unit,
}: MetricStatusProps) {
  const [mode, setMode] = React.useState<"summary" | "change">("summary");
  const handleOnClick = () => {
    if (differenceToPreviousValue !== undefined) {
      setMode((prev) => (prev === "summary" ? "change" : "summary"));
    }
  };

  const arrowDirection =
    differenceToPreviousValue !== undefined && differenceToPreviousValue > 0
      ? "upwards"
      : "downwards";

  return (
    <div className={styles.MetricResultTile}>
      <div className={styles.content}>{children}</div>
      <div className={styles.caption} onClick={handleOnClick}>
        {caption}
      </div>

      <div onClick={handleOnClick}>
        {mode === "summary" && (
          <div className={styles.status}>{summaryContent}</div>
        )}
        {mode === "change" && (
          <div className={styles.valuesDiffContainer}>
            <div className={styles.diff}>
              {differenceToPreviousValue !== undefined &&
                differenceToPreviousValue !== 0 && (
                  <SquircleArrow direction={arrowDirection} />
                )}
              {differenceToPreviousValue !== undefined ? (
                <>
                  {differenceToPreviousValue === 0 && "No change"}
                  {differenceToPreviousValue !== 0 &&
                    Math.abs(differenceToPreviousValue)}
                </>
              ) : (
                "--"
              )}
            </div>
            {unit ? <div className={styles.unit}>{unit}</div> : null}
          </div>
        )}
      </div>
    </div>
  );
}
