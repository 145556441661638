import { PropsWithChildren } from "react";
import SquircleArrow from "./assets/squircle";
import styles from "./styles.module.sass";

export default function MetricResultHeader({ children }: PropsWithChildren) {
  return <div className={styles.Header}>{children}</div>;
}

export function Titles({ children }: PropsWithChildren) {
  return <div className={styles.titles}>{children}</div>;
}

export function MainTitle({ children }: PropsWithChildren) {
  return <h3>{children}</h3>;
}

export function Subtitle({ children }: PropsWithChildren) {
  return <span className={styles.subtitle}>{children}</span>;
}

export function AuxTitle({ children }: PropsWithChildren) {
  return <span className={styles.aux}>{children}</span>;
}

export function Diff({ children }: PropsWithChildren) {
  return <div className={styles.diff}>{children}</div>;
}

export function Unit({ children }: PropsWithChildren) {
  return <div className={styles.unit}>{children}</div>;
}

interface DiffValueProps {
  valuesDiff?: number | undefined;
  unit?: string | undefined;
  formatValue?: (value?: number) => string | number | undefined;
}

export function DiffValue({
  valuesDiff,
  unit,
  formatValue = (n?: number) => n,
}: DiffValueProps) {
  return (
    <>
      {valuesDiff !== undefined && (
        <span className={styles.value}>
          {valuesDiff === 0 ? (
            ""
          ) : valuesDiff > 0 ? (
            <SquircleArrow />
          ) : (
            <SquircleArrow direction="downwards" />
          )}
          {valuesDiff === 0 ? "No change" : formatValue(+Math.abs(valuesDiff))}
        </span>
      )}
      {unit && <Unit>{unit}</Unit>}
    </>
  );
}
