import { CardioSignals } from "../../hooks/useCardioSignals";
import { Range } from "../shared/DataWindow/DataWindow";
import GraphWithRuler from "../shared/GraphWithRuler";
import { ECG_COLOR, LEFT_COLOR, RIGHT_COLOR } from "../shared/constants";
interface FootAsymmetryProps {
  signals: CardioSignals | undefined;
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
}

export default function FootAsymmetry({
  signals,
  range,
  setRange,
}: FootAsymmetryProps) {
  return signals ? (
    <GraphWithRuler
      title="Pulse Asymmetry (feet)"
      data={[
        {
          label: "Cardiac Rhythm",
          signal: signals.ecg,
          color: ECG_COLOR,
        },
        {
          label: "Left",
          signal: signals.piezo_foot_left,
          color: LEFT_COLOR,
        },
        {
          label: "Right",
          signal: signals.piezo_foot_right,
          color: RIGHT_COLOR,
        },
      ]}
      diffData={[
        {
          label: "Left Acceleration",
          signal: signals.piezo_foot_left_acc,
          color: LEFT_COLOR,
        },
        {
          label: "Right Acceleration",
          signal: signals.piezo_foot_right_acc,
          color: RIGHT_COLOR,
        },
      ]}
      range={range}
      setRange={setRange}
    />
  ) : null;
}
