import { Diastolic, Systolic } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import { DateTime } from "luxon";
import { useMemo } from "react";
import {
  getHighestRisk,
  getRiskColor,
} from "../../../../../../../../lib/utils";
import Circle from "./components/Circle";
import Rectangle from "./components/Rectangle";
import styles from "./styles.module.sass";

interface CandlestickProps {
  patient: Patient;
  systolic: number[];
  diastolic: number[];
  toY: (n: number) => number;
}

export default function Candlestick({
  patient,
  systolic,
  diastolic,
  toY,
}: CandlestickProps) {
  const age = patientAge(patient, DateTime.now());

  const ranges = useMemo(() => {
    return {
      systolic: Systolic.rangesFor({ age }),
      diastolic: Diastolic.rangesFor({ age }),
    };
  }, [age]);

  if (systolic.length === 0 || diastolic.length === 0) {
    return null;
  }
  const max = Math.max(...systolic, ...diastolic);
  const min = Math.min(...systolic, ...diastolic);

  const systolicMax = Math.max(...systolic);
  const systolicMin = Math.min(...systolic);

  const diastolicMax = Math.max(...diastolic);
  const diastolicMin = Math.min(...diastolic);

  const systolicRisk = getHighestRisk([
    ranges.systolic.findRisk({ mmHg: systolicMax }),
    ranges.systolic.findRisk({ mmHg: systolicMin }),
  ]);

  const diastolicRisk = getHighestRisk([
    ranges.diastolic.findRisk({ mmHg: diastolicMax }),
    ranges.diastolic.findRisk({ mmHg: diastolicMin }),
  ]);

  const color = getRiskColor(getHighestRisk([diastolicRisk, systolicRisk]));

  return (
    <svg className={styles.Candlestick}>
      <line
        x1="50%"
        y1={`${toY(max)}%`}
        y2={`${toY(min)}%`}
        x2="50%"
        stroke={color}
        strokeDasharray="2"
      />
      <>
        {systolicMax === systolicMin && (
          <Circle fill={color} toY={toY} val={systolicMax} />
        )}
        {systolicMax !== systolicMin && (
          <Rectangle
            fill={color}
            toY={toY}
            max={systolicMax}
            min={systolicMin}
          />
        )}
      </>
      <>
        {diastolicMax === diastolicMin && (
          <Circle fill={color} toY={toY} val={diastolicMax} />
        )}
        {diastolicMax !== diastolicMin && (
          <Rectangle
            fill={color}
            toY={toY}
            max={diastolicMax}
            min={diastolicMin}
          />
        )}
      </>
    </svg>
  );
}
