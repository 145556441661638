import { APITypesV2 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { useMemo } from "react";
import { AlcoholFrequency } from "render/fragments/metric/patient-details/AlcoholFrequency";
import { ExerciseFrequency } from "render/fragments/metric/patient-details/ExerciseFrequency";
import { SmokingFrequency } from "render/fragments/metric/patient-details/SmokingFrequency";
import { SnusFrequency } from "render/fragments/metric/patient-details/SnusFrequency";
import PatientAge from "render/fragments/patient/PatientAge";
import LocaleLanguage from "render/ui/format/LocaleLanguage";
import SexDefinition from "render/ui/format/SexDefinition";
import styles from "./styles.module.sass";

function isMetricZero(value?: string) {
  return !value || value === "0";
}

interface DetailsProps {
  onboarding: APITypesV2.QuestionnaireResponse | undefined;
  patient: Patient | undefined;
}

export function DetailsV1({ patient, onboarding }: DetailsProps) {
  const answers = useMemo(() => {
    if (onboarding) {
      return convertQuestionnaire(onboarding);
    }
  }, [onboarding]);

  return (
    <>
      <div className={styles.row}>
        <div className={styles.metricTitle}>Age</div>
        <div className={styles.data}>
          <div>
            {patient && (
              <>
                <PatientAge patient={patient} /> years old
              </>
            )}
          </div>
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Sex</div>
        <div className={styles.data}>
          {patient && <SexDefinition sex={patient.sex} />}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.metricTitle}>Language</div>
        <div className={styles.data}>
          {patient?.preferredLanguage ? (
            <LocaleLanguage locale={patient.preferredLanguage} />
          ) : (
            <>&mdash;</>
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.v1Medications,
        })}
      >
        <div className={styles.metricTitle}>Medications</div>
        <div className={styles.data}>{answers?.v1Medications}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.allergies,
        })}
      >
        <div className={styles.metricTitle}>Allergies</div>
        <div className={styles.data}>{answers?.allergies}</div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.diet,
        })}
      >
        <div className={styles.metricTitle}>Diet</div>
        <div className={styles.data}>
          {answers?.diet ? <>{answers.diet}</> : <>No</>}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.exerciseHoursPerWeek),
        })}
      >
        <div className={styles.metricTitle}>Exercise</div>
        <div className={styles.data}>
          {answers?.exerciseHoursPerWeek && (
            <ExerciseFrequency value={Number(answers.exerciseHoursPerWeek)} />
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.alcohol),
        })}
      >
        <div className={styles.metricTitle}>Alcohol</div>
        <div className={styles.data}>
          {answers?.alcohol && <AlcoholFrequency value={answers.alcohol} />}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: !answers?.isSmoker,
        })}
      >
        <div className={styles.metricTitle}>Smoking</div>
        <div className={styles.data}>
          {answers?.isSmoker ? (
            answers.smoking ? (
              <SmokingFrequency value={Number(answers.smoking)} />
            ) : (
              "Yes"
            )
          ) : (
            "No"
          )}
        </div>
      </div>

      <div
        className={classNames(styles.row, {
          [styles.disabled]: isMetricZero(answers?.snusCansPerWeek),
        })}
      >
        <div className={styles.metricTitle}>Snus</div>
        <div className={styles.data}>
          {answers?.snusCansPerWeek && (
            <SnusFrequency value={answers.snusCansPerWeek} />
          )}
        </div>
      </div>
    </>
  );
}
