import { Patient } from "@cur8/rich-entity";
import Diagram from "./assets/cholesterolv2.svg?react";
import styles from "./styles.module.sass";

interface ArterySectionProps {
  patient: Patient;
}

export default function ArterySection({ patient }: ArterySectionProps) {
  return (
    <div className={styles.ArterySection}>
      <div className={styles.diagram}>
        <Diagram height={687} width={687} />
        <label data-variant="hdl">HDL</label>
        <label data-variant="nonhdl">Non-HDL</label>
      </div>
    </div>
  );
}
