import { Patient } from "@cur8/rich-entity";
import CRPRange from "render/fragments/metric/range-charts/CRPRange";
import BMIItem from "render/fragments/metric/summary/BMIItem";
import BloodPressureItem from "render/fragments/metric/summary/BloodPressureItem";
import GlucoseItem from "render/fragments/metric/summary/GlucoseItem";
import { useBMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useHighestBrachialPressure } from "render/hooks/api/metrics/useHighestBrachialPressure";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import SummarySet from "render/pages/DashboardPage/ui/SummarySet";
import HbA1cBenchmark from "./components/HbA1cBenchmark";
import BenchmarkHeader from "./components/HbA1cBenchmark/components/Header";
import HbA1cSegment from "./components/HbA1cSegment";
import styles from "./styles.module.sass";

interface DiabetesSectionProps {
  patient: Patient;
}

export default function DiabetesSection({ patient }: DiabetesSectionProps) {
  const {
    metrics: { cardio, bloodwork, body },
    aggregates: { bmi: BMI, tbi: TBI },
  } = usePatientData();

  const highestBrachial = useHighestBrachialPressure(cardio.brachialPressure);
  const previousHighestBrachial = useHighestBrachialPressure({
    left: cardio.brachialPressure?.left?.slice(1),
    right: cardio.brachialPressure?.right?.slice(1),
  });

  const age = useAge(patient);

  const previousWeight = body.weight?.slice(1);
  const previousHeight = body.height?.slice(1) ?? body.height?.slice(0, 1);

  const previousBMI = useBMIScore({
    weight: previousWeight,
    height: previousHeight,
  });

  return (
    <div className={styles.DiabetesSection}>
      <section>
        <h2>Diabetes Factors</h2>
        <hr />
      </section>

      {patient && !!bloodwork.hba1c?.length && (
        <section>
          <HbA1cSegment patient={patient} metrics={bloodwork.hba1c} />
        </section>
      )}

      {patient && !!bloodwork.hba1c?.length && (
        <section>
          <BenchmarkHeader
            metrics={bloodwork.hba1c}
            sex={patient.sex}
            age={age}
          />
          <HbA1cBenchmark patient={patient} metrics={bloodwork.hba1c} />
        </section>
      )}

      {bloodwork.crp && <CRPRange metrics={bloodwork.crp} />}

      <section>
        <SummarySet>
          <BloodPressureItem
            patient={patient}
            brachialPressure={highestBrachial}
            previousBrachialPressure={previousHighestBrachial}
            tbi={TBI}
          />

          <BMIItem patient={patient} bmi={BMI} previousBMI={previousBMI} />

          <GlucoseItem
            glucose={bloodwork.glucose?.at(0)}
            previousGlucose={bloodwork.glucose?.at(1)}
          />
        </SummarySet>
      </section>
    </div>
  );
}
