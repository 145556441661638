import { APITypesV1, LinkingResultAnnotation } from "@cur8/api-client";
import { Annotation, ImmutableScan, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { exhaustAnnotations } from "lib/api/resolvers/annotation";
import { Lesion } from "lib/lesion";
import { isRecordingURI } from "lib/uri/guard";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

const MAX_ANNOTATIONS_FETCHED_PER_SCAN = 20000;

async function fetchDermaAnnotations(api: APIClient, patientId: string) {
  return exhaustAnnotations(
    api.annotation,
    { patientId, applicationSpecificTarget: "derma:derma", pageSize: 100 },
    8000
  ).then((result) => result.map(fromAPI.toAnnotation));
}

async function fetchScanAnnotations(
  api: APIClient,
  scan: ImmutableScan
): Promise<Annotation[]> {
  function toAnnotation(source: LinkingResultAnnotation): Annotation {
    return fromAPI.toAnnotation({
      ...source,
      createdAt: scan.timestamp.toISO(),
      updatedAt: scan.timestamp.toISO(),
      comment: "Hydrated from reduced",
      previewUrl: "",
      acceptState: APITypesV1.AcceptState.Proposed,
      audit: {},
    });
  }

  const recordingURI = scan.sourceUris.find(isRecordingURI);

  const annotations: Annotation[] = [];

  const hasLinkingResults =
    scan.resultStateSummary["linking_results"] === "complete";
  if (hasLinkingResults) {
    const annos = await api.scan
      .fetchLinkingResults({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
      })
      .then((annos) => {
        console.debug("Found linking_results for scan", scan);
        return annos.map(toAnnotation);
      })
      .catch((error) => {
        console.warn("Failed fetching linking_results for scan", scan, error);
      });

    if (annos) {
      annotations.push(...annos);

      if (recordingURI) {
        const extras = await exhaustAnnotations(
          api.annotation,
          {
            patientId: scan.patientId,
            targetUri: recordingURI.toString(),
            acceptState: APITypesV1.AcceptState.Accepted,
            pageSize: 100,
          },
          MAX_ANNOTATIONS_FETCHED_PER_SCAN
        ).then((annos) => {
          return annos.map(fromAPI.toAnnotation);
        });
        annotations.push(...extras);
      }

      return annotations;
    }
  }

  return [];
}

async function fetchAnnotations(
  api: APIClient,
  scans: ImmutableScan[]
): Promise<Lesion[]> {
  const index = new Map<string, Lesion>();

  let counter = 0;

  function add(physicalId: string) {
    if (!index.has(physicalId)) {
      counter += 1;
      index.set(physicalId, {
        fakeId: counter,
        physicalId,
        links: [],
      });
    }

    return index.get(physicalId) as Lesion;
  }

  const patientIds = new Set(scans.map((scan) => scan.patientId));

  for (const scan of scans) {
    const annotations = await fetchScanAnnotations(api, scan);

    for (const annotation of annotations) {
      const physId = annotation.physicalArtefactId;
      if (physId) {
        add(physId).links.push({ scan, annotation });
      }
    }
  }

  for (const patientId of patientIds) {
    const annotations = await fetchDermaAnnotations(api, patientId);
    for (const annotation of annotations) {
      const physId = annotation.physicalArtefactId;
      if (physId) {
        add(physId).links.push({ annotation });
      }
    }
  }

  return Array.from(index.values());
}

export function useLesions(patientId?: string, scans?: ImmutableScan[]) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<Lesion[]>>();

  useEffect(() => {
    if (!patientId || !scans) {
      return;
    }

    fetchAnnotations(api, scans).then((data) => {
      setResult({ data });
    });

    return () => {
      setResult(undefined);
    };
  }, [api, patientId, scans]);

  return result;
}
