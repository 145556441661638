import { useEffect, useMemo } from "react";
import * as THREE from "three";
import { AvatarPointCloudMaterial } from "../../materials/AvatarPointCloudMaterial";
import { createFloorGeometry } from "./models";

interface FloorProps {
  podium: THREE.Object3D;
  pointTexture: THREE.Texture;
}

export default function Floor({ podium, pointTexture }: FloorProps) {
  const floor = useMemo(() => {
    const material = new AvatarPointCloudMaterial({ pointTexture });
    material.color.setStyle("#008BB7");
    material.wave.direction.set(0, 0, 0);
    material.wave.length = 1000;
    material.points.size = 12.0 * window.devicePixelRatio;

    material.falloff.near = 0;
    material.falloff.far = 2000;

    material.scan.width = 1000;

    material.shimmer.width = 10;
    material.shimmer.origin = 10;

    const geo = createFloorGeometry();

    return new THREE.Points(geo, material);
  }, [pointTexture]);

  useEffect(() => {
    podium.add(floor);

    return () => {
      podium.remove(floor);
      floor.geometry.dispose();
      floor.material.dispose();
    };
  }, [podium, floor]);

  return null;
}
